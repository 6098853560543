import React, {useState} from 'react';
import './style.scss';
import RouterGetInfoForm from "./RouterGetInfoForm";
import RouterDisplayInfo from "./RouterDisplayInfo";
import {useLocation} from 'react-router-dom';
import Loader from "../Notifications/Loader";
import {Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const RouterInfoPages = (props) => {
    const [routerData, setRouterData] = useState(null);
    let [loader, setLoader] = useState(false);
    const [entries, setEntries] = useState(null);
    const [execData, setExecData] = useState(null);
    const [showEntries, setShowEntries] = useState(false);
    const [showInnerLoader, setShowInnerLoader] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [expand, setExpand] = useState(false);
    const [err, setErr] = useState('');

    const location = useLocation();
    let pageUrl = location.pathname.slice(1);

    let shouldDisplayFields = {
        "upid": (pageUrl !== "routerOperationalTest"),
        //"entity": (pageUrl === "routerConfig"),
        "routerSerialNumber": true,
    }

    if (loader) {
        return <Loader/>
    }

    return (
        <>
            { !showEntries ? <div className="config-note">
                <span><i>Note: This configuration download can take upto 20-30 minutes. If you experience any difficulty or errors, please call the Tabcorp SDWAN engineering support on 0736371308 option2.</i></span>
            </div> : null
            }

            <div className="router-wrapper pd-25">
                <RouterGetInfoForm setRouterData={setRouterData} shouldDisplayFields={shouldDisplayFields}
                                   setTenantList={setTenantList}
                                   setLoader={setLoader} setEntries={setEntries} setShowEntries={setShowEntries} setErr={setErr}/>
                <RouterDisplayInfo routerData={routerData} pageUrl={pageUrl} tenantList={tenantList} setExecData={setExecData} err={err} setExpand={setExpand}
                                   setEntries={setEntries} setLoader={setLoader} setShowEntries={setShowEntries} setShowInnerLoader={setShowInnerLoader}/>
            </div>

            {
                showEntries ? <>
                    <div className={"entries-heading"}>Entries</div>
                    {
                        <div className={"entries-info-wrapper"}>
                            <div>Status: <span className={`${execData && execData.execState} status-color`}>{execData && execData.execState || "Running"}</span></div>
                            {
                                execData?.router4GSignal ?  <div>Router 4G Signal: <span>{execData.router4GSignal}</span></div> : null
                            }
                            {
                                execData?.jasper ?  <div>Jasper: <span>{execData.jasper}</span></div> : null
                            }
                            {
                                execData?.routerActiveOn ?  <div>Router Active On: <span>{execData.routerActiveOn}</span></div> : null
                            }
                            {
                                execData?.routerSimRegistered ?  <div>Router Sim Registered: <span>{execData.routerSimRegistered}</span></div> : null
                            }
                        </div>
                    }

                    <div className="logs-display" onClick={() => setExpand(!expand)}><span>Logs</span>{(!expand) ?
                        <span>+</span> : <span>-</span>}</div>
                    {
                        expand ? <div className={"entries-wrapper"}>
                            {
                                entries && entries.length ? entries.map((item, index) => {
                                    return <div key={index}><span>Log# {index+1} ~</span>{item?.log}</div>
                                }) : null
                            }
                            {
                                showInnerLoader ?  <Spin
                                    indicator={
                                        <LoadingOutlined
                                            style={{
                                                fontSize: 22,
                                            }}
                                            spin
                                        />
                                    }
                                /> : null
                            }
                        </div> : null
                    }
                </> : null
            }
        </>
    )
}
export default RouterInfoPages;
