import React, {useEffect, useState} from 'react';
import '../style.scss';
import {Button, Modal, Space} from "antd";
import TextArea from "antd/es/input/TextArea";

const EditModal = ({isNextActionModalVisible, handleCancel,record, handleNoteSave}) => {
    const [textValue, setTextValue] = useState(record?.faultNote)

    useEffect(() => {
        if (record) setTextValue(record.faultNote);
    }, [record]);

    return (
        <>
            <Modal title={"Edit Next Action"}
                   open={isNextActionModalVisible}
                   className={"inspection-modal-wrapper"}
                //onOk={handleOk}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper inspection-modal">
                    <TextArea
                        value={textValue || null}
                        onChange={(e) => setTextValue(e.target.value)}
                        placeholder="Next Action"
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                    />

                    <div className="ant-modal-footer mt20">
                        <Space size="middle">
                            <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={() => handleNoteSave(textValue, record)}>
                               Save
                            </Button>
                        </Space>
                    </div>
                </div>
            </Modal>

        </>
    )
}
export default EditModal;
