import React, {useEffect, useState} from 'react';
import '../style.scss';
import PaginatedTable from "../../CommonComponents/PaginatedTable";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {Button, Form, Modal} from 'antd';
import {setColumnForBulkUploadList} from "../helper";
import {
    getDropdownsValuesBulkUploadAction,
    pushSiteForProcessAction,
    saveSiteForProcessAction
} from "../../../service-contracts/networkite/networkite-actions";
import {error as errorToast, success as successToast} from "../../../utils/toast-utils";
import {deleteSiteAction} from "../../../service-contracts/dashboard/dashboard-actions";
import SiteModal from "../SiteModal";
import {FIELDS_LIST} from "../SiteModal/helper";
import {useNavigate} from "react-router-dom";
import {USER_DETAILS} from "../../../constants";
import {getRequiredRoles} from "../../../Routing/helper";

const BulkUploadTable = ({
                             data,
                             loading,
                             tableParams,
                             handleTableChange,
                             onSearchInputChange,
                             searchInput,
                             fetchData,
                             fileId,
                             setLoading,
                             screenWidth
                         }) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalActionType, setModalAction] = useState("add");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [vendorRespData, setVendorRespData] = useState([]);
    const [siteTypeRespData, setSiteTypeRespData] = useState([]);
    const [nbnRespData, setNbnRespData] = useState([]);
    const [modal, contextHolder] = Modal.useModal();
    const [deleteRecord, setDeleteRecord] = useState(null);
    const [caseRespData, setCaseRespData] = useState([]);
    const navigate = useNavigate();

    const userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;

    const config = {
        title: `Are You sure you want to delete this site?`,
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        wrapClassName: "confirm-modal-wrap",
        onOk: () => {
            handleDelete()
        },
        onCancel: () => {
            setDeleteRecord(null);
        }
    };

    const OnConfirmClick = async (record) => {
        await setDeleteRecord(record);
    }

    useEffect(() => {
        if (deleteRecord?.upid) modal.confirm(config)
    }, [deleteRecord])

    const handleDelete = () => {
        setLoading(true);
        deleteSiteAction(deleteRecord?.upid)
            .then(res => {
                successToast(res?.data?.message || "Site has been deleted successfully");
                setSelectedRow([]);
                setSelectedRowKeys([]);
                fetchData();
            }).finally(() => {
            setLoading(false);
            setDeleteRecord(null);
        });
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            let csvSiteDataList = {...key, ...row};
            let payload = {
                "batchId": fileId,
                "csvSiteDataList": [
                    csvSiteDataList
                ]
            }
            setLoading(true);
            let response = await saveSiteForProcessAction(payload);
            if (response?.data?.status) {
                successToast(response?.data?.message || "Site has been modified successfully");
                fetchData();
                onSelectChange([]);
                setIsModalVisible(false);
            } else {
                errorToast(response?.data?.message || "Error Occurred");
                let msg = response?.data?.message
                if (msg == "Please fill Additional comment") {
                    form.getFieldInstance("additionalComments").focus();
                } else if (msg == "Please fill NTD Serial Number") {
                    form.getFieldInstance("ntdSerialNumber").focus();
                }
                errorToast(msg);
            }
            setLoading(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const editHandler = (data) => {
        form.resetFields();
        setModalAction('edit');
        if (data) {
            const initialValues = {id: data.id};
            FIELDS_LIST.map(item => {
                initialValues[item] = data[item]
            })
            form.setFieldsValue(initialValues);
        }
        setIsModalVisible(true);
    }

    const _userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;
    let shouldRedirectToSiteSchedule = getRequiredRoles("siteSchedule").some((item) => _userRole.includes(item));

    let colData = setColumnForBulkUploadList(OnConfirmClick, screenWidth,editHandler,navigate, userRole, shouldRedirectToSiteSchedule);

    const onSelectChange = (newSelectedRowKeys) => {
        let list = data.filter(item => newSelectedRowKeys.includes(item.id));
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRow(list)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.published || record.isDeleted,
            key: record.key
        })
    };

    const bulkPublish = async () => {
        let payload = {
            "batchId": fileId,
            "csvSiteDataList": selectedRow || [],
        }

        let response = await pushSiteForProcessAction(payload);
        if (response?.data?.status) {
            successToast(response?.data?.message || "Sites has been published successfully");
            fetchData();
            onSelectChange([]);
        } else {
            errorToast(response?.data?.message || "Error Occurred");
        }
    }

    const handleAddNewRow = async () => {
        setModalAction('add');
        form.resetFields();
        setIsModalVisible(true);
    }

    React.useEffect(() => {

        const fetchStatus = async () => {
            const [vendorResp, siteTypeResp, nbnResp, caseResp] = await Promise.all([
                getDropdownsValuesBulkUploadAction('SITE_INSTALL_VENDOR'),
                getDropdownsValuesBulkUploadAction('SITE_TYPE'),
                getDropdownsValuesBulkUploadAction('NBN_TECHNOLOGY'),
                getDropdownsValuesBulkUploadAction('SITE_UPLOAD_CASE'),
            ]);

            if (vendorResp?.data?.status) {
                let _data = vendorResp?.data?.data;
                setVendorRespData(_data)
            }
            if (siteTypeResp?.data?.status) {
                let _data = siteTypeResp?.data?.data;
                setSiteTypeRespData(_data)
            }
            if (nbnResp?.data?.status) {
                let _data = nbnResp?.data?.data;
                setNbnRespData(_data)
            }
            if (caseResp?.data?.status) {
                let _data = caseResp?.data?.data;
                setCaseRespData(_data)
            }
            // setLoading(false);
        };
        fetchStatus();

    }, []);

    return (
        <>
            <div className="user-setup-top">
                <Input size="large" placeholder="Search by Site Name or UPID" className="search-box" value={searchInput}
                       data-testid="search-input"
                       prefix={<SearchOutlined/>} onChange={onSearchInputChange} allowClear={true}/>
                <div>
                    <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={handleAddNewRow}
                            style={{"marginRight": "10px"}}>
                        Add New Site
                    </Button>

                    <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={bulkPublish}
                            data-testid={'publish-button'}
                            disabled={!(selectedRowKeys && selectedRowKeys.length)}>
                        Publish
                    </Button>
                </div>

            </div>

            <div className="table-filter-wrapper bulk-upload-table-wrapper" data-test-id={"bulk-upload-table"}>
                <PaginatedTable
                    columns={colData}
                    data={data}
                    loading={loading}
                    tableParams={tableParams}
                    handleTableChange={handleTableChange}
                    scrollX={'max-content'}
                    dataTestId={"vendor-table"}
                    rowSelection={rowSelection}
                    rowKey={(record) => record.id}
                />
            </div>
            <SiteModal isModalVisible={isModalVisible}
                       handleCancel={handleCancel}
                       modalActionType={modalActionType}
                       handleOk={save}
                       form={form}
                       vendorRespData={vendorRespData}
                       siteTypeRespData={siteTypeRespData}
                       caseRespData={caseRespData}
                       nbnRespData={nbnRespData}/>
            {contextHolder}
        </>
    )
}

const mapStateToProps = (state) => ({
    isDesktopView: state.commonReducer.isDesktopView,
    screenWidth: state.commonReducer.screenWidth,
});

export default connect(
    mapStateToProps,
    null,
)(BulkUploadTable);
