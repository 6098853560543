import config from '../../../config';
import { httpAuth } from '../../utils/http-utility';

// eslint-disable-next-line no-unused-vars
/*const url = config.auth;

// TODO work out what token is and what he returned type is <---
export const getAuthDetails = async (token) => {
  const apiResponse = await httpAuth.post(
    url,
    { authToken: token },
  );

  return apiResponse;
};*/

export const loginUser = async (payload) => {
  const loginApiResponse = await httpAuth.post(
      `${config.API_ROOT}/login`,
      payload,
  );

  return loginApiResponse;
};

export const changePassword = async (payload) => {
  const apiResponse = await httpAuth.post(
      `${config.API_ROOT}/changepassword`,
      payload,
  );

  return apiResponse;
};

export const sendResetLink = async (payload) => {
  const apiResponse = await httpAuth.post(
      `${config.API_ROOT}/resetpassword/sendresetlink`,
      payload,
  );

  return apiResponse;
};

export const resetpassword = async (payload) => {
  const apiResponse = await httpAuth.post(
      `${config.API_ROOT}/resetpassword/savepassword`,
      payload,
  );

  return apiResponse;
};

