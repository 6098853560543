/* eslint-disable no-unused-vars */
import axios from 'axios';
import {USER_DETAILS} from "../constants";

//const token = getAuthInfo();
//const t = config.auth;
const httpAuth = axios.create({
  //baseURL: config.auth,
  timeout: 30000,
});

httpAuth.interceptors.request.use((conf) => {
  let authInfo = JSON.parse(localStorage.getItem(USER_DETAILS));
  let _token =  authInfo?.access_token;

  // eslint-disable-next-line no-param-reassign
  if(_token){
    conf.headers = {
       //token: `${token}`,
      Authorization: `Bearer ${_token}`,
    };
  }

  return conf;
});

httpAuth.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
      /*if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const userInfo = JSON.parse(
            sessionStorage.getItem('USER_DETAILS'),
        );
        if (userInfo && userInfo.refresh_token) {
          const reqObj = {
            refreshToken: userInfo.refresh_token,
            //userType: userInfo.userType.toString(),
          };
          /!*const { data: apiResponse } = await commonServices.login(
              reqObj,
          );*!/
         /!* if (apiResponse.status) {
            const access_token = apiResponse.data.access_token;
            setItem('token', access_token);
            delete apiResponse.data.access_token;
            saveUserDataInLocal(apiResponse.data)
            // setItem('userInfo', JSON.stringify(apiResponse.data));
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + access_token;
            return axios(originalRequest);
          } else {
            logoutHandler();
            window.location.href = '/sessionExpired/true';
            // return Promise.reject(error);
          }*!/
        } else {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/';
        }
      }*/
      return Promise.reject(error);
    },
);

export { httpAuth };
