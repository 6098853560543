import {getRoleList, getUserDisableToggle, getUserList, registerUser} from "./users-services";
import {error as errorToast} from "../../utils/toast-utils";

export const register = async (user, isUpdate) => {
    try{
        const response = await registerUser(user);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getUseListAction = async (payload) => {
    try{
        const response = await getUserList(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRoleListAction = async (payload) => {
    try {
        const response = await getRoleList(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getUserDisableToggleAction = async (id, checked) => {
    try{
        const response = await getUserDisableToggle(id,checked);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};
