import calendarIcon from '../../../assets/images/calendar.svg';
import {getRequiredRoles} from "../../Routing/helper";
import {ROLES} from "../../constants";

export const sideBarMenus = [
  {
    label: 'My Dashboard',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: true,
    icon: calendarIcon,
    url: '/dashboard',
    roles: getRequiredRoles('dashboard')
  },
  {
    label: 'Site Schedules',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/siteSchedule',
    roles: getRequiredRoles('siteSchedule')
  },
  {
    label: 'Site Faults',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/siteFaults',
    roles: getRequiredRoles('siteFaults')
  },
  {
    label: 'Cable Remediation',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/cableRemediation',
    roles: getRequiredRoles('cableRemediation')
  },
  {
    label: 'Router Config',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/routerConfig',
    roles: getRequiredRoles('routerConfig')
  },
  {
    label: 'Router Operational Test',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/routerOperationalTest',
    roles: getRequiredRoles('routerOperationalTest')
  },
  {
    label: 'User Management',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/users',
    checkIfAdminRights: true,
    roles: getRequiredRoles('users')
  },
  {
    label: 'Forecast Value',
    // icon: <IconStudentAssignments width="19px" height="17px" />,
    iconImg: false,
    url: '/forecast-value',
    checkIfAdminRights: true,
    roles: getRequiredRoles('forecast-value')
  },
  {
    label: 'Networkite',
    iconImg: false,
    url: '/networkite',
    checkIfAdminRights: true,
    roles: getRequiredRoles('networkite')
  },
  // {
  //   label: 'Bulk Upload',
  //   iconImg: false,
  //   url: '/bulkUpload',
  //   checkIfAdminRights: true,
  //   roles: [ROLES.ADMIN]
  // },
  {
    label: 'Site Creation in Netbox',
    iconImg: false,
    url: '/sitecreation',
    checkIfAdminRights: true,
    roles: [ROLES.OPERATIONS, ROLES.ADMIN,ROLES.SUPER_ADMIN]
  },
  {
    label: 'Logs',
    iconImg: false,
    url: '/logs',
    checkIfAdminRights: true,
    roles: [ROLES.SUPER_ADMIN,ROLES.ADMIN, ROLES.VENDOR_MAXTECH]
  },
];

const pathNameHeading = {
  "/routerConfig" : "Router Config",
  "/routerOperationalTest" : "Router Operational Test",
  "/dashboard" : "Overview",
  "/siteSchedule" : "SDWAN Site Schedules",
  "/users" : "User Management",
  "/change-password" : "Change Password", //NOSONAR
  "/siteFaults" : "Site Faults",
  "/cableRemediation" : "Cable Remediation",
  "/forecast-value" : "Forecast Value",
  "/networkite" : "Networkite",
  "/batchUpload" : "Batch Upload",
  "/logs" : "Logs",
  "/sitecreation" : "Site Creation in Netbox",
}

export const getPageHeading = (pathname) => {
   return pathNameHeading[pathname] || "";
}
