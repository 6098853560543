import { CreateSite, getRegionId } from "./sitecreation-service"
import {error as errorToast} from "../../utils/toast-utils";

export const CreateSiteAction = async (payload) => {
    try {
        const response = await CreateSite(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};


export const getRegionIdAction = async (payload) => {
    return await getRegionId(payload);
};