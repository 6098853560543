import React from 'react';
import './style.scss';
import CableRemediation from "../CableRemediation";

const SiteFaults = () => {

    return (
       <CableRemediation isSiteFaultListingRequired={true}/>
    )
}
export default SiteFaults;

