import {ACTION} from "../constants";
import {isDesktopView} from "./common";

export const windowResize = () => {
    return function (dispatch) {
        dispatch({
            type: ACTION.WINDOW_RESIZE,
            data: isDesktopView()
        });
    };
};
