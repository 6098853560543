import React, {useEffect, useState} from 'react';
import '../style.scss';
import {Button, DatePicker, Form, InputNumber, Modal, Select, Space} from "antd";
import Loader from "../../Notifications/Loader";
import {
    getSiteStatusListAction,
    getVendorListAction, setTargetDataAction
} from "../../../service-contracts/dashboard/dashboard-actions";
import {GRAPH_TITLE} from "../../Dashboard/helper";
import {Option} from "antd/es/mentions";
import {error as errorToast, success as successToast} from "../../../utils/toast-utils";

const SetTargetModal = ({setIsModalVisible, mode, isModalVisible, callAfterTargetSet}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [siteStatusList, setSiteStatusList] = useState([]);

    let modalHeading = mode === GRAPH_TITLE.WEEKLY_REPORT ? "Set Forecast Values for Sites Installed and/or Cutover (per Week)"
        : "Set Forecast Values for Sites Cutover (per Month)"

    const handleOk = () => {
        form.validateFields().then((value) => {
            saveHandler();
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const saveHandler = async () => {
        let formData = form.getFieldValue();
        let payload = {};

        try {
            if (mode === GRAPH_TITLE.WEEKLY_REPORT) {
                payload = {
                    "vendor": formData.vendor,
                    "week": formData.week,
                    "siteStatus": formData.siteStatus,
                    "target": formData.target
                }
            } else {
                payload = {
                    "month": formData.month,
                    "target": formData.target
                }
            }
            setLoading(true);
            let response =  await setTargetDataAction(payload, mode);
            if (response?.data?.status) {
                successToast(response?.data?.message || "Forecast has been set successfully");
                callAfterTargetSet();
                setIsModalVisible(false);
                form.resetFields();
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setLoading(false);
        }
    };

    const onVendorChange = (val) => {
        let setVal = val === "MAXTECH" ? "CUTOVER" : "ROUTER_INSTALL"
        form.setFieldsValue({
            siteStatus: setVal
        })
    }

    const fetchDataList = () => {
        const fetchList = async () => {
            setLoading(true);
            const vendorResponse = await getVendorListAction();
            const statusResponse = await getSiteStatusListAction();
            if (vendorResponse && vendorResponse.data) {
                let data = vendorResponse.data;
                setVendorList(data);
            }
            if (statusResponse && statusResponse.data) {
                let data = statusResponse.data;
                setSiteStatusList(data);
            }
            setLoading(false);
        };
        if (mode === GRAPH_TITLE.WEEKLY_REPORT) fetchList();
    }

    useEffect(() => {
        if (isModalVisible) fetchDataList();
    }, [isModalVisible]);

    return (
        <>
            <Modal title={modalHeading}
                   open={isModalVisible}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
                   width={580}
            >
                {
                    loading ? <Loader/> : null
                }
                <div className="add-new-modal-wrapper" data-testid="modal">
                    <Form layout="vertical" className="calendar-mapping-form" name="calendarMapping" form={form}>
                        {
                            mode === GRAPH_TITLE.WEEKLY_REPORT ? <Form.Item
                                className="input-text-field height-40"
                                name={`week`}
                                label={`Week`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Week',
                                    },
                                ]}
                            >
                                <DatePicker picker="week" className="width-100"/>
                            </Form.Item> : null
                        }

                        {
                            mode === GRAPH_TITLE.SITES_CUTOVER ? <Form.Item
                                className="input-text-field height-40"
                                name={`month`}
                                label={`Month`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Month',
                                    },
                                ]}
                            >
                                <DatePicker picker="month" className="width-100"/>
                            </Form.Item> : null
                        }

                        {
                            mode === GRAPH_TITLE.WEEKLY_REPORT ?
                                <Form.Item
                                    className="select-dropdown-field height-40"
                                    name={`vendor`}
                                    label={`Vendor`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Vendor',
                                        },
                                    ]}
                                >
                                    <Select
                                        showArrow={false}
                                        popupClassName="select-dropdown-menu"
                                        placeholder="Select Vendor"
                                        showSearch={true}
                                        onChange={onVendorChange}
                                    >
                                        {
                                            vendorList && vendorList.length && vendorList.map(item => {
                                                return <Option value={item} key={item}>{item}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item> : null
                        }

                        {
                            mode === GRAPH_TITLE.WEEKLY_REPORT ? <Form.Item
                                className="select-dropdown-field height-40"
                                name={`siteStatus`}
                                label={`Site Status`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Site Status',
                                    },
                                ]}
                            >
                                <Select
                                    showArrow={false}
                                    popupClassName="select-dropdown-menu"
                                    placeholder="Select Site Status"
                                    showSearch={true}
                                >
                                    {
                                        siteStatusList && siteStatusList.length && siteStatusList.map(item => {
                                            return <Option value={item} key={item}>{item.replace(/_/g, " ")}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item> : null
                        }

                        <Form.Item
                            className="input-text-field height-40"
                            name={`target`}
                            label={`Forecast Value`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Forecast Value',
                                },
                            ]}
                        >
                            <InputNumber min={0} className="width-100"/>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="example btn primary-btn ant-btn-primary primary-tab-btn save-btn" data-testid={"save-btn"} onClick={() => saveHandler()}>
                                    Save
                                </Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
export default SetTargetModal;
