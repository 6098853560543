import config from '../../../config';
import {httpAuth} from "../../utils/http-utility";

export const registerUser = async (payload) => {
    const registerApiResponse = await httpAuth.post(
        `${config.API_ROOT}/createmodifyuser`,
        payload,
    );
    return registerApiResponse;
};

export const getUserList = async (payload) => {
    const registerApiResponse = await httpAuth.post(
        `${config.API_ROOT}/userlist`,
        payload,
    );
    return registerApiResponse;
};

export const getRoleList = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/getrolelist`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getUserDisableToggle = async (id, checked) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/${checked ? "disable" : "enable"}/${id}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};
