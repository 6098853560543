import React, {useEffect} from 'react';
import {
    Card,
    Form,
    Input,
    Button,
} from 'antd';
import '../index.scss';
import {resetpassword} from "../../../service-contracts/auth/auth-service";
import {useAuth} from "../auth";
import Loader from "../../Notifications/Loader";
import {success as successToast} from "../../../utils/toast-utils";
import {useLocation, useNavigate} from "react-router-dom";
import {error as errorToast} from "../../../utils/toast-utils";
import Logo from "../../../../assets/images/logoTab.png";
import {validatePassword} from "../../../utils/common";

const ResetPasswordForm = (props) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [token, setToken] = React.useState('');
    const {
        logout
    } = useAuth();
    const navigate = useNavigate();

    /**
     * Function to reset the password
     * @param {form values for changing password} values
     */
    const onFinish = async (values) => {
        if(values.new_password !== values.confirm_password){
            errorToast("Confirm Password not matched with New Password");
            return;
        }
        try {
            const payload = {
                "email": token,
                "new_password": values.new_password,
                "confirm_password": values.confirm_password,
            };
            setIsLoading(true);
            let resp = await resetpassword(payload);
            if(resp?.data?.status){
                successToast(resp?.data?.message || "New Password has been modified");
                logout();
                navigate("/");
            }
            else{
                errorToast(resp?.data?.message);
            }
        } catch (error) {
            errorToast("Error Occurred");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        // Extract the email from the query parameter
        const searchParams = new URLSearchParams(location.search);
        const _token = searchParams.get('token');
        setToken(_token);
       // navigate('/reset-password');
    }, [location.search]);

    return (
        <div className="login-wrapper">
            {
                isLoading ? <Loader/> : null
            }
            <div className="logo">
                <img src={Logo} alt="logo" className="logo-img" onClick={() => navigate("/")}/>
            </div>
            <div className="login-body">
                    <Card
                        className="card-main forget-password-card"
                    >
                        <Form
                            name="normal_login"
                            className="login-form card-space"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                className="input-row"
                                name="new_password"
                                label="New Password"
                                normalize={(value, prevVal, prevVals) => value.trim()}
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        validator:validatePassword
                                    }
                                ]}
                            >
                                <Input.Password placeholder="New password"/>
                            </Form.Item>
                            <Form.Item
                                className="input-row margin-top-space"
                                name="confirm_password"
                                label="Confirm Password"
                                normalize={(value, prevVal, prevVals) => value.trim()}
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        validator:validatePassword
                                    }
                                ]}
                            >
                                <Input.Password placeholder="Confirm password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className=" btn primary-btn mb25 primary-tab-btn"
                                    block
                                >
                                    Reset Password
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
