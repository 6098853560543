import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {Button, Form, Modal, Select, Space} from "antd";
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import Input from "antd/es/input/Input";
import {Option} from "antd/es/mentions";
import PaginatedTable from "../CommonComponents/PaginatedTable";
import {
    getRoleListAction,
    getUseListAction,
    getUserDisableToggleAction,
    register
} from '../../service-contracts/users/users-actions';
import {setColumnForUserList} from "./helper";
import Loader from "../Notifications/Loader";
import {checkmail, debounce} from "../../utils/common";
import {error as errorToast, success as successToast} from "../../utils/toast-utils";
import {handleCancel, handleSearch, handleTableChange} from "../CableRemediation/helper";
import {TABLE_PAGE_SIZE, USER_DETAILS} from "../../constants";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../Auth/auth";
import {getSitePauseAction} from "../../service-contracts/dashboard/dashboard-actions";

const Users = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const auth = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalActionType, setModalAction] = useState("add");
    const [listData, setListData] = useState();
    const [loading, setLoading] = useState(false);
    const [reloadList, setReloadList] = useState(false);
    const [roleList, setRoleList] = useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: TABLE_PAGE_SIZE,
            pageSizeOptions: ['25', '50', '75', '100'],
            showSizeChanger: true,
        },
        textSearch: ''
    });

    const _userEmail = JSON.parse(localStorage.getItem(USER_DETAILS))?.email;

    const handleTable = (pagination, filters, sorter) => {
        handleTableChange(pagination, filters, sorter,setTableParams, tableParams , setReloadList, setListData);
    }

    const debouncedSearch = useCallback(
        debounce((val) => {
            handleSearch(val,setTableParams, tableParams, setReloadList)
        }, 1000),
        [],
    );

    const onSearchInputChange = (e) => {
        setSearchInput(e.target.value)
    }

    const logout = () => {
        auth.logout();
        navigate("/");
    };

  const onUserDisableToggle = async (checked, _data) => {
    setLoading(true);
    let response = await getUserDisableToggleAction(_data?.id, checked);
    if (response?.data?.data === "User has been disabled"
        || response?.data?.data === "User has been enabled") {
      setLoading(false);
    } else {
      errorToast(response?.data?.data || "Error Occurred");
      setLoading(false);
    }
  }

    const fetchData = (fetchAfterAdd = false) => {
        setLoading(true);
        let payload = {
            "pageNo": tableParams?.pagination?.current,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch
        }
        if (fetchAfterAdd) {
            payload = {
                "pageNo": 1,
                "pageSize": TABLE_PAGE_SIZE,
                "textSearch": ''
            }
            if(searchInput !== ''){
                setSearchInput('')
                return;
            }
        }
        getUseListAction(payload).then(resp => {
            //const {data: {data}} = resp;
            setListData(resp?.data?.data?.userList);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current: resp?.data?.data.pageNo,
                    total: resp?.data?.data.totalElements,
                    pageSize: resp?.data?.data.pageSize,
                },
            });
            setReloadList(false);
        });
    };

    const handleOk = () => {
        form.validateFields().then((value) => {
            saveOrUpdateHandler(modalActionType !== 'add');
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    };

    const editHandler = (data) => {
        form.resetFields();
        setModalAction('edit');
        if (data) {
            const initialValues = {
                id: data?.id,
                firstname: data?.firstname,
                lastname: data?.lastname,
                role: data?.role,
                email: data?.email,
            }
            form.setFieldsValue(initialValues);
        }
        setIsModalVisible(true);
    }

    const saveOrUpdateHandler = async (isUpdate) => {
        let formData = form.getFieldValue();
        try {
            const payload = {
                "firstname": formData.firstname,
                "lastname": formData.lastname,
                "email": formData.email,
                "role": formData.role,
            };
            if (isUpdate) {
                payload["id"] = formData.id;
            }
            setIsLoading(true);
            let response = await register(payload, isUpdate);
            if(response?.data?.status){
                if(isUpdate){
                    successToast(response?.data?.message || "User has been modified successfully");
                    if(_userEmail === formData.email){
                        logout();
                        return false;
                    }
                }
                else{
                    successToast(response?.data?.message || "User has been created successfully");
                }
                setIsModalVisible(false);
                form.resetFields();
                fetchData(!isUpdate);
            }
            else{
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        debouncedSearch(searchInput)
    }, [searchInput]);

    useEffect(() => {
        if (reloadList) fetchData();
    }, [reloadList]);

    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            const roleResponse = await getRoleListAction();
            if (roleResponse && roleResponse.data) {
                let data = roleResponse?.data?.data;
                setRoleList(data);
            }
            setLoading(false);
        };
        if (isModalVisible) fetchList();
    }, [isModalVisible]);

    return (
        <div className="pd-25">
            {
                isLoading ? <Loader/> : null
            }
            <div className="user-setup-top">
                <Input size="large" placeholder="Search by Name or Email Id" className="search-box" value={searchInput}
                       prefix={<SearchOutlined/>} onChange={onSearchInputChange} allowClear={true}/>
                <Button onClick={() => {
                    setModalAction("add")
                    setIsModalVisible(true)
                }}
                        icon={<PlusOutlined/>}
                        className="btn-expand secondary-tab-btn">
                    Add New User
                </Button>
            </div>

            <PaginatedTable
                columns={setColumnForUserList(setModalAction, setIsModalVisible, editHandler,onUserDisableToggle)}
                data={listData}
                loading={loading}
                tableParams={tableParams}
                handleTableChange={handleTable}
                dataTestId={"element"}
            />

            <Modal title={modalActionType === 'edit' ? 'Edit User' : ' Create User'}
                   open={isModalVisible}
                   onOk={handleOk}
                   onCancel={() => handleCancel(form,setIsModalVisible)}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper" data-testid={"modal"}>
                    <Form layout="vertical" className="add-user-form" name="calendarMapping" form={form}>
                        {/*<Form.Item name={"id"} hidden={true}/>*/}
                        <Form.Item
                            className="input-text-field height-40"
                            name={`firstname`}
                            label={`First Name`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter FirstName',
                                },
                            ]}
                        >
                            <Input placeholder='First Name'/>
                        </Form.Item>

                        <Form.Item
                            className="input-text-field height-40"
                            name={`lastname`}
                            label={`Last Name`}
                        >
                            <Input placeholder='Last Name'/>
                        </Form.Item>

                        <Form.Item
                            name="email"
                            className="input-row height-40"
                            label={`Email Id`}
                            rules={[
                                {
                                    validator: checkmail,
                                },
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >

                            <Input placeholder='Email Id'/>

                        </Form.Item>

                        <Form.Item
                            className="select-dropdown-field multi-dropdown"
                            name={`role`}
                            label={`Role`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Role',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                showArrow={false}
                                popupClassName="select-dropdown-menu"
                                placeholder="Select Role"
                                className="err-margin"
                                showSearch={true}
                            >
                                {
                                    roleList && roleList.length && roleList.map(item => {
                                        return (item === "DELETE SITE" || item ===  "SCAN INSPECTION" || item === "TECH SUPPORT") ? null : <Option value={item} key={item} data-testid={`${item}`}>{item}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={() => handleOk()}>
                                    {modalActionType === 'add' ? 'Save' : 'Update'}
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>
        </div>
    )
}
export default Users;
