import AppBreadcrumb from "./index";
import React from "react";

export const Dashboard = {
  path: '/batchUpload',
  title: 'Batch Upload',
}

export const bulkUploadBreadcrumb = (id) => [
  {
    path: '/batchUpload',
    title: 'Batch Upload',
  },
  {
    path: '',
    title: `${id}`,
  }
];

export const logsBreadcrumb = (id) => [
  {
    path: '/logs',
    title: 'Logs',
  },
  {
    path: '',
    title: `${id}`,
  }
];


