import React, {useState, useEffect, useRef, useContext} from 'react';
import './style.scss';
import PaginatedTable from "../CommonComponents/PaginatedTable";
import {setColumnForUserList} from "./helper";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {setColumnForSiteFaultList} from "../SiteFaults/helper";
import {setColumnForCableRemediationList} from "../CableRemediation/helper";
import {Checkbox, Form} from 'antd';
import {FilterFilled} from '@ant-design/icons';
import {DATE_FILTER, DATE_FILTER_DATA, ROLES, USER_DETAILS} from "../../constants";
import {setColumnForBulkUploadList} from "../BulkUpload/helper";
import { DatePicker } from 'antd';
import dayjs from "dayjs";

const VendorTable = ({
                         setIsModalVisible,
                         editHandler,
                         data,
                         loading,
                         tableParams,
                         handleTableChange,
                         onSearchInputChange,
                         searchInput,
                         screenWidth,
                         onCreateClick,
                         onSitePauseToggle,
                         sourceComponent = "siteSchedule",
                         isHistoryTabSelected,
                         isFiltersRequired,
                         handleFilters,
                         filterReqObj,
                         clearFilters,
                         setClearFilters,
                         fetchData,
                         handleFaultOwnerSave,
                         className,
                         handleNoteSave,
                         editNextActionHandler,
                         record,
                         editStatusRevertHandler
                     }) => {
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const ref = useRef();
    const [toggleFilter, setToggleFilter] = useState(false);
    const CheckboxGroup = Checkbox.Group;
    const [filterReq, setFilterReq] = useState({
        "statusFilters": [],
        "stateFilters": [],
        "productFilters": [],
        "vendorFilters":[],
        "threeGFilters":[]
    })

    const onChange = (list, filterType) => {
        switch (filterType){
            case "Status":
                setFilterReq({...filterReq, statusFilters: list})
                break;
            case "States":
                setFilterReq({...filterReq, stateFilters: list})
                break;
            case "Type":
                setFilterReq({...filterReq, productFilters: list})
                break;
            case "Vendors":
                setFilterReq({...filterReq, vendorFilters: list})
                break;
            case "ThreeG":
                setFilterReq({...filterReq, threeGFilters: list})
                break;
            default:
                return;
        }
    };

    const onDateChange = (value, filterType) => {
        let start = value && value[0]?.format();
        let end = value && value[1]?.format();
        let dateObj = {
            "startDate": start,
            "endDate": end,
        }
        if(filterType === "siteCutoverScheduleDate"){
            setFilterReq({...filterReq, siteCutoverScheduledDateFilter: dateObj })
        }
        else if(filterType === "siteInstallScheduleDate"){
            setFilterReq({...filterReq, siteInstallScheduledDateFilter: dateObj })
        }
    }

    let colData;
    const _userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;
    let shouldRedirectToNetbox = ([ROLES.SUPER_ADMIN,ROLES.ADMIN,ROLES.OPERATIONS,ROLES.VENDOR_MAXTECH].some(item => _userRole.includes(item)));

    switch(sourceComponent) {
        case "siteSchedule":
            colData = setColumnForUserList(setIsModalVisible, editHandler, navigate, screenWidth, onCreateClick, onSitePauseToggle,_userRole,shouldRedirectToNetbox, isHistoryTabSelected,editStatusRevertHandler);
            break;
        case "siteFault":
            colData = setColumnForSiteFaultList(setIsModalVisible, editHandler, isHistoryTabSelected,screenWidth, shouldRedirectToNetbox, handleFaultOwnerSave, handleNoteSave, editNextActionHandler);
            break;
        case "bulkUpload":
            colData =  setColumnForBulkUploadList(setIsModalVisible, editHandler, isHistoryTabSelected,screenWidth, shouldRedirectToNetbox);
            break;
        default:
            colData = setColumnForCableRemediationList(setIsModalVisible, editHandler, isHistoryTabSelected, screenWidth, shouldRedirectToNetbox, fetchData);
    }

    const onOpenFilter = () => {
        setToggleFilter(!toggleFilter);
    }

    const onFilterApply = () => {
        let obj = {};
        if(filterReqObj){
            for (const key in filterReq) {
                if (filterReq.hasOwnProperty(key)) {
                    if(key==="siteInstallScheduledDateFilter" || key==="siteCutoverScheduledDateFilter"){
                        obj[key] = filterReq[key] || {}
                    }
                    else{
                        let _data = filterReqObj && filterReqObj[key];

                        let expectedFilter = filterReq[key] && filterReq[key].length && filterReq[key].map((item) => {
                            item = Object.keys(_data).find(_key => _data[_key] === item);
                            return item;
                        })

                        obj[key] = expectedFilter || [];
                    }
                }
            }
        }
        handleFilters(obj);
        setToggleFilter(false);
    }

    const EditableContext = React.createContext(null);
    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({
                              title,
                              editable,
                              children,
                              dataIndex,
                              record,
                              handleSave,
                              index,
                              ...restProps
                          }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [index]: record[index],
            });
        };
        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };
        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={index}
                    className="table-input-box"
                    rules={[
                        {
                            max: 100,
                            message: `Only 100 Characters.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = colData.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: col.handleSave,
                index: col.index,
            }),
        };
    });

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (toggleFilter && ref.current && !ref.current.contains(e.target)) {
                setToggleFilter(false)
            }
            if((e.target.className === "filter-btn" && toggleFilter) ||
                (toggleFilter && e.target.nodeName === "path" && e.target?.parentNode?.parentNode?.className.includes('filter-btns'))){
                setToggleFilter(true)
            }
        }
       // document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
           // document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [toggleFilter])

    useEffect(() => {
        if(clearFilters) setFilterReq({
            "statusFilters": [],
            "stateFilters": [],
            "productFilters": [],
            "vendorFilters":[],
            "threeGFilters":[]
        })
        setClearFilters(false);
        setToggleFilter(false);
    }, [clearFilters])

    let display = toggleFilter ? "block" : "none";

    return (
        <>
            <div className="user-setup-top">
                <Input size="large" placeholder="Search by Site Name or UPID" className="search-box" value={searchInput}
                       data-testid="search-input"
                       prefix={<SearchOutlined/>} onChange={onSearchInputChange} allowClear={true}/>

                {
                    isFiltersRequired ?<div className="filter-btn-wrapper">
                        <div onClick={onOpenFilter} className="filter-btn" id={"filter-btn"} data-testid="filter-test-id">
                            <FilterFilled className="filter-btns" />
                        </div>
                    </div> : null
                }
            </div>

            <div className="table-filter-wrapper">
            <PaginatedTable
                columns={columns}
                data={data}
                loading={loading}
                tableParams={tableParams}
                handleTableChange={handleTableChange}
                scrollX={(sourceComponent !== "siteSchedule" && isHistoryTabSelected) ? 1300 : 1700}
                dataTestId={"vendor-table"}
                width={toggleFilter ? "79%" : "100%"}
                toggleFilter={toggleFilter}
                components={components}
                className={className}
                record={record}
            />

                {
                    toggleFilter ? <div style={{display: display}} className="filter-wrapper-box"  ref={ref}>
                        <div onClick={() => {setToggleFilter(false)}} className="close-filter-btn">Close</div>
                        <div className="filter-checkboxes">
                            {
                                filterReqObj && Object.values(filterReqObj["vendorFilters"]) && Object.keys(Object.values(filterReqObj["vendorFilters"])).length?   <div>
                                    <div className="filter-heading">Vendor</div>
                                    <CheckboxGroup options={Object.values(filterReqObj["vendorFilters"])} value={filterReq["vendorFilters"] } onChange={(e) => onChange(e,"Vendors")}/>
                                </div>: null
                            }

                            {
                                filterReqObj && Object.values(filterReqObj["statusFilters"]) && Object.keys(Object.values(filterReqObj["statusFilters"])).length ?    <div>
                                    <div className="filter-heading">Status</div>
                                    <CheckboxGroup options={Object.values(filterReqObj["statusFilters"])} value={filterReq["statusFilters"] } onChange={(e) => onChange(e,"Status")}/>
                                </div> : null
                            }

                            {
                                filterReqObj && Object.values(filterReqObj["stateFilters"]) && Object.keys(Object.values(filterReqObj["stateFilters"])).length ?   <div>
                                    <div className="filter-heading">States</div>
                                    <CheckboxGroup options={Object.values(filterReqObj["stateFilters"])} value={filterReq["stateFilters"] } onChange={(e) => onChange(e,"States")}/>
                                </div> : null
                            }

                            {
                                filterReqObj && Object.values(filterReqObj["productFilters"]) && Object.keys(Object.values(filterReqObj["productFilters"])).length ?   <div>
                                    <div className="filter-heading">Product Type</div>
                                    <CheckboxGroup options={Object.values(filterReqObj["productFilters"])} value={filterReq["productFilters"] } onChange={(e) => onChange(e,"Type")}/>
                                </div>: null
                            }

                            {
                                filterReqObj && Object.values(filterReqObj["threeGFilters"]) && Object.keys(Object.values(filterReqObj["threeGFilters"])).length ?   <div>
                                    <div className="filter-heading">3G</div>
                                    <CheckboxGroup options={Object.values(filterReqObj["threeGFilters"])} value={filterReq["threeGFilters"] } onChange={(e) => onChange(e,"ThreeG")}/>
                                </div>: null
                            }

                            {
                                filterReqObj && Object.values(filterReqObj["scheduleFilters"]) && Object.keys(Object.values(filterReqObj["scheduleFilters"])).length ?
                                    Object.values(filterReqObj["scheduleFilters"]).map((item,index) => {
                                        let filterName = DATE_FILTER_DATA[item];
                                        let initialStart = filterReq && filterReq[filterName]?.startDate ? dayjs(dayjs(filterReq[filterName]?.startDate).toISOString(), "YYYY-MM-DD") : null;
                                        let initialEnd = filterReq && filterReq[filterName]?.endDate ? dayjs(dayjs(filterReq[filterName]?.endDate).toISOString(), "YYYY-MM-DD") : null;
                                        const initialRange = [initialStart, initialEnd];
                                        return <div key={index}>
                                            <div className="filter-heading">{DATE_FILTER[item]}</div>
                                            <RangePicker allowEmpty={["true","true"]}
                                                         onChange={(e) => onDateChange(e,item)}
                                                         defaultValue={initialRange}
                                            />
                                        </div>
                                    })
                                    : null
                            }

                            {/* <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                    {!checkAll ? "Check all" : "Clear All"}
                                </Checkbox>*/}
                            <button data-testid="filter-apply-id" onClick={onFilterApply}>Apply</button>
                        </div>
                    </div> : null
                }

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isDesktopView: state.commonReducer.isDesktopView,
    screenWidth: state.commonReducer.screenWidth,
});

export default connect(
    mapStateToProps,
    null,
)(VendorTable);
