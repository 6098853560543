import React, {useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Logo from '../../../assets/images/logoTab.png';

import {
    UserOutlined,
} from '@ant-design/icons';
import {sideBarMenus} from './helper';
import {USER_DETAILS} from "../../constants";

const {Sider} = Layout;
const {SubMenu} = Menu;

const Sidebar = ({toggle}) => {
    let location = useLocation();
    const [current, setCurrent] = useState(location.pathname);
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;

    const onLogoClick = () => {
        navigate('/dashboard')
    }

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    /**
     * Function to get the menus for sidebars
     */
    const getMenus = () => {
        return sideBarMenus.map((menus, index) => {
            if (menus?.hasNav?.length > 0) {
                return (
                    <SubMenu key={`menu-${index}`} icon={<UserOutlined/>} title={menus?.label}>
                        {menus?.hasNav?.map((nav, subMenuIndex) => {
                            return (
                                <Menu.Item key={`submenu-${index}-${subMenuIndex}`}>
                                    <Link to={nav.url}>{nav.label}</Link>
                                </Menu.Item>
                            )
                        })}
                    </SubMenu>
                );
            } else {
                return (
                    menus?.roles && Array.isArray(menus?.roles) && menus.roles.some(item => userRole.includes(item))  ? <Menu.Item key={menus.url}>
                        <Link to={menus.url}>{menus.label}</Link>
                    </Menu.Item> : null
                )
            }
        })
    }

    return (
        <Sider breakpoint="lg"
               collapsedWidth={0}
               onBreakpoint={(broken) => {
                   console.log(broken);
               }}

               onCollapse={(collapsed, type) => {
                   console.log(collapsed, type);
               }}
               trigger={null}
               collapsible={true}
               theme={"light"}
               className="main-sider"
               width={258}>
            <div className="logo">
                <img src={Logo} alt="logo" onClick={onLogoClick} className="logo-img"/>
            </div>
            <Menu
                mode="inline"
                theme="light"
                selectedKeys={[current]}
                style={{height: '100%', borderRight: 0}}
            >
                {getMenus()}
            </Menu>
        </Sider>
    )
}

export default Sidebar;
