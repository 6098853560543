import React from 'react';
import {Button, Form, Modal, Space} from "antd";
import {FIELDS_LIST, renderSiteForm} from "./helper";

const SiteModal = ({
                       isModalVisible, handleCancel, modalActionType, handleOk, form,
                       vendorRespData,
                       siteTypeRespData,
                       nbnRespData,
                       caseRespData
                   }) => {
    return (
        <>
            <Modal title={modalActionType === 'edit' ? 'Edit Site' : ' Create Site'}
                   open={isModalVisible}
                   onOk={handleOk}
                   onCancel={() => handleCancel()}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper" data-testid={"modal"}>
                    <Form layout="vertical" className="add-user-form" name="site" form={form}>
                        <Form.Item name={"id"} hidden={true}/>
                        {
                            FIELDS_LIST.map(dataIndex => {
                                return <>{renderSiteForm(dataIndex,
                                    vendorRespData,
                                    siteTypeRespData,
                                    nbnRespData,
                                    caseRespData)}
                                </>
                            })
                        }

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn"
                                        onClick={() => handleOk()}>
                                    {modalActionType === 'add' ? 'Save' : 'Update'}
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default SiteModal;
