import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Form, Modal, Collapse, Space} from "antd";
import {error as errorToast, success as successToast} from "../../utils/toast-utils";
import Input from "antd/es/input/Input";
import {
    createInspectionAction, downloadTemplateAction,
    getModifiedInspectionAction, scanMultipleSitesAction, sendPublishVendorReportAction, showEmailAction
} from "../../service-contracts/networkite/networkite-actions";
import Loader from "../Notifications/Loader";
import GetNotificationPeriodModal from "./GetNotificationPeriodModal";
import UploadSitesModal from "./UploadSitesModal";
import download from '../../../assets/images/download.jpeg';
import EmailModal from "./EmailModal";
import {useNavigate} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import {ROLES, USER_DETAILS} from "../../constants";

const Networkite = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMinuteModalVisible, setIsMinuteModalVisible] = useState(false);
    const [isGetNotificationModalVisible, setIsGetNotificationModalVisible] = useState(false);
    const [isUploadSitesModalVisible, setIsUploadSitesModalVisible] = useState(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [uploadModalType, setUploadModalType] = useState('');
    const [intervalModalType, setIntervalModalType] = useState('');
    const [emailModalType, setEmailModalType] = useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [toEmails, setToEmails] = React.useState([]);
    const [ccEmails, setCcEmails] = React.useState([]);
    const Panel = Collapse.Panel;

    const scanMultipleSites = async () => {
        try {
            setIsLoading(true);
            let response = await scanMultipleSitesAction();
            if (response?.data?.status) {
                successToast("CRON jobs have run successfully")
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setIsLoading(false);
        }
    }

    const scanInspection = async () => {
        let formData = form.getFieldValue();
        try {
            setIsLoading(true);
            let response = await getModifiedInspectionAction(formData?.minutes || 3);
            if (response?.data?.status) {
                successToast("Scanned Inspection for Safety Culture Successfully");
                if (formData && formData.minutes) {
                    setIsMinuteModalVisible(false);
                    form.resetFields();
                }
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setIsLoading(false);
        }
    }

    const _sendPublishVendorReport = async () => {
        try {
            setIsLoading(true);
            let response = await sendPublishVendorReportAction();
            if (response?.data?.status) {
                successToast("Report Sent Successfully")
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setIsLoading(false);
        }
    }

    const scanSitesConfig = {
        title: 'Please confirm if you want to run CRON jobs for multiple sites?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
            scanMultipleSites();
        },
        wrapClassName: "confirm-modal-wrap"
    };

    const scanInspectionConfig = {
        title: 'Please confirm if you want to scan last 3 min inspections?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
            scanInspection();
        },
        wrapClassName: "confirm-modal-wrap"
    };

    const sentNotificationConfig = {
        title: 'Please confirm if you want to send the Report?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
            _sendPublishVendorReport();
        },
        wrapClassName: "confirm-modal-wrap"
    };

    const OpenConfirmModal = (config) => {
        modal.confirm(config);
    }

    const handleTrigger = () => {
        form.validateFields().then(async (value) => {
            let formData = form.getFieldValue();
            try {
                setIsLoading(true);
                let response = await createInspectionAction(formData && formData.siteId);
                if (response?.data?.status) {
                    successToast("CRON job has run successfully");
                    setIsModalVisible(false);
                    form.resetFields();
                } else {
                    errorToast(response?.data?.message || "Error Occurred");
                }
            } catch (error) {
                console.log(`Invalid`);
            } finally {
                setIsLoading(false);
            }
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    };

    const downloadTemplate = async (type) => {
        try {
            setIsLoading(true);
            let response = await downloadTemplateAction(type);
            if(response?.data?.status && response?.data?.data){
                let a = document.createElement('a');
                a.href = response?.data?.data;
                a.click();
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const handleMinuteCancel = () => {
        form.resetFields();
        setIsMinuteModalVisible(false);
    }

    const openUploadModal = (type) => {
        setIsUploadSitesModalVisible(true);
        setUploadModalType(type)
    }

    const openIntervalModal = (type) => {
        setIsGetNotificationModalVisible(true);
        setIntervalModalType(type);
    }

    const openEmailModal = async (type) => {
        setIsLoading(true);
        const response = await showEmailAction();
        if (response && response.data) {
            let data = response?.data?.data;
            await setToEmails(data.emailId);
            await setCcEmails(data.ccs);
        }
        setIsLoading(false);
        if (type !== "Vendor Email"){
            // fetchData();
        } else{
            setToEmails([]);
            setCcEmails([]);
        }

        setEmailModalVisible(true);
        setEmailModalType(type);
    }

    const onChange = (key) => {
        // console.log(key);
    };

    const _userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;
    let shouldDisplaySafetyCulture = ([ROLES.SUPER_ADMIN, ROLES.SCAN_INSPECTION].some(item => _userRole.includes(item)));
    let shouldDisplayToAdmin = ([ROLES.SUPER_ADMIN, ROLES.ADMIN].some(item => _userRole.includes(item)));
    let shouldDisplayBatchUpload = ([ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.DELETE_SITE].some(item => _userRole.includes(item)));
    let shouldDisplayExportReport = ([ROLES.SUPER_ADMIN, ROLES.ADMIN,ROLES.EXPORT_REPORT].some(item => _userRole.includes(item)));

   // let shouldDisplayBatchUpload = (["SUPER_ADMIN", "DELETE SITE"].some(item => _userRole.includes(item)));

    return (
        <div className="pd-25">
            {
                isLoading ? <Loader/> : null
            }
            <div className="kite-wrapper">
                <Collapse defaultActiveKey={['1']} onChange={onChange} bordered={false}>
                    {
                        shouldDisplayToAdmin ?  <Panel header="Netbox" key="1">
                            <div className="kite-box" onClick={() => setIsModalVisible(true)}>Run CRON Job for Single Site
                                in
                                Netbox
                            </div>
                            <div className="kite-box" onClick={() => OpenConfirmModal(scanSitesConfig)}>Run CRON Jobs for
                                Multiple
                                Sites in Netbox
                            </div>
                        </Panel> : null
                    }
                    {
                        shouldDisplaySafetyCulture ?  <Panel header="Safety Culture" key="2">
                            <div className="kite-box" onClick={() => OpenConfirmModal(scanInspectionConfig)}>
                                <div>Scan Inspection From Safety Culture</div>
                                <span>Last 3 min Inspections will be scanned</span>
                            </div>
                            <div className="kite-box" onClick={() => setIsMinuteModalVisible(true)}>
                                <div>Scan Inspection From Safety Culture</div>
                                <span>Choose the minutes</span>
                            </div>
                        </Panel> : null
                    }
                    {
                        shouldDisplayBatchUpload ? <Panel header="Batch Upload of Sites/Inspections/Schedules" key="3">
                            <div className="kite-box" onClick={() => navigate('/batchUpload')}>
                                <div>Batch Upload <UploadOutlined  style={{fontSize:"2vh"}} /> </div>
                            </div>
                            <div className="upload-box">
                                <div className="kite-box" onClick={() => openUploadModal("Sites")}>
                                    <div>Load Sites In Safety Culture</div>
                                    <span>Upload the Sites (csv) to load them in Safety Culture</span>
                                </div>
                                <div className="kite-box" onClick={() => downloadTemplate('LOAD_SITES_IN_SAFETYCULTURE')}>
                                    <img src={download} alt={"download"}/>
                                </div>
                            </div>

                            <div className="upload-box">
                                <div className="kite-box" onClick={() => openUploadModal("Inspections")}>
                                    <div>Load CSV to fill Inspections</div>
                                    <span>Upload the Inspections (csv) to load them in Safety Culture</span>
                                </div>
                                <div className="kite-box" onClick={() => downloadTemplate('LOAD_CSV_TO_FILL_INSPECTION')}>
                                    <img src={download} alt={"download"}/>
                                </div>
                            </div>

                            <div className="upload-box">
                                <div className="kite-box" onClick={() => openUploadModal("Schedules")}>
                                    <div>Load CSV to upload Schedules</div>
                                    <span>Upload the Schedules (csv)</span>
                                </div>
                                <div className="kite-box" onClick={() => downloadTemplate('LOAD_CSV_FOR_SCHEDULE')}>
                                    <img src={download} alt={"download"}/>
                                </div>
                            </div>

                            <div className="upload-box">
                                <div className="kite-box" onClick={() => openUploadModal("New Sites")}>
                                    <div>Load CSV to upload sites using new process</div>
                                    <span>Site Upload Using New Process</span>
                                </div>
                                <div className="kite-box" onClick={() => downloadTemplate('LOAD_CSV_FOR_SITE_BULK_UPLOAD')}>
                                    <img src={download} alt={"download"}/>
                                </div>
                            </div>
                        </Panel> : null
                    }
                    {
                        // shouldDisplayToAdmin ?  <Panel header="Reports" key="4">
                        //     <div className="kite-box" onClick={() => openIntervalModal("Reports")}>
                        //         <div>Send Reports</div>
                        //     </div>
                        //     {/*<div className="kite-box" onClick={() => OpenConfirmModal(sentNotificationConfig)}>
                        //     <div>Send Reports For Last One Day</div>
                        //     <span>Last 24 Hour inspection related Reports will be triggerred</span>
                        // </div>*/}
                        //     <div className="kite-box" onClick={() => openIntervalModal("BusinessReports")}>
                        //         <div>Send Reports to Business Units</div>
                        //     </div>
                        // </Panel> : null
                    }
                    {
                        shouldDisplayExportReport ?  <Panel header="Export" key="5">
                            <div className="kite-box" onClick={() => openIntervalModal("Export")}>
                                <div>Export Sites Data</div>
                            </div>
                        </Panel> : null
                    }
                    {
                        shouldDisplayToAdmin ?  <Panel header="Set Email" key="6">
                            <div className="kite-box" onClick={() => openEmailModal("Email")}>
                                <div>Add Recipients for Reports</div>
                            </div>
                            <div className="kite-box" onClick={() => openEmailModal("Vendor Email")}>
                                <div>Add Recipients for Vendor Reports</div>
                            </div>
                        </Panel> : null
                    }
                </Collapse>
            </div>
            <Modal title={'Enter Minutes'}
                   open={isMinuteModalVisible}
                   onOk={scanInspection}
                   onCancel={handleMinuteCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper">
                    <Form layout="vertical" className="add-user-form" name="calendarMapping" form={form}>
                        <Form.Item
                            className="input-text-field height-40"
                            name={`minutes`}
                            label={`Minutes`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Minutes',
                                },
                            ]}
                        >
                            <Input placeholder='Minutes'/>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn"
                                        onClick={() => scanInspection()}>
                                    Scan Inspection
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>
            <Modal title={'Enter Site Id'}
                   open={isModalVisible}
                   onOk={handleTrigger}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper">
                    <Form layout="vertical" className="add-user-form" name="calendarMapping" form={form}>
                        <Form.Item hidden={true}/>
                        <Form.Item
                            className="input-text-field height-40"
                            name={`siteId`}
                            label={`Site Id`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Site Id',
                                },
                            ]}
                        >
                            <Input placeholder='Site Id'/>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn"
                                        onClick={() => handleTrigger()}>
                                    Trigger
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>
            <GetNotificationPeriodModal setIsModalVisible={setIsGetNotificationModalVisible}
                                        isModalVisible={isGetNotificationModalVisible}
                                        intervalModalType={intervalModalType}/>
            <UploadSitesModal setIsModalVisible={setIsUploadSitesModalVisible} uploadModalType={uploadModalType}
                              isModalVisible={isUploadSitesModalVisible}/>
            <EmailModal setIsModalVisible={setEmailModalVisible}
                                        isModalVisible={emailModalVisible}
                                        intervalModalType={emailModalType}
                        _toEmails={toEmails}
                        _ccEmails={ccEmails}/>
            {contextHolder}
        </div>
    )
}
export default Networkite;
