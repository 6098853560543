export const columns = [
    {
        title: 'Month',
        dataIndex: 'monthName',
        key: 'monthName',
        // render: (record) => `${record.month?.month} ${record.month?.year}`,
    },
    {
        title: 'Forecast',
        dataIndex: 'target',
        key: 'target',
        align: 'center',
    },
];

export const columnsWeekly = [
    {
        title: 'Week',
        dataIndex: 'weekDisplay',
        key: 'weekDisplay',
    },
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
    },
    {
        title: 'Site Status',
        dataIndex: 'siteStatus',
        key: 'siteStatus',
    },
    {
        title: 'Forecast',
        dataIndex: 'target',
        key: 'target',
        align: 'center',
    },
];

export const mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const mS = ['','Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
