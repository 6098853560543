import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {Button, DatePicker, Form, Modal,Select, Space, Tabs, Checkbox} from "antd";
import VendorTable from "./VendorTable";
import Input from "antd/es/input/Input";
import {debounce} from "../../utils/common";
import {
    createModifyInspectionScheduleAction,
    getSitePauseAction,
    getSitesFilterAction, getSitesScheduleHistoryListAction,
    getSitesSchedulesListAction, revertInspectionStatusAction
} from "../../service-contracts/dashboard/dashboard-actions";
import {error as errorToast, success as successToast} from "../../utils/toast-utils";
import Loader from "../Notifications/Loader";
import dayjs from "dayjs";
import InspectionModal from "../InspectionModal/index";
import {ROLES, TABLE_PAGE_SIZE, USER_DETAILS} from "../../constants";
import {useLocation} from "react-router-dom";
import {Option} from "antd/es/mentions";
import {STATUS_CONSTANT} from "../Dashboard/helper";
const SiteSchedule = (props) => {
    const [form] = Form.useForm();
    const [revertStatusForm] = Form.useForm();
    const {state} = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [modalActionType, setModalAction] = useState("add");
    const [modalType, setModalType] = useState("");
    const [data, setData] = useState([]);
   // const [userListData, setUserListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState('');
    const [isRevertStatusModalVisible, setIsRevertStatusModalVisible] = useState(false);
    const [recordPerSite, setRecordPerSite] = useState('');
    const [searchInput, setSearchInput] = useState(state && state.upid ? state.upid : '');
    const [selectedTab, setSelectedTab] = useState('');
    const [filterReqObj, setFilterReqObj] = useState({});
    const [filterReq, setFilterReq] = useState({});
    const [clearFilters, setClearFilters] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: TABLE_PAGE_SIZE,
            pageSizeOptions: ['25', '50', '75', '100'],
            showSizeChanger: true,
        },
        textSearch: state && state.upid ? state.upid : '',
        sorting: 'ASC',
        filter: selectedTab,
        filterReq: filterReq,
    });

    const _userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;
    let shouldDisplayClearDate = ([ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VENDOR_MAXTECH].some(item => _userRole.includes(item)));

    /**
     * Function to handle pagination sorting changes
     * @param {pagination details} pagination
     * @param {filters details} filters
     * @param {sorting details} sorter
     */
    const handleTableChange = async (pagination, filters, sorter) => {
        let sorting = 'ASC';
        let sortingColumn = '';

        if (sorter.hasOwnProperty("column")) {
            sorting = sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : "ASC";
            sortingColumn= sorter.column?.title;
        }

        await setTableParams({
            ...tableParams,
            pagination,
            filters,
            ...sorter,
            sorting,
            filterReq,
            filter: selectedTab,
            sortingColumn
        });

        let _payload = {
            "pageNo": pagination?.current || 1,
            "pageSize": pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": sorting,
            "filter": tableParams.filter,
            "filterReq": tableParams?.filterReq,
            "sortingColumn": sortingColumn,
        }

        fetchData(_payload, selectedTab === "HISTORY");
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    /**
     * Function to apply filters
     * @param {filter object to be send as payload} _filterReq
     */
    const handleFilters = async (_filterReq) => {
        setFilterReq(_filterReq);
        await setTableParams({
            ...tableParams,
            _filterReq,
        });

        let _payload = {
            "pageNo": tableParams?.pagination?.current || 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": tableParams?.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": tableParams.filter,
            "filterReq": _filterReq
        }

        fetchData(_payload, selectedTab === "HISTORY");
    }

    /**
     * Function to apply filters
     * @param {text search value} val
     * @param {selectedTab} _selectedTab
     */
    const handleSearch = async (val, _selectedTab) => {
        await setTableParams({
            ...tableParams,
            filter: _selectedTab || "",
            "textSearch": val,
        });

        let _payload = {
            "pageNo": 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": val,
            "sorting": tableParams.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": "",
            "filterReq": tableParams?.filterReq
        }

        await fetchData(_payload, selectedTab === "HISTORY");
       // setClearFilters(true);
        // setReloadList(true);
    };

    const debouncedSearch = useCallback(
        debounce((e, _selectedTab) => {
            handleSearch(e, _selectedTab)
        }, 1000),
        [tableParams],
    );

    const onSearchInputChange = async (e) => {
        await setSearchInput(e.target.value);
        debouncedSearch(e.target.value, selectedTab);
    }

    /**
     * Function to fetch the schedule data
     * @param {payload object} _payload
     * @param {check if history tab is opened} onHistoryTab
     */
    const fetchData = (_payload, onHistoryTab) => {
        setLoading(true);
        let callApi = getSitesSchedulesListAction;
        let payload = {
            "pageNo": tableParams?.pagination?.current || 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": tableParams.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": tableParams.filter,
            "filterReq":tableParams.filterReq,
        }

        if (tableParams.sorting === '') delete payload.sorting;

        if (_payload) payload = _payload;

        if (onHistoryTab) {
            callApi =  getSitesScheduleHistoryListAction;
        }

        callApi(payload).then(resp => {
            let  _key = onHistoryTab ? "SITE_SCHEDULE_HISTORY" : "SITE_SCHEDULE"
            if(resp?.data?.data){
                getSitesFilterAction(_key).then( _resp => {
                    setFilterReqObj(_resp?.data?.data)
                })
            }
            let _data = !onHistoryTab ? resp?.data?.data?.sitesSchedulesResponseList : resp?.data?.data?.siteScheduleHistory
            setData(_data);
            setLoading(false);
            setTableParams({
                ...tableParams,
                filter: _payload ? _payload.filter : "",
                filterReq: _payload? _payload.filterReq : filterReq,
                pagination: {
                    ...tableParams.pagination,
                    current: resp?.data?.data?.pageNo,
                    total: resp?.data?.data?.totalElements,
                    pageSize: resp?.data?.data?.pageSize,
                },
                "textSearch": resp?.data?.data?.textSearch,
                "sortingColumn": resp?.data?.data?.sortingColumn,
                "sorting": resp?.data?.data?.sorting,
            });
            // setReloadList(false);
        });
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const editHandler = (_data, _recordPerSite) => {
        setIsModalVisible(true);
        setRecord(_data);
        setRecordPerSite(_recordPerSite)
    }

    const onSitePauseToggle = async (checked, _data) => {
        setLoading(true);
        await getSitePauseAction(_data?.siteId, checked).then(resp => {
            fetchData();
        });
        setLoading(false);
    }

    /**
     * Function to create a schedule
     * @param {data to be send} _data
     * @param {site install or site schedule} type
     * @param {action to perform add or edit} action
     */
    const onCreateClick = (_data, _recordPerSite, type, action = "add") => {
        setModalAction(action);
        setModalType(type);
        form.resetFields();

        if (_data) {
            const {telstraInstall} = _data;
            const {tbbInstalled} = _recordPerSite;
            let inspectionIdsList = (type==="Site Install") ? (!tbbInstalled && telstraInstall) ? [ _recordPerSite?.tbbAuditId, _recordPerSite?.siteInstallAuditId] : [_recordPerSite?.siteInstallAuditId] : [_recordPerSite?.siteCutoverAuditId];
            let date = (type==="Site Install") ? _recordPerSite?.siteInstallSchedule : _recordPerSite?.siteCutoverSchedule;
            //let email = (type==="Site Install") ? `${_data?.siteInstallTechEmail}-${_data?.siteInstallUserId}` : `${_data?.siteCutoverTechEmail}-${_data?.siteCutoverUserId}`;

            const initialValues = {
                id: _data?.id,
                upid: _data?.upid,
                type: type,
                vendor: _data?.vendor,
                sitename: _data?.sitename,
                siteId: _data?.siteId,
                inspectionIdsList:inspectionIdsList,
            }
            if (action === "edit") {
                initialValues.date = dayjs(dayjs(date).toISOString(), "YYYY-MM-DD HH:mm");
               // initialValues.email = email;
            }

            form.setFieldsValue(initialValues);
        }
        setIsCreateModalVisible(true);
    }

    /**
     * Function to update or save the inspection schedule
     * @param {api for update or save} isUpdate
     */
    const saveOrUpdateHandler = async (isUpdate) => {
        let formData = form.getFieldValue();
        try {
            const payload = {
                "upid": formData?.upid,
                "typeOfInstall": formData?.type,
               // "email": formData?.email?.split('-')[0],
                "date": new Date(formData.date).getTime(),
                "vendor": formData?.vendor,
                "sitename": formData?.sitename,
                "siteId": formData?.siteId,
                "inspectionId": formData?.inspectionId,
                //"userId": formData?.email?.split('-')[1],
                "inspectionIdsList": formData?.inspectionIdsList,
                "clearScheduleDateRequired": modalType !=="Site Install" && formData?.date ? formData?.clearScheduleDateRequired : false,
            };

            if (isUpdate) {
                payload["id"] = formData.id;
            }
            //setIsLoading(true);
            let response = await createModifyInspectionScheduleAction(payload, isUpdate);
            if (response?.data?.status) {
                if (isUpdate) {
                    successToast(response?.data?.message || "Site Schedule updated successfully");
                } else {
                    successToast(response?.data?.message || "Site Schedule created successfully");
                }
                setIsCreateModalVisible(false);
                form.resetFields();
                fetchData(null, selectedTab === "HISTORY");
            } else {
                errorToast(response?.data?.message || "Error Occurred");
            }
        } catch (error) {
            console.log(`Invalid`);
        } finally {
            //setIsLoading(false);
        }
    };

    const handleOk = () => {

        form.validateFields().then((value) => {
            saveOrUpdateHandler(modalActionType !== 'add');
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
                //errorToast('Please enter all mandatory fields');
            });
    };

    const handleRevert = async () => {
        revertStatusForm.validateFields().then(async (value) => {
            let formData = revertStatusForm.getFieldValue();
            try {
                const payload = {
                    "siteId": record && record?.siteId,
                    "revertStatusTo": formData.revertStatus || ""
                }

                //setIsLoading(true);
                let response = await revertInspectionStatusAction(payload);
                if (response?.status === 200) {
                        successToast(response?.data || "Site Status reverted successfully");
                    setIsRevertStatusModalVisible(false);
                    revertStatusForm.resetFields();
                    fetchData(null, selectedTab === "HISTORY");
                } else {
                    errorToast(response?.data?.message || "Error Occurred");
                }
            } catch (error) {
                console.log(`Invalid`);
            } finally {
                //setIsLoading(false);
            }
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
                //errorToast('Please enter all mandatory fields');
            });
    };

    const handleCancelScheduleModal = () => {
        form.resetFields();
        setIsCreateModalVisible(false);
    }

 /*   useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            const userListResponse = await getSafetyCultureUserListAction(form.getFieldValue('vendor'));
            if (userListResponse && userListResponse.data) {
                let _data = userListResponse?.data?.data;
                setUserListData(_data);
            }
            setLoading(false);
        };
        if (isCreateModalVisible) fetchList();
    }, [isCreateModalVisible]);*/

    const editStatusRevertHandler = (data, item) => {
        console.log(data, item)
        setIsRevertStatusModalVisible(true);
        setRecord(item);
    }

    const handleEditStatusRevertModal = () => {
        revertStatusForm.resetFields();
        setIsRevertStatusModalVisible(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const TableComponent = (isFiltersRequired) => {
        return <VendorTable setIsModalVisible={setIsModalVisible}
                            editHandler={editHandler}
                            data={data}
                            loading={loading}
                            tableParams={tableParams}
                            isHistoryTabSelected={selectedTab === "HISTORY"}
                            handleTableChange={handleTableChange}
                            onSearchInputChange={onSearchInputChange}
                            searchInput={searchInput}
                            onCreateClick={onCreateClick}
                            onSitePauseToggle={onSitePauseToggle}
                            isFiltersRequired={isFiltersRequired}
                            handleFilters={handleFilters}
                            filterReqObj={filterReqObj}
                            clearFilters={clearFilters}
                            setClearFilters={setClearFilters}
                            record={record}
                            className={selectedTab === "HISTORY" ? "site-history-table" : ''}
                            editStatusRevertHandler={editStatusRevertHandler}
        />
    }

    const items = [
        {
            key: 'ACTIVE',
            label: `Active`,
            children: TableComponent(true),
        },
        {
            key: 'HISTORY',
            label: `History`,
            children: TableComponent(true),
        },
    ];

    const onTabChange = async (key) => {
        console.log('heree tab changed...')
        let _payload = {
            "pageNo": 1,
            "pageSize": TABLE_PAGE_SIZE,
            "textSearch": "",
            "sorting": "ASC",
        }
        await setTableParams({
            ...tableParams,
            "pageNo": 1,
            "pageSize": TABLE_PAGE_SIZE,
            "textSearch": "",
            "sorting": "ASC",
        });

        await setSelectedTab(key === "ALL" ? "" : key)
        fetchData(_payload,key === "HISTORY");
        setSearchInput("");
        setClearFilters(true);
       // setCheckedList([]);
    };

    return (
        <div className="site-schedule-wrapper tabs-container" data-testid="site-schedule-wrapper">
            {
                loading ? <Loader/> : null
            }

            <Tabs defaultActiveKey="ACTIVE" items={items} onChange={onTabChange}/>
            {
                isModalVisible &&
                <InspectionModal isModalVisible={isModalVisible} handleCancel={handleCancel} record={record} recordPerSite={recordPerSite}/>
            }

            <Modal title={modalActionType === 'edit' ? 'Edit Schedule' : ' Create Schedule'}
                   open={isCreateModalVisible}
                   onOk={handleOk}
                   onCancel={handleCancelScheduleModal}
                   footer={null}
                   maskClosable={true}
                   data-testid="modal"
            >
                <div className="add-new-modal-wrapper" data-testid="modal">
                    <Form layout="vertical" className="add-user-form" name="calendarMapping" form={form}>
                        {/*<Form.Item name={"id"} hidden={true}/>*/}
                        <Form.Item
                            className="input-text-field height-40"
                            name={`upid`}
                            label={`UPID`}
                        >
                            <Input placeholder='UPID' disabled/>
                        </Form.Item>

                        <Form.Item
                            className="input-text-field height-40"
                            name={`type`}
                            label={`Type of Install`}
                        >
                            <Input placeholder='Type of Install' disabled/>
                        </Form.Item>

                        <Form.Item
                            name="date"
                            label="Date"
                            className="height-40 schedule-datepicker"
                            rules={[{required: true, message: 'Please select Date'}]}
                            //initialValue={moment()}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                //showTime
                                data-testid = "date-picker"
                            />
                        </Form.Item>

                        {
                            shouldDisplayClearDate && modalType !=="Site Install" && form.getFieldValue()?.date ? <Form.Item
                                name="clearScheduleDateRequired"
                                valuePropName="checked"
                                className="height-40 schedule-datepicker"
                            >
                                <Checkbox>Clear Schedule Date</Checkbox>
                            </Form.Item> : null
                        }

                        {/*<Form.Item
                            className="select-dropdown-field height-40"
                            name="email"
                            label={`Technician Email`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Technician Email',
                                },
                            ]}
                        >
                            <Select
                                showArrow={false}
                                popupClassName="select-dropdown-menu"
                                placeholder="Select Technician Email"
                                showSearch={true}
                            >
                                {
                                    userListData && userListData.length && userListData.map(item => {
                                        return <Option key={item.userId}
                                                       value={`${item.email}-${item.userId}`}>{item.email}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>*/}

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn"
                                        data-testid="save-btn" onClick={() => handleOk()}>
                                    {modalActionType === 'add' ? 'Save' : 'Update'}
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>

            <Modal title={"Revert Status"}
                   open={isRevertStatusModalVisible}
                   onOk={handleOk}
                   onCancel={handleEditStatusRevertModal}
                   footer={null}
                   maskClosable={true}
                   data-testid="modal"
            >
                <div className="add-new-modal-wrapper" data-testid="modal">
                    <Form layout="vertical" className="add-user-form" name="calendarMapping" form={revertStatusForm}>
                        {/*<Form.Item name={"id"} hidden={true}/>*/}

                        <Form.Item
                            className="select-dropdown-field height-40 revert-status-field"
                            name="revertStatus"
                            label={`Status`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Status',
                                },
                            ]}
                        >
                            <Select
                                showArrow={false}
                                popupClassName="select-dropdown-menu"
                                placeholder="Select Status"
                                showSearch={true}
                            >
                                {
                                    record && record?.revertStatus?.length && record?.revertStatus.map(item => {
                                        return <Option key={item}
                                                       value={item}>{STATUS_CONSTANT[item]}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn"
                                        data-testid="save-btn" onClick={() => handleRevert()}>
                                    Revert
                                </Button>
                            </Space>
                        </div>

                    </Form>

                </div>
            </Modal>
        </div>
    )
}

export default SiteSchedule;
