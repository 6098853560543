import * as React from 'react';
import Routing from "./Routing/index";

const App = () => (
   <div data-testid={"root"}>
     <Routing/>
   </div>
);

export default App;
