import React from 'react';

import { createRoot } from 'react-dom/client';

import {Index} from './app/index';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);
