import config from '../../../config';
import {httpAuth} from "../../utils/http-utility";
import {GRAPH_TITLE} from "../../components/Dashboard/helper";

export const getSitesCountPerStatus = async (appendQuery) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/sitesCountPerStatus${appendQuery}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getMonthlyCutoverVsTarget = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/monthlycutovervstarget`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getWeeklySiteDataVsActual = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/weeklySiteReport`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getSiteFaultSummary = async (appendQuery) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/sitesFaultSummary${appendQuery}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getSitesScheduleSummary = async (appendQuery) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/sitesScheduleSummary${appendQuery}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getSitesStatusSummary = async (appendQuery) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/getSiteStatus/summary${appendQuery}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getCableRemediationSummary = async (appendQuery) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/cableRemediationSummary${appendQuery}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getVendorList = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/vendor/list`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getSiteStatusList = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/siteStatus/list`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getMonthlyTargetList = async (pageNo=1, pageSize=10) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/getMonthlyTargetList?page=${pageNo}&size=${pageSize}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getWeeklyTargetList = async (pageNo=1, pageSize=10) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/getWeeklyTargetList?page=${pageNo}&size=${pageSize}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const setTargetData = async (payload, title) => {
    let apiEndPoint = title === GRAPH_TITLE.WEEKLY_REPORT ? "setWeeklyTarget" : "setMonthlyTarget";
    const targetApiResponse = await httpAuth.post(
        `${config.API_ROOT}/${apiEndPoint}`,
        payload,
    );

    return targetApiResponse;
};

export const getSitesFaultList = async (payload) => {
    const registerApiResponse = await httpAuth.post(
        `${config.API_ROOT}/sitesfault`,
        payload,
    );

    return registerApiResponse;
};

export const getSitesFaultHistoryList = async (payload) => {
    const sitesFaultHistoryApiResponse = await httpAuth.post(
        `${config.API_ROOT}/sitesFaultHistory`,
        payload,
    );

    return sitesFaultHistoryApiResponse;
};

export const getSitesScheduleHistoryList = async (payload) => {
    const sitesScheduleHistoryResponse = await httpAuth.post(
        `${config.API_ROOT}/sitesScheduleHistory`,
        payload,
    );

    return sitesScheduleHistoryResponse;
};

export const getSitesSchedulesList = async (payload) => {
    const sitesSchedulesListApiResponse = await httpAuth.post(
        `${config.API_ROOT}/sitesSchedules`,
        payload,
    );

    return sitesSchedulesListApiResponse;
};

export const createModifyInspectionSchedule = async (payload) => {
    const createModifyInspectionScheduleApiResponse = await httpAuth.post(
        `${config.API_ROOT}/createModifyInspectionSchedule`,
        payload,
    );

    return createModifyInspectionScheduleApiResponse;
};

export const revertInspectionStatus = async (payload) => {
    const revertInspectionStatusApiResponse = await httpAuth.put(
        `${config.API_ROOT}/site/revert`,
        payload,
    );

    return revertInspectionStatusApiResponse;
};

export const getSafetyCultureUserList = async (vendor) => {
    const safetyCultureUserListResponse = await httpAuth.get(
        `${config.API_ROOT}/getSafetyCultureUserList?vendor=${vendor}`,
    );

    return safetyCultureUserListResponse;
};

export const getCableRemediationList = async (payload) => {
    const cableRemediationListApiResponse = await httpAuth.post(
        `${config.API_ROOT}/cableRemediation`,
        payload,
    );

    return cableRemediationListApiResponse;
};

export const getCableRemediationHistoryList = async (payload) => {
    const cableRemediationHistoryListResponse = await httpAuth.post(
        `${config.API_ROOT}/cableRemediationHistory`,
        payload,
    );

    return cableRemediationHistoryListResponse;
};

export const getSitePause = async (siteid, isPause) => {
    const getSitePauseApiResponse = await httpAuth.get(`${config.API_ROOT}/site/${isPause ? 'pause' : 'unPause'}/${siteid}`);
    return getSitePauseApiResponse;
};

export const getRouterDeviceDetails = async (payload) => {
    const routerDeviceDetailResponse = await httpAuth.get(
        `${config.API_ROOT}/device/details?serialNo=${payload?.routerSerialNumber}`,
    );

    return routerDeviceDetailResponse;
};

export const getTenant = async () => {
    const getTenantResponse = await httpAuth.get(
        `${config.API_ROOT}/getTenant`,
    );

    return getTenantResponse;
};

export const getRouterOperationalCheck = async (payload, pageUrl) => {
    const routerOperationalCheckResponse = await httpAuth.post(
        `${config.API_ROOT}/${pageUrl === "routerOperationalTest" ? 'operationalCheck' : 'operationalCheckConfig'}`,
        payload,
    );

    return routerOperationalCheckResponse;
};

export const getOperationalData = async (id) => {
    const getOperationalDataResponse = await httpAuth.get(
        `${config.API_ROOT}/getOperationalData?id=${id}`,
    );

    return getOperationalDataResponse;
};

export const getExecutionInfo = async (url) => {
    const getExecutionInfoResponse = await httpAuth.get(
        `${config.API_ROOT}/getExecutionInfo?url=${url}`,
    );

    return getExecutionInfoResponse;
};

export const approveInspection = async (payload) => {
    const approveInspectionApiResponse = await httpAuth.post(
        `${config.API_ROOT}/inspection/approved`,
        payload,
    );

    return approveInspectionApiResponse;
};

export const submitInspection = async (payload) => {
    const submitInspectionApiResponse = await httpAuth.post(
        `${config.API_ROOT}/inspection/submitted`,
        payload,
    );

    return submitInspectionApiResponse;
};

export const getSitesFilter = async (page) => {
    const sitesFilterListResponse = await httpAuth.get(
        `${config.API_ROOT}/getSiteFilters/${page}`,
    );

    return sitesFilterListResponse;
};

export const setFaultOwner = async (siteId,payload) => {
    const setFaultOwnerResponse = await httpAuth.post(
        `${config.API_ROOT}/siteFault/faultOwner/update/${siteId}`,
        payload,
    );

    return setFaultOwnerResponse;
};

export const setNote = async (siteId,payload) => {
    const response = await httpAuth.post(
        `${config.API_ROOT}/siteFault/note/update/${siteId}`,
        payload,
    );

    return response;
};

export const getRouterList = async (type="routerConfig",offset=0,limit=20,searchTag="") => {
    var getRouterListResponse = null;

    if (type === "routerOpsCheck") {
        getRouterListResponse = await httpAuth.get(
            `${config.API_ROOT}/device/log/routerList?offset=${offset}&limit=${limit}${searchTag === '' || searchTag === null ? '' : `&searchTag=${searchTag}`}`,
        );
    }
    else{
        getRouterListResponse = await httpAuth.get(
            `${config.API_ROOT}/${type}/list?offset=${offset}&limit=${limit}${searchTag === '' || searchTag === null ? '' : `&searchTag=${searchTag}`}`,
        );
    }
    return getRouterListResponse;
};

export const getRouterJobList = async (type="routerConfig",sn) => {
    var getRouterJobListResponse = null;
    if (type == "routerOpsCheck") {
        getRouterJobListResponse = await httpAuth.get(
            `${config.API_ROOT}/device/log/routerJobList?serialNumber=${sn}`,
        );
    }
    else
    {
        getRouterJobListResponse = await httpAuth.get(
            `${config.API_ROOT}/${type}/job/${sn}/list`,
        );
    }

    return getRouterJobListResponse;
};

export const getRouterJobOutputList = async (id) => {
    const getRouterJobOutputListResponse = await httpAuth.get(
        `${config.API_ROOT}/router/job/output/${id}`,
    );

    return getRouterJobOutputListResponse;
};

export const deleteSite = async (id) => {
    const deleteSiteResponse = await httpAuth.delete(
        `${config.API_ROOT}/deleteData?upId=${id}`,
    );

    return deleteSiteResponse;
};

export const ExecuteRouterOpertionalTest = async (payload) =>{
    const response = await httpAuth.post(
        `${config.API_ROOT}/device/executeTest`,
        payload,
    );
    return response;
}

export const getRouterOperationalData = async (jobId) =>{
const response = await httpAuth.get(
    `${config.API_ROOT}/device/execution/logs?jobId=${jobId}`,
);
return response
}

export const simCardActivation = async (payload) =>{
    const response = await httpAuth.post(
        `${config.API_ROOT}/simCardActivation/`,
        payload,
    );
    return response;
}


