import {REGEX} from "../constants";
import {toTitleCase} from "../components/Dashboard/helper";

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

export const checkmail = (_, value) => {
    let inputValue = value;
    if (inputValue) {
        if (REGEX.EMAIL.test(inputValue)) {
            return Promise.resolve();
        } else {
            return Promise.reject('Enter Valid Email Id');
        }
    }

    return Promise.reject(new Error("Enter Valid Email Id"));
};

export const validatePassword = (_,password) => {
    if (!password || password?.length === 0) {
        return Promise.reject(`Enter ${toTitleCase(_?.field) || 'Password'}`);
    }

    // Check if the password length is at least 12 characters
    if (password?.length < 12) {
        return Promise.reject('Password length should be at least 12 characters');
    }

    // Check if the password contains at least one numeric or special character
    if (!/[0-9!@#$%^&*]/.test(password)) {
        return Promise.reject('Password must contain at least one numeric or special character');
    }

    // Check if the password has more than 5 repeating characters
    for (let i = 0; i < password?.length - 5; i++) {
        if (
            password[i] === password[i + 1] &&
            password[i + 1] === password[i + 2] &&
            password[i + 2] === password[i + 3] &&
            password[i + 3] === password[i + 4] &&
            password[i + 4] === password[i + 5]
        ) {
            return Promise.reject('Password must not contain more than 5 repeating characters');
        }
    }

    return Promise.resolve();
};

export const isDesktopView = () => {
    const resolution = window.innerWidth;
    const isMobileOrTab = resolution >= 320 && resolution <= 992;
    return {
        isDesktopView: !isMobileOrTab,
        screenWidth: resolution,
    }
};

export const getScreenWidth = () => window.innerWidth;

export const capitalize = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('').toLowerCase();
}
