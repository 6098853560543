import React, {useState} from 'react';
import {
    Card,
    Form,
    Input,
    Button,
    Select,
    Row,
    Col
} from 'antd';
import './index.scss';
import Loader from "../Notifications/Loader";
import {success as successToast} from "../../utils/toast-utils";
import {error as errorToast} from "../../utils/toast-utils";
import {AddressSuburbList, ValidateDigits, setMask, ValidateIDs } from "./helper.js";
import { CreateSiteAction, getRegionIdAction } from "../../service-contracts/SiteCreation/sitecreation-actions";
const { Option } = Select;

const SiteCreation = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();


    const onFinish = async (values) => {
        setIsLoading(true)
        let regionData = await getRegionIdAction(values.Address2)
        if (regionData == false){
            errorToast(<span style={{color:"red", fontWeight:"bold", fontSize:"15px"}}>Please select correct Address</span>, 6)
            setIsLoading(false)
            return
        }
        const payload = {
            "name": values.VenueName,
            "physical_address": (values.Address1? values.Address1 + ", " : "") + values.Address2,
            "region":regionData?.id,
            "custom_fields": {
                "gaming": values.GamingID ? values.GamingID : null,
                "keno": values.KenoID ? values.KenoID : null,
                "lotteries": values.LotteriesID? values.LotteriesID : null,
                "wagering": values.WageringID ? values.WageringID : null,
                "UPID": values.UPID
            }
        }
        const result = await CreateSiteAction(payload)
        if (result?.status != 201) {
            errorToast(<span style={{color:"red", fontWeight:"bold", fontSize:"15px"}}>{result?.response?.data}</span>, 6)
        }
        else{
            const SiteUrl = result?.data
            successToast(<span>Successfully Site Created  <a href={SiteUrl} target='_blank'> link</a></span>, 5)
            form.resetFields()
        }
        setIsLoading(false)
    };

    const onFinishFailed = async (errorInfo) => {
        return
    };

    return (
        <div className="login-wrapper change-password-wrapper">
                {
                    isLoading ? <Loader/> : null
                }
                <div className="login-body">
                        <Card
                            className="card-main sitecreation"
                        >
                            <Form
                                form={form}
                                name="normal_login"
                                className="login-form card-space"
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    className="input-row height-40"
                                    name="UPID"
                                    label="UPID"
                                    rules={[
                                        {
                                            validator:ValidateDigits
                                        }

                                    ]}
                                >
                                    <Input maxLength={10} onChange={(e)=> setMask(e, form)} />
                                </Form.Item>
                                <Form.Item
                                    className="input-row margin-top-space height-40"
                                    name="VenueName"
                                    label="Venue Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Venue Name'
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Row
                                    className="input-row address margin-top-space height-40"
                                >
                                    <Col span={12}>
                                    <Form.Item
                                        name="Address1"
                                        label="Address"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Field is Mandatory',
                                            }
                                        ]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                    </Col>
                                    <Col span={12}  className="margin-top-space height-40" style={{marginTop: "30px", textAlign: "center"}}>
                                    <Form.Item
                                        name="Address2"
                                        label="Address2"
                                        id="Address2"
                                        data-testid="Address2"
                                        initialValue={AddressSuburbList[0]}
                                        rules={[
                                                {
                                                    required: true,
                                                    message: 'Field is Mandatory',
                                                }
                                        ]} noStyle>

                                        <Select   showSearch style={{ width: '100%' }} >
                                            {AddressSuburbList.map((address, index) => (
                                                <Option data-testid="Address-selector" key={index} value={address}>{address}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    </Col>

                                </Row>
                                <Form.Item
                                    className="input-row margin-top-space height-40"
                                    name="WageringID"
                                    label="Wagering ID"
                                    rules={[
                                        {
                                            required: false,
                                            message: '',
                                        },
                                        {
                                            validator:()=>ValidateIDs(form)
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    className="input-row margin-top-space height-40"
                                    name="GamingID"
                                    label="Gaming ID"
                                    rules={[
                                        {
                                            required: false,
                                            message: '',
                                        },
                                        {
                                            validator:()=>ValidateIDs(form)
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    className="input-row margin-top-space height-40"
                                    name="LotteriesID"
                                    label="Lotteries ID"
                                    rules={[
                                        {
                                            required: false,
                                            message: '',
                                        },
                                        {
                                            validator:()=>ValidateIDs(form)
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    className="input-row margin-top-space height-40"
                                    name="KenoID"
                                    label="Keno ID"
                                    rules={[
                                        {
                                            required: false,
                                            message: '',
                                        },
                                        {
                                            validator:()=>ValidateIDs(form)
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className=" btn primary-btn mb25 primary-tab-btn"
                                        block
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                </div>
        </div>
    )
}
export default SiteCreation
