import React from 'react';
import {
    Card,
    Form,
    Input,
    Button,
} from 'antd';
import '../index.scss';
import Logo from "../../../../assets/images/logoTab.png";
import {useAuth} from "../auth";
import Loader from "../../Notifications/Loader";
import {checkmail} from "../../../utils/common";
import {error as errorToast} from "../../../utils/toast-utils";
import {useNavigate} from "react-router-dom";

const LoginForm = (props) => {

    const {
        login
    } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);

    /**
     * Function to login
     * @param {form values with login details} values
     */
    const onFinish = async (values) => {
        try {
            const payload = {
                "email": values?.email,
                "password": values?.password
            };
            setIsLoading(true);
            await login(payload);
        } catch (error) {
            errorToast("Incorrect Credentials");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (isLoading) {
        return <Loader data-testid="loader"/>
    }

    return (
        <div className="login-wrapper" data-testid={"login-form"}>
            <div className="logo">
                <img src={Logo} alt="logo" className="logo-img" onClick={() => navigate("/")}/>
            </div>
            <div className="login-body">
                <Card
                    className="card-main login-card"
                    title={"Network Automation Sign In"}
                >
                    <Form
                        name="normal_login"
                        className="login-form card-space"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            className="input-row height-40"
                            name="email"
                            label="Email"
                            normalize={(value, prevVal, prevVals) => value.trim()}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                                {
                                    validator: checkmail,
                                },
                            ]}
                        >
                            <Input placeholder="Enter your email"/>
                        </Form.Item>
                        <div className="description-row">
                            <Form.Item
                                className="input-row height-40"
                                name="password"
                                label="Password"
                                normalize={(value, prevVal, prevVals) => value.trim()}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Password!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Enter your password"/>
                            </Form.Item>
                            <Form.Item className="input-row link-item forgot-box">
                                    <span
                                        className="login-form-forgot forgot-link"
                                        data-testid={"Forgot password"}
                                        onClick={() =>
                                            navigate('/forgot-password')
                                        }
                                    >
                                      Forgot password
                                    </span>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn primary-btn mb25  primary-tab-btn"
                                block
                            >
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default LoginForm;
