export const ApplicationTitle = 'Network Automation';

export const AUTH_INFO = 'AUTH_INFO';
export const REQUESTED_PAGE = 'REQUESTED_PAGE';
export const USER_DETAILS = 'USER_DETAILS';

export const REGEX = {
    LETTERS: /^(?=.*[a-zA-Z]).+/, //NOSONAR
    NUMBERS: /^[0-9]*$/,
    ALPHABET_WITHOUT_SPACE: /^[a-zA-Z]*$/,
    ALPHABET_WITH_SPACE: /^[a-zA-Z ]*$/,
    ALPHABET_WITH_SPECIAL_CHAR: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, //NOSONAR
    ALPHA_NUM_SPACE_HY_UN: /^[\w\s-]+$/,
    EMAIL:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //NOSONAR
    STRING: /^[a-z][a-z ]*$/i,
    alphaNumeric: /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/i,
    NumberInString: /\d/,
    COUNTRY_CODE: /^\+(\d{1}\-)?(\d{1,4})$/ //NOSONAR
};

export const ACTION = {
    WINDOW_RESIZE: 'WINDOW_RESIZE',
};

export const ROLES = {
    "FIELDTECH":"FIELD_TECH",
    "ADMIN": "ADMIN",
    "OPERATIONS": "OPERATIONS",
    "CUTOVER_ENGINEER": "CUTOVER_ENGINEER",
    "SDWAN_INSTALLATION_ENGINEER": "SDWAN_INSTALLATION_ENGINEER",
    "VENDOR_TELSTRA":"VENDOR_TELSTRA",
    "VENDOR_ALLIANCE":"VENDOR_ALLIANCE",
    "VENDOR_MAXTECH":"VENDOR_MAXTECH",
    "SUPER_ADMIN": "TECH_SUPPORT",
    "SCAN_INSPECTION": "SCAN_INSPECTION",
    "DELETE_SITE": "DELETE_SITE",
    "REVERT_STATUS": "REVERT_STATUS",
    "EXPORT_REPORT": "EXPORT_REPORT"
};

export const ROUTER_STATUS ={
    "succeeded": "SUCCESS",
    "success": "SUCCESS", // added for routerOpsCheck
    "failed": "FAILED",
    "running": "RUNNING",
    "aborted": "ABORTED"
}

export const DATE_FILTER = {
    "siteCutoverScheduleDate": "Site Cutover Schedule Date",
    "siteInstallScheduleDate": "Site Install Schedule Date"
}

export const DATE_FILTER_DATA = {
    "siteCutoverScheduleDate": "siteCutoverScheduledDateFilter",
    "siteInstallScheduleDate": "siteInstallScheduledDateFilter"
}

export const TABLE_PAGE_SIZE = 25;
