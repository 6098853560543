import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as React from "react";
import history from '../utils/history';
import Dashboard from "../components/Dashboard";
import {RequireAuth} from "../components/Auth/requiredAuth";
import MainMenuLayout from "../components/MainMenuLayout";
import SiteSchedule from "../components/SiteSchedule";
import LoginForm from "../components/Auth/LogInForm";
import Users from "../components/Users";
import RouterInfoPages from "../components/RouterInfoPages";
import ChangePasswordForm from "../components/Auth/ChangePasswordForm";
import SiteFaults from "../components/SiteFaults";
import CableRemediation from "../components/CableRemediation";
import TargetValue from "../components/TargetValue";
import {AuthProvider} from "../components/Auth/auth";
import {useEffect} from "react";
import {connect} from "react-redux";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {windowResize} from "../utils/actions";
import Networkite from "../components/Networkite";
import SendEmailForm from "../components/Auth/SendEmailForm";
import ResetPasswordForm from "../components/Auth/ResetPasswordForm";
import {CheckLogin} from "../components/Auth/checkLogin";
import BulkUpload from "../components/BulkUpload";
import BulkUploadTableWrapper from "../components/BulkUpload/Table";
import RouterLogs from "../components/RouterInfoPages/RouterLogs";
import RouterLogsListing from "../components/RouterInfoPages/RouterLogs/RouterLogsList";
import SiteCreation from "../components/SiteCreation";
import {getRequiredRoles} from "./helper";
import {ROLES} from "../constants";
import RouterOperationalTest from "../components/RouterInfoPages/RouterOperationalTest";

const Routing = (props) => {
    const handleWindowResize = () => {
        let {
            actions: { windowResize:_windowResize }
        } = props;
        _windowResize();
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
    }, []);

    return (
        <BrowserRouter history={history}>
            <AuthProvider>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <CheckLogin>
                                <LoginForm/>
                            </CheckLogin>
                        }
                    />
                    <Route
                        exact
                        path="/forgot-password"
                        element={
                            <CheckLogin>
                                <SendEmailForm/>
                            </CheckLogin>
                        }
                    />
                    <Route
                        exact
                        path="/reset-password"
                        element={
                            <CheckLogin>
                                <ResetPasswordForm/>
                            </CheckLogin>
                        }
                    />
                    <Route
                        exact
                        path="/change-password"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("change-password")}>
                                <MainMenuLayout>
                                    <ChangePasswordForm/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/dashboard"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("dashboard")}>
                                <MainMenuLayout>
                                    <Dashboard/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/siteSchedule"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("siteSchedule")}>
                                <MainMenuLayout>
                                    <SiteSchedule/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/siteFaults"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("siteFaults")}>
                                <MainMenuLayout>
                                    <SiteFaults/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />

                    <Route
                        exact
                        path="/cableRemediation"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("cableRemediation")}>
                                <MainMenuLayout>
                                    <CableRemediation/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />

                    <Route
                        exact
                        path="/routerConfig"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("routerConfig")}>
                                <MainMenuLayout>
                                    <RouterInfoPages
                                        key={"routerConfig"}/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />

                    <Route
                        exact
                        path="/routerOperationalTest"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("routerOperationalTest")}>
                                <MainMenuLayout>
                                <RouterOperationalTest />
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />

                    <Route
                        exact
                        path="/users"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("users")}>
                                <MainMenuLayout>
                                    <Users/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/forecast-value"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("forecast-value")}>
                                <MainMenuLayout>
                                    <TargetValue/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/networkite"
                        element={(
                            <RequireAuth requiredRoles={getRequiredRoles("networkite")}>
                                <MainMenuLayout>
                                    <Networkite/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/batchUpload"
                        element={(
                            <RequireAuth requiredRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN,ROLES.DELETE_SITE,ROLES.SCAN_INSPECTION]}>
                                <MainMenuLayout>
                                    <BulkUpload/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/batchUpload/:id"
                        element={(
                            <RequireAuth requiredRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN,ROLES.DELETE_SITE,ROLES.SCAN_INSPECTION]}>
                                <MainMenuLayout>
                                    <BulkUploadTableWrapper/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                     <Route
                        exact
                        path="/sitecreation"
                        element={(
                            <RequireAuth requiredRoles={[ROLES.OPERATIONS, ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
                                <MainMenuLayout>
                                    <SiteCreation/>
                                    </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/logs"
                        element={(
                            <RequireAuth requiredRoles={[ROLES.SUPER_ADMIN,ROLES.ADMIN, ROLES.VENDOR_MAXTECH]}>
                                <MainMenuLayout>
                                    <RouterLogs/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />
                    <Route
                        exact
                        path="/logs/:type/:id"
                        element={(
                            <RequireAuth requiredRoles={[ROLES.SUPER_ADMIN,ROLES.ADMIN, ROLES.VENDOR_MAXTECH]}>
                                <MainMenuLayout>
                                    <RouterLogsListing/>
                                </MainMenuLayout>
                            </RequireAuth>
                        )}
                    />

                     {/*<Route
                        exact
                        path="/login/callback"
                        element={
                            <LoginCallback/>
                        }
                    />*/}
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            windowResize
        },
        dispatch
    )
});

export default connect(
    null,
    mapDispatchToProps,
)(Routing);
