import React, {useEffect, useState, useCallback} from 'react';
import './style.scss';
import {Button, Form, Input, Modal, Pagination, Select, Space} from "antd";
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import Loader from "../Notifications/Loader";
import {
    createNewBatchFileAction,
    getBatchConverToCSVAction,
    getBatchUploadsDataAction,
} from "../../service-contracts/networkite/networkite-actions";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import csvIcon from "../../../assets/images/csv-icon.jpg";
import UploadSitesModal from "../Networkite/UploadSitesModal";
import {debounce} from "../../utils/common";

const BulkUpload = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [listData, setListData] = useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isUploadSitesModalVisible, setIsUploadSitesModalVisible] = useState(false);
    const [convertToCSV, setConvertToCSV] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [current, setCurrent] = useState(0);
    const [limit, setLimit] = useState(21);
    const [totalElements, setTotalElements] = useState(null);
    const [textSearch, setTextSearch] = useState('');

    const onBulkFileClick = (file) => {
        navigate(`/batchUpload/${file.id}`)
    }

    const onChange = (page, pageSize=21, textSearch) => {
        setIsLoading(true);
        let payload= {
            pageNo :page,
            pageSize:pageSize,
            textSearch:textSearch
        }
        callData(payload);
    };

    const callData = (payload) => {
        setIsLoading(true);
        let data= payload ? payload : {
             pageNo :1,
             pageSize:21,
             textSearch: textSearch
        }
        getBatchUploadsDataAction(data).then(resp => {
            setListData(resp?.data?.data?.data);
            setCurrent(resp?.data?.data?.pageNo);
            setLimit(resp?.data?.data?.pageSize);
            setTotalElements(resp?.data?.data?.totalElements);
            setTextSearch(resp?.data?.data?.textSearch);
            setIsLoading(false);
        });
    }
  
    /**
     * Function to apply filters
     * @param {text search value} val
     * @param {selectedTab} _selectedTab
     */
    const handleSearch = async (val, _selectedTab) => {
        // await setTableParams({
        //     ...tableParams,
        //     filter: _selectedTab || "",
        //     "textSearch": val,
        // });

        // let _payload = {
        //     "pageNo": tableParams?.pagination?.current || 1,
        //     "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
        //     "textSearch": val,
        //     "sorting": tableParams.sorting,
        //     "sortingColumn": tableParams?.sortingColumn,
        //     "filter": "",
        //     "filterReq": tableParams?.filterReq
        // }

        let _payload = { 
            pageNo :1,
            pageSize:21,
            textSearch: val
       }

        await callData(_payload);
       // setClearFilters(true);
        // setReloadList(true);
    };

    const debouncedSearch = useCallback(
        debounce((e) => {
            handleSearch(e)
        }, 1000),
        [],
    );

    const handleChange = async (e) => {
        await setTextSearch(e.target.value);
        debouncedSearch(e.target.value);
    };

    const handleSubmit = (value) => {
      console.log('Input value:', value);
      // Do something with the input value, such as sending it to an API or processing it further
    };

    const callConvertToCsv = (fileInfo) => {
        setIsLoading(true);

        const formData = new FormData();
        // Ensure that you append the `originFileObj`, which is the actual file object
        formData.append('file', fileInfo.originFileObj);

        getBatchConverToCSVAction(formData).then(resp => {
            setIsLoading(false);
            if(resp?.data){
                downloadFile(resp?.data)
            }
        }).catch(error => {
            setIsLoading(false);
            console.error('Error during file conversion:', error);
            // Handle the error case here, possibly by displaying a message to the user
        });
    }

    function downloadFile(fileUrl) {
        // Use the actual file name you want to download as
        const fileName = 'downloaded_file.xlsx';
        // Create a new anchor element
        const anchor = document.createElement('a');
        // Set the href to the file URL
        anchor.href = fileUrl;
        // Set the download attribute with the file name
        anchor.download = fileName;
        // Append the anchor to the body
        document.body.appendChild(anchor);
        // Trigger the download by simulating a click
        anchor.click();
        // Remove the anchor from the document
        document.body.removeChild(anchor);
    }

    const setFileName = () => {
        form.validateFields().then(async (value) => {
            let formData = form.getFieldValue();
            await createNewBatchFileAction(formData.fileName)
           /* let payload = {
                "createdDate": new Date(),
                "fileName": formData?.fileName,
                //"fileUrl": "processFiles/Agam_15sites_16Nov.csv",
                "id": "6585761d24f1b24c46ada9b4",
                "modifiedDate": new Date(),
                "state": null
            }

            const newData = [...listData];
            newData.unshift(payload);
            setListData(newData);*/
            setIsModalVisible(false);
            form.resetFields();
            callData();
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    useEffect(() => {
        callData();
    }, [])

    return (
        <div className="pd-25">
            {
                isLoading ? <Loader/> : null
            }
            <div className="bulk-upload-setup-top">
                <div className="search-container">
                    <Input
                        size="large"
                        placeholder="Search by date(dd/mm/yyyy) / UPID / File Name"
                        className="search-box"
                        value={textSearch}
                        onChange={handleChange}
                        prefix={<SearchOutlined />}
                        allowClear
                    />
                </div>
                <div className="button-container">
                    <Button onClick={() => {
                        setIsUploadSitesModalVisible(true)
                        setConvertToCSV(true)
                    }}
                        icon={<PlusOutlined />}
                        style={{ "marginRight": "10px" }}
                        className="btn-expand secondary-tab-btn">
                        Convert CSV
                    </Button>
                    <Button onClick={() => {
                        setIsModalVisible(true)
                    }}
                        icon={<PlusOutlined />}
                        style={{ "marginRight": "10px" }}
                        className="btn-expand secondary-tab-btn">
                        Create
                    </Button>

                    <Button onClick={() => {
                        setIsUploadSitesModalVisible(true)
                        setConvertToCSV(false)
                    }}
                        icon={<PlusOutlined />}
                        className="btn-expand secondary-tab-btn">
                        Upload
                    </Button>
                </div>
            </div>

            <div className="bulk-tile-wrapper">
                {
                    listData && listData.length ? listData.map((item, index) => {
                        return <div key ={index} className={"bulk-site-tile"} onClick={ () => onBulkFileClick(item)}>
                            <div className="file-tile-icon"><img src={csvIcon} alt={"csv"}/> {item.fileName}</div>
                            <span> {moment(item.createdDate).format('DD/MM/YYYY')}</span>
                        </div>
                    }) : <div className="file-empty-data"><div>No Data Available</div></div>
                }
            </div>
            {
                listData && listData.length ?  <div className="pagination-wrapper">
                    <Pagination current={current} onChange={onChange} total={totalElements} pageSize={limit}/>
                </div> : null
            }
            <UploadSitesModal setIsModalVisible={setIsUploadSitesModalVisible} uploadModalType={convertToCSV ? "convertToCsv" : "New Sites"}
                              isModalVisible={isUploadSitesModalVisible} callData={convertToCSV ? callConvertToCsv : callData}/>

            <Modal title={'Create batch upload file'}
                   open={isModalVisible}
                   onOk={setFileName}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper">
                    <Form
                        form={form}
                        layout="vertical"
                        className="add-user-form"
                    >

                        <Form.Item label="File Name"
                                   name="fileName"
                                   className="height-40"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Enter File Name!',
                                       },
                                   ]}>
                            <Input placeholder="Enter File Name"/>
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={setFileName}>
                                    Set File Name
                                </Button>
                            </Space>
                        </div>
                    </Form>

                </div>
            </Modal>
        </div>
    )
}
export default BulkUpload;
