import inspectionImg from '../../../assets/images/link.png';
import moment from "moment";
import {Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";

const STATUS_COLOR = {
    "Cutover Fault" : '#FFA700',
    "Site Fault" : '#ED1E1E',
    "TBB Fault" : '#8E5320',
}

export const setColumnForSiteFaultList = (setIsModalVisible, editHandler, isHistoryTabSelected, screenWidth, shouldRedirectToNetbox, handleFaultOwnerSave, handleNoteSave, editNextActionHandler) => {
    let colArr = [
        {
            title: 'UPID',
           // dataIndex: 'upid',
            //sorter: true,
            render: (record) => shouldRedirectToNetbox ? <a href={record.netboxUrl} target="_blank">{record.upid}</a> : `${record.upid}`,
            width: '18%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'SITE NAME',
            dataIndex: 'sitename',
            width: '25%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor',
            width: '15%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'ROUTER INSTALLATION VENDOR',
            dataIndex: 'routerInstallVendor',
            width: '18%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'STATUS',
            //dataIndex: 'status',
            render: (record) => <div className={"status-block"}><span className={"status-indicator"}
                                                                      style={{background: `${STATUS_COLOR[record.status]}`}}/>{record.status}
            </div>,
            width: '20%',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'REASON',
            //dataIndex: 'reason',
            width: '40%',
            render: (record) => {
              // Generate tooltip text with a dot before each reason, ensuring each starts on a new line.
              const tooltipText = record.reasons && record.reasons.length
                ? record.reasons.map(item => `• ${item.message}`).join('<br>') // Use HTML line break
                : '';

              return (
                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: tooltipText }} />} overlayStyle={{ fontSize: '16px' }}>
                  <div className="reason-block-without-dot">
                    {record.reasons && record.reasons.length ? record.reasons[0]?.message : null}
                  </div>
                </Tooltip>
              );
            },
        },
        {
            title: 'LINKS',
            //dataIndex: '',
            //sorter: true,
            render: (record) => <img src={inspectionImg} alt={"#"} style={{cursor: 'pointer'}}
                                     data-testid={"edit-button"}
                                     onClick={() => {
                                         editHandler(record);
                                     }
                                     }/>,
            width: '10%',
        },
    ];

    if(!isHistoryTabSelected){
        colArr.splice(6,0,  {
            title: 'DATE LOGGED',
            //dataIndex: 'lastSiteFault',
            render: (record) => record?.lastSiteFault ? <span> {moment(record?.lastSiteFault).format('MMM DD, YYYY')}</span> : 'NA',
            width: '15%',
            align: 'center',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },)

        colArr.splice(4,0,
            {
                title: 'FAULT OWNER',
                //dataIndex: 'faultOwner',
                width: '20%',
                editable: true,
                render: (record) => record?.faultOwner ? <Tooltip title={record.faultOwner}><div className="text-ellipsis">{record.faultOwner}</div></Tooltip> : 'NA',
                handleSave: handleFaultOwnerSave,
                index:'faultOwner'
            },)

        colArr.splice(5,0,
            {
                title: 'NEXT ACTION',
                //dataIndex: 'faultNote',
                width: '20%',
                //editable: true,
                //handleSave: handleNoteSave,
                render: (record) => record?.faultNote ? <div><div className="text-ellipsis">{record.faultNote}</div>
                    <EditOutlined data-testid={"edit-button"} style={{cursor: 'pointer'}}  onClick={() => {
                        editNextActionHandler(record);
                    }
                    }/>
                </div> : <div><span>NA</span> <EditOutlined data-testid={"edit-button"} style={{cursor: 'pointer'}}  onClick={() => {
                    editNextActionHandler(record);
                }
                }/></div>,
                index:'faultNote'
            },)
    }

    return colArr;
}

