import React, {useState} from 'react';
import '../style.scss';
import {Form, Modal} from "antd";
import {error as errorToast} from "../../../utils/toast-utils";
import Loader from "../../Notifications/Loader";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from '@ant-design/icons';
import {message} from 'antd';
import {USER_DETAILS} from "../../../constants";
import config from "../../../../config";

const UploadSitesModal = ({setIsModalVisible, isModalVisible, uploadModalType, callData}) => {

    const acceptedFileTypes = uploadModalType === "convertToCsv"
    ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Accept only .xlsx files for "convertToCsv"
    : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // Accept both .csv and .xlsx files for other types


    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem(USER_DETAILS))?.access_token;

    const setAction = () => {
        let _action ='';
        switch (uploadModalType){
            case "Sites":
                _action  = `${config.API_ROOT}/loadSitesInSafteyCulture`
                break;
            case "Schedules":
                _action  = `${config.API_ROOT}/bulkUploadSiteSchedules`
                break;
            case "Inspections":
                _action  = `${config.API_ROOT}/bulkUploadInspections`
                break;
            case "New Sites":
                _action  = `${config.API_ROOT}/uploadFileToProcess`
                break;
            case "convertToCsv":
                _action =  `${config.API_ROOT}/convertIntoBatchUploadFile`
                break;
            default:
                return;
        }
        return _action;
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const props = {
        name: 'file',
        accept: acceptedFileTypes,
        action: setAction(),
        headers: {
            authorization: `Bearer ${token}`,
        },
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (status === 'done') {
               // console.log(info.file.response, "respp");
                message.success(`${info.file.name} file uploaded successfully.`);
                //successToast("Sites Loaded Successfully");
               /* info.file.response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'picture.csv';
                    a.click();
                });*/
                setIsModalVisible(false);
                if (typeof callData === 'function') {
                    if (uploadModalType === 'convertToCsv') {
                        callData(info.file);
                    } else {
                        callData();
                    }
                }
                form.resetFields();
            } else if (status === 'error') {
                errorToast(info?.file?.response?.message || `${info.file.name} file upload failed.`);
                //message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            //console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <>
            {
                isLoading ? <Loader/> : null
            }
            <Modal title={`Upload ${uploadModalType}`}
                   open={isModalVisible}
                   //onOk={loadSites}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper">
                    <Form
                        form={form}
                        layout="vertical"
                        className="add-user-form"
                    >
                        <Form.Item
                            name="file"
                            //label="Time Period"
                            className="height-40"
                            rules={[{required: true, message: 'Please upload a csv file'}]}
                        >
                            <Dragger {...props} multiple={false} maxCount={1}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single upload.
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default UploadSitesModal;
