import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button} from "antd";
import {
    getMonthlyTargetListAction, getWeeklyTargetListAction,
} from "../../service-contracts/dashboard/dashboard-actions";
import {columns, columnsWeekly, mS} from "./helper";
import PaginatedTable from "../CommonComponents/PaginatedTable";
import {GRAPH_TITLE} from "../Dashboard/helper";
import SetTargetModal from "./SetTargetModal";

const TargetValue = (props) => {
    const [monthData, setMonthData] = useState();
    const [weekData, setWeekData] = useState();
    const [mode, setMode] = useState('');
    const [loading, setLoading] = useState(false);
    const [weekLoading, setWeekLoading] = useState(false);
    const [reloadList, setReloadList] = useState(true);
    const [reloadWeeklyList, setReloadWeeklyList] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [weeklyTableParams, setWeeklyTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const callAfterTargetSet = () => {
        mode === GRAPH_TITLE.WEEKLY_REPORT ? fetchData(true, false) : fetchData(false, true);
    }

    const fetchData = async (callAfterSetWeek=false, callAfterSetMonth=false) => {
        let payload = {
            "pageNo": callAfterSetMonth ? 1 : tableParams?.pagination?.current,
            "pageSize": 10,
        }
        let weeklyPayload = {
            "pageNo": callAfterSetWeek ? 1 : weeklyTableParams?.pagination?.current,
            "pageSize": 10,
        }

        if(reloadList || callAfterSetMonth){
            setLoading(true);
            getMonthlyTargetListAction(payload.pageNo, payload.pageSize).then(resp => {
                const {data: {data}} = resp;
                let _data = data?.targetList;
                _data.map( item => item.monthName = `${mS[item?.month?.month] || ''}, ${item?.month?.year}` )
                setMonthData(_data);
                // setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        current: data.pageNo,
                        total: data.totalElements,
                    },
                });
                setReloadList(false);
            }).catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            }).finally(() => {
                setLoading(false);
            });
        }

        if(reloadWeeklyList || callAfterSetWeek){
            setWeekLoading(true);
            getWeeklyTargetListAction(weeklyPayload.pageNo, weeklyPayload.pageSize).then(resp => {
                const {data: {data}} = resp;
                let _data = data?.weekStatusTargetList;
                _data.map( item => {
                    item.weekDisplay = `${item?.week?.startDate || ''} - ${item?.week?.endDate || ''}`;
                    item.siteStatus = item.siteStatus.replace(/_/g, " ")
                })
                setWeekData(_data);
                // setLoading(false);
                setWeeklyTableParams({
                    ...weeklyTableParams,
                    pagination: {
                        ...weeklyTableParams.pagination,
                        current: data.pageNo,
                        total: data.totalElements,
                    },
                });
                setReloadWeeklyList(false);
            }).catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            }).finally(() => {
                setWeekLoading(false);
            });
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        await setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        setReloadList(true);

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setMonthData([]);
        }
    };

    const handleWeeklyTableChange = async (pagination, filters, sorter) => {
        await setWeeklyTableParams({
            pagination,
            filters,
            ...sorter,
        });

        setReloadWeeklyList(true);

        if (pagination.pageSize !== weeklyTableParams.pagination?.pageSize) {
            setMonthData([]);
        }
    };

    const openTargetModal = (graph) => {
        setIsModalVisible(true);
        setMode(graph);
    }

    useEffect(() => {
        if (reloadList || reloadWeeklyList) fetchData();
    }, [reloadList, reloadWeeklyList]);

    return (
        <div className="pd-25">
             <div className="target-tables-wrapper">
                 <div className={"month-table-wrap"}>
                     <div className="table-title"><span>Forecast Values for Sites Cutover (per Month)</span>
                         <Button onClick={() => {
                             openTargetModal(GRAPH_TITLE.SITES_CUTOVER);
                         }}
                                 className="btn-expand secondary-tab-btn">
                             Set Monthly Forecast
                         </Button>
                     </div>
                     <PaginatedTable
                         columns={columns}
                         data={monthData}
                         loading={loading}
                         tableParams={tableParams}
                         handleTableChange={handleTableChange}
                         dataTestId="month-table"
                     />
                 </div>
                 <div className={"week-table-wrap"}>
                     <div className="table-title"><span>Forecast Values for Sites Installed and/or Cutover (per Week)</span>
                         <Button onClick={() => {
                             openTargetModal(GRAPH_TITLE.WEEKLY_REPORT);
                         }}
                                 className="btn-expand secondary-tab-btn">
                             Set Weekly Forecast
                         </Button>
                     </div>
                     <PaginatedTable
                         columns={columnsWeekly}
                         data={weekData}
                         loading={weekLoading}
                         tableParams={weeklyTableParams}
                         handleTableChange={handleWeeklyTableChange}
                         scrollX={500}
                         dataTestId="week-table"
                     />
                 </div>
            </div>
            <SetTargetModal setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                            callAfterTargetSet={callAfterTargetSet}
                            mode={mode}/>
        </div>
    )
}
export default TargetValue;
