const configUrl = '/config.json';
// need to set the config path here

const renderApp = () => {
    // eslint-disable-next-line global-require
    require('./bootstrap');
};

if (process.env.NODE_ENV === 'production') {
    // This is for deployed environments like CI, murrray, nile, prod
    fetch(configUrl)
        .then((resp) => resp.json())
        .then((config) => {
            window.APP_CONFIG = config;
            renderApp();
        });
} else {
    // for local dev no need to fetch configs from api,
    // as they can directly utilize the configs in codebase folder.
    renderApp();
}

