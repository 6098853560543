import React from 'react';
import {
    Card,
    Form,
    Input,
    Button,
} from 'antd';
import '../index.scss';
import Logo from "../../../../assets/images/logoTab.png";
import Loader from "../../Notifications/Loader";
import {checkmail} from "../../../utils/common";
import {error as errorToast, success} from "../../../utils/toast-utils";
import {sendResetLink} from "../../../service-contracts/auth/auth-service";
import {useNavigate} from "react-router-dom";

const SendEmailForm = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);

    
    const onFinish = async (values) => {
        try {
            const payload = {
                "email": values?.email,
            };
            setIsLoading(true);
            await sendResetLink(payload).then(resp => {
                if(resp.data.status){
                    success("Reset Link has been sent on your email")
                    navigate("/")
                }
            });
        } catch (error) {
            errorToast("Error Occurred");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-wrapper">
            {
                isLoading ? <Loader data-testid="loader"/> : null
            }
            <div className="logo">
                <img src={Logo} alt="logo" className="logo-img" onClick={() => navigate("/")}/>
            </div>
            <div className="login-body">
                <Card
                    className="card-main login-card"
                    title={"Network Automation Sign In"}
                >
                    <Form
                        name="normal_login"
                        className="login-form card-space"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            className="input-row height-40"
                            name="email"
                            label="Email"
                            normalize={(value, prevVal, prevVals) => value.trim()}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                                {
                                    validator: checkmail,
                                },
                            ]}
                        >
                            <Input placeholder="Enter your email"/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className=" btn primary-btn mb25  primary-tab-btn"
                                block
                            >
                                Send Email
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default SendEmailForm;
