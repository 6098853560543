import {
    getCableRemediationHistoryListAction,
    getCableRemediationListAction,
    getSitesFaultHistoryListAction,
    getSitesFaultListAction,
    getSitesFilterAction,
    setFaultOwnerAction, setNoteAction,
} from "../../service-contracts/dashboard/dashboard-actions";
import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {Form, Tabs} from "antd";
import {
    getTableParamInitialVal,
    handleCancel,
    handleSearch,
    handleTableChange,
} from "./helper";
import {debounce} from "../../utils/common";
import {useLocation} from "react-router-dom";
import InspectionModal from "../InspectionModal/index";
import VendorTable from "../SiteSchedule/VendorTable";
import {TABLE_PAGE_SIZE} from "../../constants";
import {error as errorToast, success as successToast} from "../../utils/toast-utils";
import EditModal from "./EditModal";

const CableRemediation = ({isSiteFaultListingRequired}) => {
    const [form] = Form.useForm();
    const {state} = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNextActionModalVisible, setIsNextActionModalVisible] = useState(false);
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reloadList, setReloadList] = useState(true);
    const [record, setRecord] = useState('');
    const [selectedTab, setSelectedTab] = useState('');
    const [searchInput, setSearchInput] = React.useState(state && state.upid ? state.upid : '');
    const [tableParams, setTableParams] = useState(getTableParamInitialVal(state));
    const [filterReqObj, setFilterReqObj] = useState({});
    const [filterReq, setFilterReq] = useState({});
    const [clearFilters, setClearFilters] = useState(false);

    const handleTable = (pagination, filters, sorter) => {
        handleTableChange(pagination, filters, sorter, setTableParams, tableParams, setReloadList, setListData, filterReq);
    }

    /**
     * Function to apply filters
     * @param {filter object to be send as payload} _filterReq
     */
    const handleFilters = async (_filterReq) => {

        setFilterReq(_filterReq);
        await setTableParams({
            ...tableParams,
            _filterReq,
        });

        let _payload = {
            "pageNo": tableParams?.pagination?.current || 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": tableParams?.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": tableParams.filter,
            "filterReq": _filterReq
        }

        fetchData(selectedTab === "HISTORY", _payload);
    }

    /**
     * Function to apply filters
     * @param {text search value} val
     * @param {selectedTab} _selectedTab
     */
    const handleSearch = async (val, _selectedTab) => {
        let _payload = {
            pageNo :1,
            pageSize:25,
            textSearch: val
        }

        await setTableParams({
            ...tableParams,
            "textSearch": val,
            "filterReq": tableParams?.filterReq
        });

        fetchData(selectedTab === "HISTORY", _payload);
    };


    const debouncedSearch = useCallback(
        debounce((e) => {
            handleSearch(e, setTableParams, tableParams, setReloadList)
        }, 1000),
        [tableParams],
    );

    const onSearchInputChange = async (e) => {
        await setSearchInput(e.target.value);
        debouncedSearch(e.target.value);
        //setClearFilters(true);
    }

    /**
     * Function to table listing data
     * @param {is history tab selected on UI} onHistoryTab
     * @param {payload to be send in api} _payload
     */
    const fetchData = (onHistoryTab, _payload) => {
        setLoading(true);
        let payload = {
            "pageNo": tableParams?.pagination?.current,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": tableParams.sorting,
            "sortingColumn": tableParams.sortingColumn,
            filterReq:tableParams.filterReq,
        }

        if (tableParams.sorting === '') delete payload.sorting;

        if (_payload) payload = _payload;

        let callApi = isSiteFaultListingRequired ? getSitesFaultListAction : getCableRemediationListAction;

        if (onHistoryTab) {
            callApi = isSiteFaultListingRequired ? getSitesFaultHistoryListAction : getCableRemediationHistoryListAction;
        }

        callApi(payload).then(resp => {
            const {data} = resp;
            let page = isSiteFaultListingRequired ? "SITE_FAULT" : onHistoryTab? "CABLE_REMEDIATION_HISTORY" : "CABLE_REMEDIATION";
            if(resp.data.data){
                getSitesFilterAction(page).then( _resp => {
                    setFilterReqObj(_resp?.data?.data)
                })
            }
            setListData(isSiteFaultListingRequired ? data?.data?.sitefaultdata : data?.data?.cableRemediationResponseList);
            setLoading(false);
            setTableParams({
                ...tableParams,
                filterReq: _payload? _payload.filterReq : filterReq,
                "textSearch": resp?.data?.data?.textSearch,
                "sortingColumn": resp?.data?.data?.sortingColumn,
                "sorting": resp?.data?.data?.sorting,
                pagination: {
                    ...tableParams.pagination,
                    current: data?.data?.pageNo,
                    total: data?.data?.totalElements,
                    pageSize: data?.data?.pageSize,
                },
            });
            setReloadList(false);
        });
    };

    const editHandler = (data) => {
        setIsModalVisible(true);
        setRecord(data);
    }

    const editNextActionHandler = (data) => {
        setIsNextActionModalVisible(true);
        setRecord(data);
    }

    /**
     * Function save the fault owner
     * @param {complete row data} row
     */
    const handleFaultOwnerSave = async (row) => {
        setLoading(true);
        let payload = {
            "faultOwner": row.faultOwner
        };

        const response = await setFaultOwnerAction(row.siteId, payload);
        if (response) {
            successToast(response?.data?.message || "Fault Owner has been set successfully");
            setReloadList(true);
        } else {
            errorToast(response?.data?.message || "Error Occurred");
        }
        setLoading(false);
    };

    const handleNoteSave = async (value, row) => {
        setLoading(true);
        let payload = {
            "faultNote": value
        };

        const response = await setNoteAction(row.siteId, payload);
        if (response) {
            successToast(response?.data?.message || "Next Action has been set successfully");
            setReloadList(true);
        } else {
            errorToast(response?.data?.message || "Error Occurred");
        }
        setIsNextActionModalVisible(false);
        setLoading(false);
    };

    const TableComponent = <VendorTable setIsModalVisible={setIsModalVisible}
                                        editHandler={editHandler}
                                        data={listData}
                                        loading={loading}
                                        tableParams={tableParams}
                                        handleTableChange={handleTable}
                                        onSearchInputChange={onSearchInputChange}
                                        searchInput={searchInput}
                                        isHistoryTabSelected={selectedTab === "HISTORY"}
                                        sourceComponent={`${isSiteFaultListingRequired ? "siteFault" : "cableRemediation"}`}
                                        isFiltersRequired={true}
                                        handleFilters={handleFilters}
                                        filterReqObj={filterReqObj}
                                        clearFilters={clearFilters}
                                        setClearFilters={setClearFilters}
                                        fetchData={fetchData}
                                        handleFaultOwnerSave={handleFaultOwnerSave}
                                        handleNoteSave={handleNoteSave}
                                        editNextActionHandler={editNextActionHandler}
                                        record={record}
    />

    const items = [
        {
            key: 'ACTIVE',
            label: `Active`,
            children: TableComponent,
        },
        {
            key: 'HISTORY',
            label: `History`,
            children: TableComponent,
        }
    ];

    const onChange = async (key) => {
        console.log('tab changed...')
        let _payload = {
            "pageNo": 1,
            "pageSize": TABLE_PAGE_SIZE,
            "textSearch": "",
            "sorting": "ASC",
        }
        await setTableParams({
            ...tableParams,
            "pageNo": 1,
            "pageSize": TABLE_PAGE_SIZE,
            "textSearch": "",
            "sorting": "ASC",
        });

        await setSelectedTab(key)
        fetchData(key === "HISTORY", _payload);
        setSearchInput("");
        setClearFilters(true);
    };

    useEffect(() => {
        if (reloadList) fetchData(selectedTab === "HISTORY");
    }, [reloadList]);

    return (
        <div className={`${isSiteFaultListingRequired ? "site-fault-table-wrapper" : ""} tabs-container cable-table-wrapper`}>
            <Tabs defaultActiveKey="ALL" items={items} onChange={onChange}/>

            {
                isModalVisible && <InspectionModal isModalVisible={isModalVisible}
                                                   handleCancel={() => handleCancel(form, setIsModalVisible)}
                                                   record={record}/>
            }

            {
                isNextActionModalVisible && <EditModal isNextActionModalVisible={isNextActionModalVisible}
                                                       handleNoteSave={handleNoteSave}
                                                   handleCancel={() => handleCancel(form, setIsNextActionModalVisible)}
                                                   record={record}/>
            }
        </div>
    )
}
export default CableRemediation;


