import React from 'react';
import './style.scss';
import {Modal, Table} from "antd";
import {inspectionTableColumns} from "./helper";

const InspectionModal = ({isModalVisible, handleCancel,record, recordPerSite}) => {
    let business = recordPerSite ? recordPerSite.business : record?.business;
    let sitename = recordPerSite ? recordPerSite.sitename : record?.sitename || record.siteName;
    let upid = recordPerSite ? recordPerSite.upid : record?.upid;
    let inspectionData = recordPerSite ? recordPerSite.inspectionData : [];
    return (
        <>
            <Modal title={"Inspections"}
                   open={isModalVisible}
                   className={"inspection-modal-wrapper"}
                //onOk={handleOk}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper inspection-modal">
                    <div className="detail-box">
                    {

                        upid ? <div className="business-box"><span>UPID:</span>{upid}</div> : null
                    }
                    {
                        business ? <div className="business-box"><span>Business:</span>{business}</div> : null
                    }
                    </div>
                    {
                        sitename ? <div className="business-box last-business-box"><span>SiteName:</span>{sitename}</div> : null
                    }
                    {/*{
                        record && record.inspectionLink && Array.isArray(record.inspectionLink) && record.inspectionLink.length ?
                            record.inspectionLink.map(item => <a href={item?.inspectionDeepLink} target={"_blank"}>
                                <img src={inspectionImg} alt={"#"}
                                     onClick={() => {
                                         editHandler(record);
                                     }
                                     }/>
                                {item?.inspectionDeepLink}</a>) : <div>No Inspections Found</div>
                    }*/}
                    {
                        <Table columns={inspectionTableColumns} dataSource={inspectionData[0]?.inspectionLink || record?.inspectionLink} pagination={false}/>
                    }
                </div>
            </Modal>

        </>
    )
}
export default InspectionModal;
