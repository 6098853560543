import {
    createInspection,
    downloadTemplate,
    exportSitesData,
    getModifiedInspection,
    publishManualReportForBusinessUnit,
    scanMultipleSites,
    sendPublishManualVendorReport,
    sendPublishVendorReport,
    showEmail, showEmailByVendor,
    updateEmail, getBatchUploadsData, getCSVSiteDataList,
    getDropdownsValuesBulkUpload, pushSiteForProcess, saveSiteForProcess, createNewBatchFile, getBatchConvertToCsv
} from "./networkite-services";
import {error as errorToast} from "../../utils/toast-utils";

export const scanMultipleSitesAction = async (payload) => {
    try {
        const response = await scanMultipleSites(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getModifiedInspectionAction = async (payload) => {
    try {
        const response = await getModifiedInspection(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const createInspectionAction = async (id) => {
    try {
        const response = await createInspection(id);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const sendPublishVendorReportAction = async (payload) => {
    try {
        const response = await sendPublishVendorReport(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const sendPublishManualVendorReportAction = async (startTime, endTime) => {
    try {
        const response = await sendPublishManualVendorReport(startTime, endTime);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const publishManualReportForBusinessUnitAction = async (payload) => {
    try {
        const response = await publishManualReportForBusinessUnit(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const exportSitesDataAction = async (startTime, endTime) => {
    try {
        const response = await exportSitesData(startTime, endTime);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const downloadTemplateAction = async (type) => {
    try {
        const response = await downloadTemplate(type);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const updateEmailAction = async (payload) => {
    try {
        const response = await updateEmail(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const showEmailAction = async () => {
    try {
        const response = await showEmail();
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const showEmailByVendorAction = async (vendor) => {
    try {
        const response = await showEmailByVendor(vendor);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getBatchUploadsDataAction = async (data) => {
    try {
        const response = await getBatchUploadsData(data);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getBatchConverToCSVAction = async (formData) => {
    try {
        const response = await getBatchConvertToCsv(formData);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getCSVSiteDataListAction = async (payload) => {
    try{
        const response = await getCSVSiteDataList(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const saveSiteForProcessAction = async (payload) => {
    try{
        const response = await saveSiteForProcess(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const pushSiteForProcessAction = async (payload) => {
    try{
        const response = await pushSiteForProcess(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getDropdownsValuesBulkUploadAction = async (payload) => {
    try{
        const response = await getDropdownsValuesBulkUpload(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const createNewBatchFileAction = async (fileName) => {
    try{
        const response = await createNewBatchFile(fileName);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};







