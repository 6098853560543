import React from 'react';
import './loader.scss';

const Loader = ({ task }) => {
  return (
      <div id="loader-wrapper"  data-testid="loader">
        <div className="loader"/>
        {
          task && (
              <div className= "taskClass">
                <p>
                  { task }
                </p>
              </div>
          )
        }

      </div>
  )
};

export default Loader;
