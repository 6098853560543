import React, {useEffect, useState} from 'react';
import '../style.scss';
import {Button, Form, Modal, Select, Space} from "antd";
import {error as errorToast, success as successToast} from "../../../utils/toast-utils";
import {
    showEmailByVendorAction,
    updateEmailAction
} from "../../../service-contracts/networkite/networkite-actions";
import Loader from "../../Notifications/Loader";
import {Option} from "antd/es/mentions";
import {ReactMultiEmail} from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const EmailModal = ({setIsModalVisible, isModalVisible, intervalModalType,_toEmails,_ccEmails}) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [toEmails, setToEmails] = React.useState(_toEmails);
    const [ccEmails, setCcEmails] = React.useState(_ccEmails);
    const [toEmailsRequired, setToEmailsRequired] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    let VendorList = ["TELSTRA", "ALLIANCE", "MAXTECH"]

    const setEmail = () => {
        form.validateFields().then(async (value) => {
            let formData = form.getFieldValue();
            if(!toEmails || !toEmails.length){
                setToEmailsRequired(true);
                return;
            }
            let payload = {
                "emailId": toEmails,
                "ccs": ccEmails
            }
            if (formData.vendor) {
                payload.vendor = formData.vendor
            }

            try {
                setIsLoading(true);
                let response = await updateEmailAction(payload);
                if (response?.data?.status) {
                    successToast("Email Set Successfully");
                    setIsModalVisible(false);
                    form.resetFields();
                    setToEmails([]);
                    setCcEmails([]);
                    setToEmailsRequired(false);
                } else {
                    errorToast(response?.data?.message || "Error Occurred");
                }
            } catch (error) {
                console.log(`Invalid`);
            } finally {
                setIsLoading(false);
            }
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
        setToEmails([]);
        setCcEmails([]);
    }

    const onVendorChange = async (val) => {
        setIsLoading(true);
        const response = await showEmailByVendorAction(val);
        if (response && response.data) {
            let data = response?.data?.data;
            setToEmails(data.emailId);
            setCcEmails(data.ccs);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setCcEmails(_ccEmails);
    }, [_ccEmails]);

    useEffect(() => {
        setToEmails(_toEmails);
        if(_toEmails && _toEmails.length) setToEmailsRequired(false)
    }, [_toEmails]);

    return (
        <>
            {
                isLoading ? <Loader/> : null
            }
            <Modal title={'Set Email'}
                   open={isModalVisible}
                   onOk={setEmail}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper email-modal-wrapper">
                    <Form
                        form={form}
                        layout="vertical"
                        className="add-user-form"
                    >

                        {
                            intervalModalType === "Vendor Email" ? <Form.Item
                                className="select-dropdown-field height-40"
                                name={`vendor`}
                                label={`Vendor`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Vendor',
                                    },
                                ]}
                            >
                                <Select
                                    showArrow={false}
                                    popupClassName="select-dropdown-menu"
                                    placeholder="Select Vendor"
                                    showSearch={true}
                                    onChange={onVendorChange}
                                >
                                    {
                                        VendorList && VendorList.length && VendorList.map(item => {
                                            return <Option value={item} key={item}
                                                           data-testid={`${item}`}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item> : null

                        }

                        <Form.Item
                            className="input-text-field height-40"
                            name={`to`}
                            label={`To`}
                        >
                            <ReactMultiEmail
                               // placeholder='To'
                                emails={toEmails}
                                onChange={(_emails) => {
                                    setToEmails(_emails);
                                    setToEmailsRequired(false);
                                }}
                                autoFocus={true}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            <div data-tag-item>{email}</div>
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
                                        </div>
                                    );
                                }}
                            />
                        </Form.Item>
                        {
                            toEmailsRequired ? <div className="error-display">Email Required</div> : null
                        }

                        <Form.Item
                            className="input-text-field height-40"
                            name={`cc`}
                            label={`CC`}
                        >
                            <ReactMultiEmail
                               // placeholder='CC'
                                emails={ccEmails}
                                onChange={(_emails) => {
                                    setCcEmails(_emails);
                                }}
                                autoFocus={true}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            <div data-tag-item>{email}</div>
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
                                        </div>
                                    );
                                }}
                            />
                        </Form.Item>

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={setEmail}>
                                    Set Email
                                </Button>
                            </Space>
                        </div>
                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default EmailModal;
