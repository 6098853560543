import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './index.scss';

const AppBreadcrumb = ({ routes }) => {

  const itemRender = (route, params, _routes) => {
    const index = _routes.findIndex(x => x.title === route.title);

    return index === _routes.length -1 ? (
      <span className="app-breadcrumb-withoutlink">
        {route.title}
      </span>
    ) : (
      <Link className="app-breadcrumb-link" to={route.path}>
        {route.title}
      </Link>
    );
  };

  return (
    <Breadcrumb
      className="app-breadcrumb"
      itemRender={itemRender}
      routes={routes}
    />
  );
};

export default AppBreadcrumb;
