import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import reducers from './reducer';

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;
const middlewares = applyMiddleware(thunkMiddleware);

const store = createStore(
  reducers,
  composeEnhancers(middlewares),
);

export default store;
