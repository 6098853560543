import React, {useCallback, useEffect, useState} from 'react';
import '../style.scss';
import {debounce} from "../../../utils/common";
import Loader from "../../Notifications/Loader";
import {TABLE_PAGE_SIZE} from "../../../constants";
import {
    getCSVSiteDataListAction,
} from "../../../service-contracts/networkite/networkite-actions";
import {useParams} from "react-router-dom";
import {bulkUploadBreadcrumb} from "../../CommonComponents/Breadcrumb/breadcrumbsConstants";
import AppBreadcrumb from "../../CommonComponents/Breadcrumb";
import BulkUploadTable from "./BulkUploadTableNew";

const BulkUploadTableWrapper = (props) => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: TABLE_PAGE_SIZE,
            pageSizeOptions: ['25', '50', '75', '100'],
            showSizeChanger: true,
        },
        textSearch: '',
        sorting: 'ASC',
        filter: params.id || "",
       // filterReq: filterReq,
    });

    const handleTableChange = async (pagination, filters, sorter) => {
        let sorting = 'ASC';
        let sortingColumn = '';

        if (sorter.hasOwnProperty("column")) {
            sorting = sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : "ASC";
            sortingColumn= sorter.column && sorter.column?.title ? typeof (sorter.column?.title) === 'function' ? sorter.column?.dataIndex: sorter.column?.title : null;
        }

        await setTableParams({
            ...tableParams,
            pagination,
            filters,
            ...sorter,
            sorting,
           // filterReq,
            filter: params.id,
            sortingColumn
        });

        let _payload = {
            "pageNo": pagination?.current || 1,
            "pageSize": pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": sorting,
            "filter": tableParams.filter || params.id,
            //"filterReq": tableParams?.filterReq,
            "sortingColumn": sortingColumn,
        }

        fetchData(_payload);
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const handleSearch = async (val) => {
        await setTableParams({
            ...tableParams,
            filter: "",
            "textSearch": val,
        });

        let _payload = {
            "pageNo": tableParams?.pagination?.current || 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": val,
            "sorting": tableParams.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": params.id,
            //"filterReq": tableParams?.filterReq
        }

        await fetchData(_payload);
    };

    const debouncedSearch = useCallback(
        debounce((e, _selectedTab) => {
            handleSearch(e)
        }, 1000),
        [tableParams],
    );

    const onSearchInputChange = async (e) => {
        await setSearchInput(e.target.value);
        debouncedSearch(e.target.value, "");
    }

    const fetchData = (_payload) => {
        setLoading(true);
        let payload = {
            "pageNo": tableParams?.pagination?.current || 1,
            "pageSize": tableParams?.pagination?.pageSize || TABLE_PAGE_SIZE,
            "textSearch": tableParams?.textSearch,
            "sorting": tableParams.sorting,
            "sortingColumn": tableParams?.sortingColumn,
            "filter": tableParams.filter || params.id,
           // "filterReq":tableParams.filterReq,
        }

        if (tableParams.sorting === '') delete payload.sorting;

        if (_payload) payload = _payload;

        getCSVSiteDataListAction(payload).then(resp => {
            /* if(resp.data.data){
                 getSitesFilterAction("SITE_SCHEDULE").then( resp => {
                     setFilterReqObj(resp?.data?.data)
                 })
             }*/
            let _data = resp?.data?.data?.csvSiteDataList ? resp?.data?.data?.csvSiteDataList.map( (item, index) => {
                item.key = index.toString();
                return item;
            }) : [];
            setData(_data);
            setLoading(false);
            setTableParams({
                ...tableParams,
                filter: _payload ? _payload.filter : "",
               // filterReq: _payload? _payload.filterReq : filterReq,
                pagination: {
                    ...tableParams.pagination,
                    current: resp?.data?.data?.pageNo,
                    total: resp?.data?.data?.totalElements,
                    pageSize: resp?.data?.data?.pageSize,
                },
                "textSearch": resp?.data?.data?.textSearch,
                "sortingColumn": resp?.data?.data?.sortingColumn,
                "sorting": resp?.data?.data?.sorting,
            });
            // setReloadList(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [params.id]);

    return (
        <div className="site-schedule-wrapper tabs-container bulk-upload-table-container" data-testid="site-schedule-wrapper">
            <AppBreadcrumb routes={bulkUploadBreadcrumb(params.id)} />
            {
                loading ? <Loader/> : null
            }

            <BulkUploadTable data={data}
                             loading={loading}
                             tableParams={tableParams}
                             handleTableChange={handleTableChange}
                             onSearchInputChange={onSearchInputChange}
                             searchInput={searchInput}
                             fileId = {params.id}
                             fetchData={fetchData}
                             setData={setData}
                             setLoading={setLoading}
            />
        </div>
    )
}

export default BulkUploadTableWrapper;
