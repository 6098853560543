import {ResponsivePie} from "@nivo/pie";
import React from "react";
import {colorArray} from "../../helper";

//const margin = { top: 80, right: 80, bottom: 80, left:-80 }
//const margin = { top: 20, right: 20, bottom: 20, left: 20 }; //for mobile view
//const margin = { top: 20, right: 100, bottom: 20, left: -100 };
const overlayBottom = 40;
const styles = {
    root: {
        fontFamily: "consolas, sans-serif",
        textAlign: "center",
        position: "relative",
       // width: 450,
       // height: 600
    },
    overlay: {
        position: "absolute",
        top: 0,
        //right: margin.right,
        bottom: 0,
        //left: margin.left,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        fontFamily: "InterSemiBold,sans-serif",
        color: "#000000",
        textAlign: "center",
        // This is important to preserve the chart interactivity
        pointerEvents: "none"
    },
};

const PieChart = ({data, totalSites,screenWidth,toggleFilter}) => {
    const margin = screenWidth < 1435 || toggleFilter ? { top: 20, right: 20, bottom: 20, left: 20 } : { top: 60, right: 100, bottom: 60, left: -100 }
    const legendX= screenWidth < 1435 ? -40 : 20;
    const legendY=0;
    return (
        <div  className="graph-box graph-one-box" style={styles.root} data-testid="pie-chart">
            <ResponsivePie
                data={data}
                colors={colorArray}
                // width={400}
                margin={margin}
                slicesLabelRadiusMultiplier={2}
                sliceLabelsRadiusOffset={5}
                innerRadius={0.5}
                padAngle={0}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                theme={{ legends: { text: { fontSize: 10, fontFamily : "InterMedium", } }, tooltip:{ fontSize:12 } }}
                legends={(!toggleFilter && (screenWidth > 767 && screenWidth< 1281 || screenWidth> 1435)) ? [
                    {
                        data: data.map(item => {
                            return{
                                label: `${item?.label} (${item.count})`,
                                id:item.id,
                                color: item.color,
                            }
                        }),
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: legendX,
                        translateY: legendY,
                        itemsSpacing: 0,
                        itemWidth: 104,
                        itemHeight: 24,
                        itemTextColor: '#323E59',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 13,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ] : false}
                tooltip={(tooltipData) => {
                    let _data = tooltipData.datum
                    return <div className="tooltip-chart-wrapper" style={{
                        padding: 10,
                        background: '#ffffff',
                    }}>
                        <span className="color-box-tooltip" style={{"background": _data.color}}/> {_data.id} : {_data.value}
                    </div>
                }
                }
            />
            {
                data?.length && totalSites ?    <div style={{...styles.overlay, right:margin.right, left:margin.left}}>
                    <span>{totalSites} {totalSites > 1 ? "Sites" : "Site"}</span>
                </div> : null
            }
        </div>
    );
}

export default PieChart;
