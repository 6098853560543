import React, {useEffect, useState} from 'react';
import './style.scss';
import Loader from "../../Notifications/Loader";
import {useNavigate} from "react-router-dom";
import routerIcon from "../../../../assets/images/router.png";
import {Pagination, Tabs} from "antd";
import {getRouterListAction} from "../../../service-contracts/dashboard/dashboard-actions";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";

const RouterLogs = (props) => {
    const navigate = useNavigate();
    const [listData, setListData] = useState();
    const [selectedTab, setSelectedTab] = useState('routerConfig');
    const [searchInput, setSearchInput] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [current, setCurrent] = useState(0);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [totalElements, setTotalElements] = useState(null);

    const callAfterApi = (resp) => {
        setListData(resp?.data?.data?.content);
        setOffset(resp?.data?.data?.number);
        setCurrent(resp?.data?.data?.number);
        setLimit(resp?.data?.data?.size);
        setTotalElements(resp?.data?.data?.totalElements);
        setIsLoading(false);
    }

    const onChange = (page, pageSize=20) => {
        setIsLoading(true);
        getRouterListAction(selectedTab,page-1, pageSize, searchInput).then(resp => {
            callAfterApi(resp);
        });
    };

    const items = [
        {
            key: 'routerConfig',
            label: `Router Config`,
        },
        {
            key: 'routerOpsCheck',
            label: `Router Operational Test`,
        },
    ];

    const onRouterClick = (item) => {
        navigate(`/logs/${selectedTab}/${item.sn}`)
    }

    const onSearchInputChange = (e) => {
       setSearchInput(e.target.value);

        getRouterListAction(selectedTab, 0, limit, e.target.value).then(resp => {
            callAfterApi(resp);
        });
    }

    const onTabChange = async (key) => {
        setIsLoading(true);
        getRouterListAction(key,0, limit, "").then(resp => {
            callAfterApi(resp);
        });

        await setSelectedTab(key)
    };

    useEffect(() => {
        setIsLoading(true);
        getRouterListAction('routerConfig',offset, limit, searchInput).then(resp => {
            callAfterApi(resp);
        });
    }, [])

    return (
        <div className="">
            {
                isLoading ? <Loader/> : null
            }
            <Tabs defaultActiveKey="ACTIVE" items={items} onChange={onTabChange}/>
            <div className="logs-setup-top">
                <Input size="large" placeholder="Search by Router Serial Number or UPID" className="search-box" value={searchInput}
                       data-testid="search-input"
                       prefix={<SearchOutlined/>} onChange={onSearchInputChange} allowClear={true}/>
            </div>
            <div className="file-tile-wrapper">
                {
                    listData && listData.length ? listData.map((item, index) => {
                        return <div key ={index} className={"file-tile"} onClick={ () => onRouterClick(item)}>
                            <div className="file-tile-icon"><img src={routerIcon} alt={"csv"}/> {item.sn}</div>
                            <div className="router-details">
                               {/* {
                                    item.modifiedDate ?  <span className="router-date"> {moment(item.modifiedDate).format('DD-MM-YYYY')}</span> : null
                                }*/}
                                <span>{item.upId}</span>
                               {/* <span className={`router-status ${item.status.toLowerCase()}`}>{ROUTER_STATUS[item.status.toLowerCase()]}</span>*/}
                            </div>
                        </div>
                    }) : <div className="file-empty-data"><div>No Data Available</div></div>
                }
            </div>
            {
                listData && listData.length ?  <div className="pagination-wrapper">
                    <Pagination current={current+1} onChange={onChange} total={totalElements} pageSize={limit}/>
                </div> : null
            }
        </div>
    )
}
export default RouterLogs;
