import React, {useEffect, useState} from 'react';
import '../style.scss';
import {Button, Form, Modal, Select} from 'antd';
import {
    getOperationalDataAction,
    getRouterOperationalCheckAction
} from "../../../service-contracts/dashboard/dashboard-actions";
import {Option} from "antd/es/mentions";

const RouterDisplayInfo = ({routerData, setEntries, setLoader, pageUrl, tenantList,setShowEntries,setShowInnerLoader,setExecData,setExpand, err}) => {
    const [modal, contextHolder] = Modal.useModal();
    const [tenant, setTenant] = useState(null);
    window.globalVar = true;

    const operationalDataCall = async (id) => {

        try {
            setShowInnerLoader(true);
            if (!window.globalVar) {
                // Stop the function if globalVar is set to false
                console.log('Stopping further API calls');
                return;
            }
            await getOperationalDataAction(id).then( async resp => {
                if (resp.data.data.message === "Failure") {
                    setTimeout(() => {
                        operationalDataCall(id);
                    }, 5000)
                    if(resp.data.data.entries && resp.data.data.entries.length){
                        setEntries(resp.data.data.entries);
                        setExecData(resp.data.data)
                    }
                } else {
                    setEntries(resp.data.data.entries);
                    setExecData(resp.data.data);
                    setShowInnerLoader(false)
                }
            });
        } catch (error) {
            console.log(`Invalid Data`);
            setShowInnerLoader(false);
        } finally {
            //setLoader(false)
        }
    }

    const callOkHandler = async () => {
        let payload;
        if(pageUrl === "routerOperationalTest" ){
            payload = {
                upid: routerData && routerData.UPID ? routerData.UPID : "",
                routerSerialNumber: routerData && routerData.routerSerialNo ? routerData.routerSerialNo :"",
                deviceTypeId: routerData && routerData.deviceTypeId ? routerData.deviceTypeId :"",
            }
        }else{
            payload = {
                venueId: routerData && routerData.UPID ? routerData.UPID : "",
                routerSerialNumber: routerData && routerData.routerSerialNo ? routerData.routerSerialNo :"",
                tenant: routerData && routerData.tenant ? routerData.tenant : tenant,
            }
        }
        try {
            setLoader(true);
            setEntries([]);
            let resp = await getRouterOperationalCheckAction(payload, pageUrl);
            if (resp && resp.data && resp.data.status) {
                setLoader(false);
                setShowEntries(true);
                setExpand(false);
                operationalDataCall(resp.data.data);
            }
            else{
            }
        } catch (error) {
            console.log(`Invalid`);
            setLoader(false);
        } finally {
            setLoader(false);
            //setLoader(false);
        }
    }

    const config = {
        title: `Run ${pageUrl === "routerOperationalTest" ? 'Operational Test' : 'Config'} on this device?`,
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        wrapClassName: "confirm-modal-wrap",
        onOk: () => {
            callOkHandler()
        },
    };

    const OnConfirmClick = () => {
        modal.confirm(config);
    }

    const checkDisable = () => {
        let disable;
        if(routerData && routerData.tenant){
           disable=false;
        }
        else if(tenant){
            disable=false;
        }
        else disable = true;
        return pageUrl === "routerOperationalTest" ? !(routerData && routerData.UPID) : disable;
    }

    useEffect( () => {
        return async () => {
            if(!window.location.href.includes('routerConfig') && !window.location.href.includes('routerOperationalTest')){
                window.globalVar = false;
            }
        };
    }, []);

    return (
        <>
            {
                <div className="router-info-box" data-testid={'router-display-info'}>
                    <div>
                        <h2>Router Information</h2>
                        <div className="router-info">
                            <span>Site Name:</span>{routerData && routerData.siteName ? routerData.siteName : "NA"}</div>
                        <div className="router-info">
                            <span>Site Address:</span>{routerData && routerData.siteAddress ? routerData.siteAddress : "NA"}
                        </div>
                        <div className="router-info">
                            <span>UPID:</span>{routerData && routerData.UPID ? routerData.UPID : "NA"}</div>
                        <div className="router-info">
                            <span>Router Serial Number:</span>{routerData && routerData.routerSerialNo ? routerData.routerSerialNo : "NA"}
                        </div>
                        <div className="router-info">
                            <span>FNN:</span>{routerData && routerData.fnn ? routerData.fnn : "NA"}</div>
                        <div className="router-info">
                            <span>NTD serial number:</span>{routerData && routerData.ntdserialNo ? routerData.ntdserialNo : "NA"}
                        </div>
                        <div className="router-info router-info-flex">
                            <span>Tenant:</span>{routerData && routerData.tenant ? routerData.tenant :
                            pageUrl === "routerOperationalTest" ? 'NA' :
                            <Form.Item
                                className="select-dropdown-field height-40"
                                name={`tenant`}
                                rules={[
                                    {
                                        required: pageUrl !== "routerOperationalTest",
                                        message: 'Select Tenant',
                                    },
                                ]}
                            >
                                <Select
                                    showArrow={false}
                                    popupClassName="select-dropdown-menu"
                                    placeholder="Select Tenant"
                                    disabled={tenantList && !tenantList.length}
                                    onChange={(val) => setTenant(val)}
                                >
                                    {
                                        tenantList && tenantList.length && tenantList.map((item, index) => {
                                            return <Option value={item} key={item}>{item}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                        </div>
                    </div>
                    <div className="confirm-btn-box">
                        <Button type="primary" htmlType="submit"
                                className="primary-tab-btn"
                                disabled={checkDisable()}
                                onClick={OnConfirmClick}>Confirm</Button>
                    </div>
                </div>
            }
            <div>{contextHolder}</div>
            {contextHolder}
        </>
    )
}
export default RouterDisplayInfo;
