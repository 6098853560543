import {
    getCableRemediationSummary,
    getMonthlyCutoverVsTarget,
    getMonthlyTargetList,
    getSiteFaultSummary,
    getSitesCountPerStatus,
    getSitesFaultList,
    getSitesSchedulesList,
    getSitesScheduleSummary,
    getSiteStatusList,
    getVendorList,
    getWeeklySiteDataVsActual,
    getWeeklyTargetList,
    setTargetData,
    createModifyInspectionSchedule,
    getCableRemediationList,
    getSafetyCultureUserList,
    getSitePause,
    getCableRemediationHistoryList,
    getSitesFaultHistoryList,
    getRouterDeviceDetails,
    getRouterOperationalCheck,
    getOperationalData,
    approveInspection, submitInspection, getSitesFilter, setFaultOwner, getSitesScheduleHistoryList,
    getSitesStatusSummary, setNote, getExecutionInfo,
    getTenant, getRouterList, getRouterJobList, getRouterJobOutputList, deleteSite,
    ExecuteRouterOpertionalTest,
    getRouterOperationalData, simCardActivation, revertInspectionStatus
} from "./dashboard-services";
import {error as errorToast} from "../../utils/toast-utils";

export const getSitesCountPerStatusAction = async (appendQuery) => {
    try {
        const response = await getSitesCountPerStatus(appendQuery);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getMonthlyCutoverVsTargetAction = async (payload) => {
    try {
        const response = await getMonthlyCutoverVsTarget(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getWeeklySiteDataVsActualAction = async (payload) => {
    try {
        const response = await getWeeklySiteDataVsActual(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getSiteFaultSummaryAction = async (appendQuery) => {
    try {
        const response = await getSiteFaultSummary(appendQuery);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getSitesScheduleSummaryAction = async (appendQuery) => {
    try {
        const response = await getSitesScheduleSummary(appendQuery);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getSitesStatusSummaryAction = async (appendQuery) => {
    try {
        const response = await getSitesStatusSummary(appendQuery);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getCableRemediationSummaryAction = async (appendQuery) => {
    try {
        const response = await getCableRemediationSummary(appendQuery);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getVendorListAction = async (payload) => {
    try {
        const response = await getVendorList(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getSiteStatusListAction = async (payload) => {
    try {
        const response = await getSiteStatusList(payload);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getMonthlyTargetListAction = async (pageNo, pageSize) => {
    try {
        const response = await getMonthlyTargetList(pageNo, pageSize);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const getWeeklyTargetListAction = async (pageNo, pageSize) => {
    try {
        const response = await getWeeklyTargetList(pageNo, pageSize);
        return response;
    } catch (error) {
        errorToast("Incorrect Values");
    }
};

export const setTargetDataAction = async (payload, title) => {
    try {
        const response = await setTargetData(payload, title);
        return response;
    } catch (error) {
        return error;
        //errorToast("Error Occurred");
    }
};

export const getSitesFaultListAction = async (payload) => {
    try{
        const response = await getSitesFaultList(payload);
        //return temp_data;
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSitesFaultHistoryListAction = async (payload) => {
    try{
        const response = await getSitesFaultHistoryList(payload);
        //return temp_data;
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSitesScheduleHistoryListAction = async (payload) => {
    try{
        const response = await getSitesScheduleHistoryList(payload);
        //return temp_data;
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSitesSchedulesListAction = async (payload) => {
    try{
        const response = await getSitesSchedulesList(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const createModifyInspectionScheduleAction = async (payload) => {
    try{
        const response = await createModifyInspectionSchedule(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const revertInspectionStatusAction = async (payload) => {
    try{
        const response = await revertInspectionStatus(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSafetyCultureUserListAction = async (vendor) => {
    try{
        const response = await getSafetyCultureUserList(vendor);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getCableRemediationListAction = async (payload) => {
    try{
        const response = await getCableRemediationList(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getCableRemediationHistoryListAction = async (payload) => {
    try{
        const response = await getCableRemediationHistoryList(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSitePauseAction = async (siteId, isPause) => {
    try{
        const response = await getSitePause(siteId, isPause);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRouterDeviceDetailsAction = async (payload) => {
    try{
        const response = await getRouterDeviceDetails(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getTenantAction = async (payload) => {
    try{
        const response = await getTenant(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRouterOperationalCheckAction = async (payload, pageUrl) => {
    try{
        const response = await getRouterOperationalCheck(payload, pageUrl);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getOperationalDataAction = async (id) => {
    try{
        const response = await getOperationalData(id);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getExecutionInfoAction = async (url) => {
    try{
        const response = await getExecutionInfo(url);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const approveInspectionAction = async (payload) => {
    try{
        const response = await approveInspection(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const submitInspectionAction = async (payload) => {
    try{
        const response = await submitInspection(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getSitesFilterAction = async (page) => {
    try{
        const response = await getSitesFilter(page);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const setFaultOwnerAction = async (siteId, payload) => {
    try{
        const response = await setFaultOwner(siteId, payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const setNoteAction = async (siteId, payload) => {
    try{
        const response = await setNote(siteId, payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRouterListAction = async (type,offset,limit,searchTag) => {
    try{
        const response = await getRouterList(type,offset,limit,searchTag);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRouterJobListAction = async (type,sn) => {
    try{
        const response = await getRouterJobList(type,sn);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const getRouterJobOutputListAction = async (id) => {
    try{
        const response = await getRouterJobOutputList(id);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};

export const deleteSiteAction = async (id) => {
    try{
        const response = await deleteSite(id);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};


export const ExecuteRouterOpertionalAction = async (data) =>{
    try{
        let payload = {}
        if (data.routerSerialNumber){
             payload = {
                serialNo:data.routerSerialNumber
            }
        }
        else{
            payload = {
                systemIp:data.SystemIP
            }
        }
        let response = await ExecuteRouterOpertionalTest(payload)
        if (response?.status === 200) {
            return response?.data
        }
        errorToast(response?.data?.errors[0]?.message || "Error Occurred");
        return false
    }
    catch (error) {
        errorToast("Error Occurred");
    }
}

export const getRouterOperationalDataAction = async (jobId) =>{
    try{
        let response = await getRouterOperationalData(jobId)
        if (response?.status === 200) {
            return response
        }
        errorToast("Error Occurred");
        return false
    }
    catch (error) {
        errorToast("Error Occurred");
    }
}

export const simCardActivationAction = async (payload) => {
    try{
        const response = await simCardActivation(payload);
        return response;
    }
    catch (error) {
        errorToast("Error Occurred");
    }
};
