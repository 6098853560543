import React, {useState} from 'react';
import {Layout, Menu, Dropdown, Row, Button} from 'antd';
import {
    UserOutlined,
} from '@ant-design/icons';
import Sidebar from './Sidebar';
import './style.scss'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getPageHeading} from "./helper";
import Avatar from "antd/es/avatar/avatar";
import {AuthProvider, useAuth} from "../Auth/auth";
import ResponsiveNavigation from "./ResponsiveNavigation";
import {connect} from "react-redux";
import MobileToggle from '../../../assets/images/mobileToggle.png';

const {Header, Content} = Layout;

const MainMenuLayout = (props) => {
    const {children, isDesktopView} = props;
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const email = auth?.user?.email;
    const name = auth?.user?.fname + " " + auth?.user?.lname

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const logout = () => {
        auth.logout();
        navigate("/");
    };

    const profileMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/change-password">Change Password</Link>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => logout()} href="javascript:void(0)">
                    Logout
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Layout>
                <Sidebar/>
                <Layout className="site-layout">
                    <Header className="site-layout-header-background" style={{padding: 0}}>
                        <Row className={"site-header"}>
                            {
                                !isDesktopView ? /*<Button
                                    type="text"
                                    onClick={showDrawer}
                                    data-testid="toggle-button"
                                    style={{
                                        fontSize: '16px',
                                        width: 64,
                                        height: 64,
                                    }}
                                />*/ <img onClick={showDrawer}
                                        data-testid="toggle-button"
                                        style={{
                                            fontSize: '16px',
                                            width: 32,
                                            height: 32,
                                            marginLeft: 10,
                                        }}
                                        src={MobileToggle} alt={Menu}/>: null
                            }
                            <h2 className="page-heading" data-testid={"page-header"}>{getPageHeading(location.pathname)}</h2>
                            <div className="right-part align-end">
                                <Dropdown
                                    overlay={profileMenu}
                                    overlayClassName="header-popover"
                                    placement="bottomLeft"
                                    data-testid="profile-icon"
                                >
                                    <div className="username">
                                        <div className="profile" data-testid="profile-icon">
                                            <Avatar icon={<UserOutlined/>}/>
                                        </div>
                                        <div className="profile-name">
                                            <span className='name'>Hi! {name}</span>
                                            <span className='email'>{email}</span>
                                        
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                           // padding: 25,
                            minHeight: 280,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
            <ResponsiveNavigation open={open} onClose={onClose}/>
        </>
    );
};

const mapStateToProps = (state) => ({
    isDesktopView: state.commonReducer.isDesktopView,
});

export default connect(
    mapStateToProps,
    null,
)(MainMenuLayout);
