import * as React from 'react';
import {ResponsiveBar} from "@nivo/bar";

const BarChart = ({data}) => {
   // const customToolTipRef = useRef(null);
    const keys = ["TELSTRA","ALLIANCE","MAXTECH"];
    const commonProps = {
        margin: { top: 40, right: 40, bottom: 60, left: 80 }
    };

    return <div className="graph-box" data-testid="bar-chart">
        <ResponsiveBar
            {...commonProps}
            data={data}
            indexBy="week"
            keys={keys}
            padding={0.3}
           // innerPadding={5}
           // borderRadius={20}
            enableGridY={false}
            enableLabel={false}
            colors={['#86ACF8', '#225DAF', '#B8C2D6']}
            /*barComponent={(barProps) => {
                const barItemData = {
                    x: barProps.bar.x,
                    y: barProps.bar.y,
                    width: barProps.bar.width,
                    height: barProps.bar.height,
                    data: barProps.bar.data
                };
                return (
                    <CustomBarComponent
                        barItemData={barItemData}
                        tooltipRef={customToolTipRef}
                    />
                );
            }}*/
            groupMode="grouped"
            axisLeft={{
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legend: 'No.Of Sites',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            axisBottom={{
                tickSize: 0,
                tickPadding: 10
            }}
            //gridYValues={[0, 3, 6, 9, 12, 15, 18, 21]}
           // enableLabel={true}
            theme={{ legends: { text: { fontSize: 10, fontFamily : "InterMedium", } }, tooltip:{ fontSize:12 } }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 10,
                    translateY: 70,
                    itemsSpacing: 20,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 40,
                    // itemOpacity: 0.75,
                    itemTextColor: '#323E59',
                    symbolSize: 12,
                    symbolShape: 'square',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
       {/* <div className="custom_tooltip" ref={customToolTipRef}></div>*/}
    </div>
};

export default BarChart;
