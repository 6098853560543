import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import React from "react";
import {ROLES} from "../../constants";

export const SITE_UPLOAD_CASE = {
    "SITE_BEFORE_RNT": "Case B - TAB(SDWAN), TLC -> SDWAN",
    "NORMAL": "NA",
    "SITE_ON_MPLS": "Case A - TAB(MPLS), TLC -> SDWAN",
};

export const setColumnForBulkUploadList = (OnConfirmClick, screenWidth, editHandler, navigate, userRole,shouldRedirectToSiteSchedule) => {
    return [
        {
            title: () => <div className="mandatory-title">UPID<span>*</span></div>,
            //dataIndex: 'upid',
            render: (record) => record.published && shouldRedirectToSiteSchedule ? <div className="upid-link" onClick={() => {
                navigate('/siteSchedule', {
                    state: {
                        "upid": record?.upid
                    }
                })
            }}>{record.upid}</div> : `${record.upid}`,
            //sorter: true,
            width: '5%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            editable: true,
        },
        {
            title: 'SITE NAME',
            dataIndex: 'siteName',
            width: '5%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            editable: true
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor',
            width: '2%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            editable: true
        },
        {
            title: 'RELATED UPID',
            dataIndex: 'relatedUpid',
            width: '3%',
            //sorter: true,
            //render: (record) => shouldRedirectToNetbox ? <a href={record?.netboxUrl || "#"} target="_blank">{record.upid}</a> : `${record.upid}`,
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            editable: true,
        },
        {
            title: 'NBN Tech',
            dataIndex: 'nbnTechnology',
            width: '3%',
            editable: true,
            fixed: screenWidth < 767 ? '' : 'left',
        },
        {
            title: 'Circuit Id',
            dataIndex: 'circuitId',
            width: '3%',
            editable: true
        },
        {
            title: 'NTD Serial Or MAC',
            dataIndex: 'ntdSerialOrMac',
            render: (text, record) => record.ntdSerialOrMac || record.ntdSerialNumber || record.macAddress,
            width: '5%',
            editable: true
        },
        {
            title: 'Port Or Additional Comment',
            dataIndex: 'portOrAdditionalComment',
            render: (text, record) => record.portOrAdditionalComment || record.port || record.additionalComment,
            width: '5%',
            editable: true
        },
        {
            title: 'TBB Complete',
            dataIndex: 'tbbInstallationComplete',
            width: '2%',
            editable: true
        },
        {
            title: 'Site Type',
            dataIndex: 'siteType',
            width: '3%',
            editable: true
        },
        {
            title: 'Site Case',
            dataIndex: 'siteUploadCase',
            render: (record) => `${SITE_UPLOAD_CASE[record] || ""}`,
            width: '12%',
            editable: true
        },
        {
            title: '3G',
            dataIndex: 'threeG',
            width: '2%',
            editable: true
        },
        {
            title: 'Status',
            dataIndex: 'reason',
            width: '8%',
            fixed: screenWidth < 767 ? '' : 'right',
            // editable: true
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '1%',
            align: 'center',
            fixed: screenWidth < 767 ? '' : 'right',
            render: (_, record) => {
                return <>
                    <EditOutlined className={ !record.isDeleted && record.editDeleteFlag ? "" : "disable-icon"} data-testid={"edit-button"}
                                  style={{
                                      marginRight: 8,
                                  }} onClick={() => !record.isDeleted && record.editDeleteFlag ? editHandler(record) : null}/>
                    {
                        [ROLES.SUPER_ADMIN, ROLES.DELETE_SITE].some(item => userRole.includes(item)) ?  <DeleteOutlined className={ record.isDeleted ? "disable-icon" : ""}
                                                                                                                              onClick={() => !record.isDeleted ? OnConfirmClick(record) : null}/> : null
                    }
                </>
            },
        }
    ]
}
