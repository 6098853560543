import React, {useEffect, useState} from 'react';
import '../style.scss';
import Loader from "../../../Notifications/Loader";
import moment from "moment";
import {useParams} from "react-router-dom";
import {Spin, Tabs} from "antd";
import {
    getOperationalDataAction,
    getRouterJobListAction,
    getRouterOperationalDataAction
} from "../../../../service-contracts/dashboard/dashboard-actions";
import {ROUTER_STATUS} from "../../../../constants";
import AppBreadcrumb from "../../../CommonComponents/Breadcrumb";
import {logsBreadcrumb} from "../../../CommonComponents/Breadcrumb/breadcrumbsConstants";
import {LoadingOutlined} from "@ant-design/icons";

const RouterLogsListing = (props) => {
    const params = useParams();
    window.globalVar = true;
    const [listData, setListData] = useState();
    //const [selectedTab, setSelectedTab] = useState('');
    const [entries, setEntries] = useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [execData, setExecData] = useState(null);
    const [showEntries, setShowEntries] = useState(false);
    const [showInnerLoader, setShowInnerLoader] = useState(false);
    const [expand, setExpand] = useState(false)

    const items = [
        {
            key: 'routerConfig',
            label: `Router Config`,
            disabled:true
            //children: <div>Config</div>,
        },
        {
            key: 'routerOpsCheck',
            label: `Router Operational Test`,
            disabled:true
            //children: <div>operational</div>,
        },
    ];

    const operationalDataCall = async (id) => {
        try {
            setShowInnerLoader(true);
            await getOperationalDataAction(id).then( async resp => {
                if (resp.data.data.message === "Failure") {
                    if (!window.globalVar) {
                        // Stop the function if globalVar is set to false
                        console.log('Stopping further API calls');
                        return;
                    }
                    setTimeout(() => {
                        operationalDataCall(id);
                    }, 5000)
                    if(resp.data.data.entries && resp.data.data.entries.length){
                        setEntries(resp.data.data.entries);
                        setExecData(resp.data.data);
                    }
                } else {
                    setEntries(resp.data.data.entries);
                    setExecData(resp.data.data);
                    setShowInnerLoader(false)
                }
            });
        } catch (error) {
            console.log(`Invalid Data`);
            //setLoader(false);
            setShowInnerLoader(false);
        } finally {
            //setLoader(false)
        }
    }

    const routerOpsCheckDataCall = async (id) => {
        try {
            setShowInnerLoader(true);
            await getRouterOperationalDataAction(id).then( async resp => {
                    setEntries(resp.data.data.entries);
                    setExecData(resp.data.data);
                    setShowInnerLoader(false)
            });
        } catch (error) {
            console.log(`Invalid Data`);
            setShowInnerLoader(false);
        } finally {
            //setLoader(false)
        }
    }
    const onJobClick = (item) => {
        setEntries([]);
        setShowEntries(true);
        if (params.type == "routerOpsCheck") {
            routerOpsCheckDataCall(item.sn)
        }
        else{
            operationalDataCall(item.sn)
        }
    }

   /* const onTabChange = async (key) => {
        setIsLoading(true);
        getRouterListAction(key).then(resp => {
            setListData(resp?.data?.data);
            setIsLoading(false);
        });
        await setSelectedTab(key)
    };*/

    useEffect(() => {
        setIsLoading(true);
        getRouterJobListAction(params.type,params.id).then(resp => {
            setListData(resp?.data?.data);
            setIsLoading(false);
        });
    }, [])

    useEffect( () => {
        return async () => {
            if(!window.location.href.includes('routerConfig') && !window.location.href.includes('routerOperationalTest')){
                window.globalVar = false;
            }
        };
    }, []);

    return (
        <div className="">
            {
                isLoading ? <Loader/> : null
            }
            <Tabs defaultActiveKey={params.type} items={items}/>
            <div className="logs-setup-top">
                <AppBreadcrumb routes={logsBreadcrumb(params.id)} />
            </div>
            <div className="file-tile-wrapper">
                {
                    listData && listData.length && listData.map((item, index) => {
                        return <div key ={index} className={"file-tile"} onClick={ () => onJobClick(item)}>
                            <div className="file-tile-icon">{/*<img src={routerIcon} alt={"csv"}/>*/} {item.sn}</div>
                            <div className="router-details">
                                {
                                    item.modifiedDate ?  <span className="router-date"> {moment(item.modifiedDate).format('DD-MM-YYYY | HH:mm')}</span> : null
                                }
                                <span className={`router-status ${item.status.toLowerCase()}`}>{ROUTER_STATUS[item.status.toLowerCase()]}</span>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="entries-box">
                {
                    showEntries ? <>
                        <div className={"entries-heading"}>Entries</div>
                        {
                            <div className={"entries-info-wrapper"}>
                                <div>Status: <span className={`${execData && execData.execState} status-color`}>{execData && execData.execState || "Running"}</span></div>
                                {
                                    execData?.router4GSignal ?  <div>Router 4G Signal: <span>{execData.router4GSignal}</span></div> : null
                                }
                                { (params.type == "routerOpsCheck") ?
                                    <>
                                        {
                                            execData?.routerInfo?.upId ?  <div>UPID: <span>{execData?.routerInfo?.upId}</span></div> : null
                                        }
                                        {
                                            execData?.routerInfo?.router4GSignal ?  <div>Router 4G Signal: <span>{execData.routerInfo.router4GSignal}</span></div> : null
                                        }
                                        {
                                            execData?.routerInfo?.simStatus ?  <div>Jasper: <span>{execData?.routerInfo?.simStatus}</span></div> : null
                                        }
                                        {
                                            execData?.routerInfo?.interfaceStatus && execData?.routerInfo?.interfaceStatus == "Primary" ? <div>Router Active On: <span>NBN</span></div> :  null
                                        }
                                        {
                                            execData?.routerInfo?.interfaceStatus && execData?.routerInfo?.interfaceStatus == "Cellular" ? <div>Router Active On: <span>Backup</span></div> :  null
                                        }
                                        {
                                            execData?.routerInfo?.iccId ? <div>Router Sim Registered: <span>{execData?.routerInfo?.iccId}</span></div> : null
                                        }
                                    </>
                                 :
                                    <>
                                        {
                                            execData?.upId ?  <div>UPID: <span>{execData?.upId}</span></div> : null
                                        }
                                        {
                                            execData?.router4GSignal ?  <div>Router 4G Signal: <span>{execData.router4GSignal}</span></div> : null
                                        }
                                        {
                                            execData?.jasper ?  <div>Jasper: <span>{execData.jasper}</span></div> : null
                                        }
                                        {
                                            execData?.routerActiveOn ?  <div>Router Active On: <span>{execData.routerActiveOn}</span></div> : null
                                        }
                                        {
                                            execData?.routerSimRegistered ?  <div>Router Sim Registered: <span>{execData.routerSimRegistered}</span></div> : null
                                        }
                                    </>
                                }
                            </div>
                        }
                        <div className="logs-display" onClick={()=>setExpand(!expand)}><span>Logs</span>{(!expand)?<span>+</span>:<span>-</span>}</div>
                        {
                            expand ?
                            <div className={"entries-wrapper"}>
                                {
                                    entries && entries.length ? entries.map((item, index) => {
                                        return <div key={index}><span>Log# {index+1} ~</span>{item?.log}</div>
                                    }) : null
                                }
                                {
                                    showInnerLoader ?  <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 22,
                                                }}
                                                spin
                                            />
                                        }
                                    /> : null
                                }
                            </div>: null
                        }
                    </> : null
                }
            </div>
        </div>
    )
}
export default RouterLogsListing;
