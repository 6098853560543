import config from '../../../config';
import {httpAuth} from "../../utils/http-utility";

export const CreateSite = async (payload) => {
    try {
        const apiResponse = await httpAuth.post(`${config.API_ROOT}/site/create`, payload);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getRegionId = async (region) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/region/name?name=${region}`);
        if (apiResponse?.status === 200) {
            return apiResponse?.data;
        }
        return false
    } catch (error) {
        return error;
    }
};