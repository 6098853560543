import config from '../../../config';
import {httpAuth} from "../../utils/http-utility";

export const scanMultipleSites = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/publish/sitesByStatus`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getModifiedInspection = async (minutes=3) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/modifiedInspection?minutes=${minutes}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const createInspection = async (id) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/createInspection?netboxSiteId=${id}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const sendPublishVendorReport = async (minutes=5) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/publishVendorReport`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const sendPublishManualVendorReport = async (startTime, endTime) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/publishManualVendorReport?startTime=${startTime}&endTime=${endTime}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const publishManualReportForBusinessUnit = async (payload) => {
    const apiResponse = await httpAuth.post(
        `${config.API_ROOT}/publishManualReportForBusinessUnit`,
        payload,
    );
    return apiResponse;
};

export const exportSitesData = async (startTime, endTime) => {
    let url;
    if(startTime && !endTime){
        url = `exportSiteData?startTime=${startTime}`
    }
    else if(endTime && !startTime){
        url= `exportSiteData?endTime=${endTime}`
    }
    else if(endTime && startTime){
        url= `exportSiteData?startTime=${startTime}&endTime=${endTime}`
    }
    else{
        url= `exportSiteData`
    }
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/${url}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const downloadTemplate = async (type) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/downloadCsvTemplate?templateType=${type}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const updateEmail = async (payload) => {
    const response = await httpAuth.post(
        `${config.API_ROOT}/updateEmail`,
        payload,
    );
    return response;
};

export const showEmail = async () => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/showEmail`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const showEmailByVendor = async (vendor) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/showEmail/${vendor}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getBatchUploadsData = async (data) => {
    try {
        const apiResponse = await httpAuth.post(`${config.API_ROOT}/getBatchUploads`,data);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getBatchConvertToCsv = async (formData) => {
    try {
        const apiResponse = await httpAuth.post(`${config.API_ROOT}/convertIntoBatchUploadFile`, formData);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const getCSVSiteDataList = async (payload) => {
    const getCSVSiteDataListApiResponse = await httpAuth.post(
        `${config.API_ROOT}/getCSVSiteData`,
        payload,
    );

    return getCSVSiteDataListApiResponse;
};

export const saveSiteForProcess = async (payload) => {
    const saveSiteForProcessApiResponse = await httpAuth.post(
        `${config.API_ROOT}/saveSiteForProcess`,
        payload,
    );

    return saveSiteForProcessApiResponse;
};

export const pushSiteForProcess = async (payload) => {
    const pushSiteForProcessApiResponse = await httpAuth.post(
        `${config.API_ROOT}/pushSiteForProcess`,
        payload,
    );

    return pushSiteForProcessApiResponse;
};

export const getDropdownsValuesBulkUpload = async (type) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/getDropdownsValuesBulkUpload/${type}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};

export const createNewBatchFile = async (fileName) => {
    try {
        const apiResponse = await httpAuth.get(`${config.API_ROOT}/createNewBatch?fileName=${fileName}`);
        return apiResponse;
    } catch (error) {
        return error;
    }
};



