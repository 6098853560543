import {EditOutlined} from "@ant-design/icons";
import {Switch} from "antd";

export const setColumnForUserList = (setModalAction, setIsModalVisible,editHandler,onUserDisableToggle) => {
    return [
        {
            title: 'Name',
            //dataIndex: 'name',
            //sorter: true,
           // defaultSortOrder: 'descend',
            render: (record) => `${record.firstname} ${record.lastname ? record.lastname : ''}`,
            width: '30%',
        },
        {
            title: 'Role',
            //dataIndex: 'role',
            render: (record) => {
                return <div>{
                    record.role && record.role.length ? record.role.map( item => {
                        return <div>{item}</div>
                    }) : null
                }</div>
            },
            width: '30%',
        },
        {
            title: 'Email Id',
            dataIndex: 'email',
            width: '30%',
        },
        {
            title: 'Disable User',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div>
                        {rowData?.map((item, index) => (
                            <div key={index}>{
                                <Switch defaultChecked={item?.disabled} disabled={item?.self}
                                        onClick={(e) => {onUserDisableToggle(e, item)}}/>
                            }
                            </div>
                        ))}
                    </div>
                );
            },
            width: '15%',
        },
        {
            title: 'Edit',
            //dataIndex: '',
            //sorter: true,
            render: (record) => <EditOutlined data-testid={"edit-button"} onClick={() => {
               // setIsModalVisible(true);
                setModalAction("edit");
                editHandler(record);
            }
            }/>,
            width: '10%',
        },
    ];
}
