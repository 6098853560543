import right from '../../../assets/images/right.png';
import wrong from '../../../assets/images/wrong.png';
import cableRemediation from '../../../assets/images/cableRemediationImg.png';
import fault from '../../../assets/images/faultImg.png';
import inspectionImg from '../../../assets/images/link.png';
import infoImg from '../../../assets/images/info.png';
import {EditOutlined} from '@ant-design/icons';
import moment from "moment";
import {Switch, Tooltip} from "antd";
import React from "react";
import {ROLES} from "../../constants";

const showScheduleBtn = (record, item2) => {
    return item2.siteInstallAuditId && item2.tbbInstalled;
}

const disableScheduleBtn = (record, userRole, checkCondition) => {
    const {vendor} = record;
    let roleNotMatch = (userRole.includes(`VENDOR_${vendor}`) ||
        userRole.includes(ROLES.SUPER_ADMIN) || userRole.includes(ROLES.ADMIN));
    return checkCondition || !roleNotMatch;
}

const getColorForDate = (siteDate, workDone) => {
    let _siteDate = new Date( moment(siteDate).format('l')).getTime();
    let _currDate = new Date(moment().format('l')).getTime();

    if ((_siteDate < _currDate) && !workDone) {
        return "turn-red"
    } else {
        return ""
    }
}

const getClassForDualCases = (siteStage, isImage) => {
  if(siteStage === "CUTOVER_BEFORE_RNT"){
      //return isImage ? 'img-strike' : 'strikeText';
      return 'disableRow';
  } else if(siteStage === "MPLS"){
      return 'disableRow';
  } else{
      return ''
  }
}

const disableRevertStatusBtn = (userRole) => {
    let roleNotMatch = (userRole.includes(ROLES.REVERT_STATUS) ||
        userRole.includes(ROLES.SUPER_ADMIN) || userRole.includes(ROLES.ADMIN));
    return !roleNotMatch;
}

export const setColumnForUserList = (setIsModalVisible, editHandler, navigate, screenWidth, onCreateClick, onSitePauseToggle, userRole, shouldRedirectToNetbox, isHistoryTabSelected,editStatusRevertHandler) => {
    let colArr =  [
        {
            title: 'UPID',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                            {item.siteStage === "NORMAL" ?  <a href={item.netboxUrl} target="_blank">{item.upid}</a> :
                                <span className={getClassForDualCases(item.siteStage)}>{item.upid}</span>}
                        </div>
                      ))}
                    </div>
                );
            },

            width: '35%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'SITE NAME',
            dataIndex: 'sitename',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                  <div >
                    {rowData.map((item, index) => (
                      <div className={"dual-line"} key={index}>
                        <span className={getClassForDualCases(item.siteStage)}>{item.sitename}</span>
                      </div>
                    ))}
                  </div>
                );
              },
            width: '45%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                          <span className={getClassForDualCases(item.siteStage)}>{item.vendor === null ? 'NA' : item.vendor}</span>
                        </div>
                      ))}
                    </div>
                );
            },
            width: '30%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'ROUTER INSTALLATION VENDOR',
            dataIndex: 'routerInstallVendor',

            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                          <span className={getClassForDualCases(item.siteStage)}>{item.routerInstallVendor === null ? 'NA' : item.routerInstallVendor}</span>
                        </div>
                      ))}
                    </div>
                );
            },

            width: '32%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Business Line',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                            {
                                item?.businessLine?.length === 0 ? 'NA' : item?.businessLine?.map(item2 =>  <div className={getClassForDualCases(item.siteStage)}>{item2}</div>)
                            }
                          {/* <span>{item.routerInstallVendor === null ? 'NA' : item.routerInstallVendor}</span> */}
                        </div>
                      ))}
                    </div>
                );
            },
            width: '35%',
        },
        {
            title: 'TBB INSTALLED',
            dataIndex: 'tbbInstalled',

            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData.map((item, index) => {
                        return item.siteStage !== "MPLS" ? <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => <div className={`${getClassForDualCases(item.siteStage, true)}`}>{item2?.tbbInstalled ? <img src={right} alt="Yes"/> : <img src={wrong} alt="Yes"/>}</div>)
                            }
                        </div> : <div className="min-height"/>
                      })}
                    </div>
                );
            },
            width: '29%',
            align: 'center'
        },
        {
            title: 'SITE INSTALL SCHEDULED',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div>
                      {rowData?.map((item, index) => {
                        return item.siteStage !== "MPLS" ? <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => <div className={"dual-line"}>{item2 && item2.siteInstallSchedule ?
                                    <div className={getColorForDate(item2?.siteInstallSchedule, item2?.siteInstalled)}>
                                        <span className={`${getClassForDualCases(item.siteStage)} schedule-date`}> {moment(item2?.siteInstallSchedule).format('MMM DD, YYYY')}</span>
                                        { disableScheduleBtn(item, userRole, item2?.siteInstalled) ? null : <EditOutlined className="edit-btn" data-testid="schedule-button"
                                                                               onClick={() => onCreateClick(item, item2,"Site Install", "edit")}/> }
                                    </div> : showScheduleBtn(record, item2) ? <button disabled={disableScheduleBtn(item, userRole, item2?.siteInstalled)}
                                                                               onClick={() => onCreateClick(item, item2, "Site Install")}
                                                                               data-testid={"schedule-button"}
                                                                               role="schedule-button"
                                                                               className="create-schedule-btn">Schedule</button> : <span className={`${getClassForDualCases(item.siteStage)}`}>NA</span>}</div>)
                            }

                        </div> :  <div className="min-height"/>
                      })}
                    </div>
                );
            },

            width: '39%',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'SITE INSTALLED',
            dataIndex: 'siteInstalled',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => {

                        return ( item.siteStage !== "MPLS" ? item?.inspectionData?.map(item2 => <div className={"dual-line"} key={index}>
                            <div className={`${getClassForDualCases(item.siteStage, true)}`}>
                                {item2?.siteInstalled ? <img src={right} alt="Yes"/> : <img src={wrong} alt="Yes"/>}</div></div>) :  <div className="min-height"/>
                        )
                      })}
                    </div>
                );
            },
            width: '29%',
            align: 'center'
        },
        {
            title: 'SITE CUTOVER SCHEDULED',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => {
                        return ( item.siteStage !== "MPLS" ? <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => <div className={"dual-line"}>{item2 && item2?.siteCutoverSchedule ?
                                    <div className={getColorForDate(item2?.siteCutoverSchedule, item2?.siteCutover)}>
                                        <span className={`${getClassForDualCases(item.siteStage)} schedule-date`}> {moment(item2?.siteCutoverSchedule).format('MMM DD, YYYY')}</span>
                                        {disableScheduleBtn(item, userRole, item2?.siteCutover) ? null : <EditOutlined className="edit-btn" data-testid="schedule-button"
                                                                               onClick={() => onCreateClick(item, item2,"Site Cutover", "edit")}/>}
                                    </div> : (item2.siteCutoverAuditId) ? <button disabled={disableScheduleBtn(item, userRole, item2?.siteCutover)}
                                                                               data-testid={"schedule-button"}
                                                                               onClick={() => onCreateClick(item, item2, "Site Cutover")}
                                                                               className="create-schedule-btn">Schedule</button> : <span className={`${getClassForDualCases(item.siteStage)}`}>NA</span>}</div>)
                            }

                        </div> :  <div className="min-height"/>)
                      })}
                    </div>
                );
            },
            width: '39%',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'SITE CUTOVER',
            dataIndex: 'siteCutover',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => {
                          return (
                              item.siteStage !== "MPLS" ? <div className={"dual-line"} key={index}>
                                  {
                                      item?.inspectionData?.map(item2 => <div className={`${getClassForDualCases(item.siteStage, true)}`}>{item2?.siteCutover ? <img src={right} alt="Yes"/> : <img src={wrong} alt="Yes"/>}</div>)
                                  }
                              </div> :  <div className="min-height"/>
                          )
                      })}
                    </div>
                );
            },
            width: '27%',
            align: 'center'
        },
        {
            title: 'FAULT',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => <div className={"dual-line"}>{item2?.isFault ? <img src={fault} alt="Yes"className="table-img" onClick={() => {
                                                    navigate('/siteFaults', {
                                                        state: {
                                                            "upid": record?.[index]?.upid
                                                        }
                                                    })
                                                }}/> : null}</div>)
                            }

                        </div>
                      ))}
                    </div>
                );
            },
            width: '22%',
            align: 'center'
        },
        {
            title: 'CABLE REMEDIATION',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => <div className={"dual-line"}>{item2?.isCableInRemediation ?
                                                    <img src={cableRemediation} alt="Yes" className="table-img" onClick={() => {
                                                        navigate('/cableRemediation', {
                                                            state: {
                                                                "upid": record?.[index]?.upid
                                                            }
                                                        })
                                                    }}/> : null}</div>)
                            }

                        </div>
                      ))}
                    </div>
                );
            },
            width: '34%',
            align: 'center'
        },{
            title: '3G',
            dataIndex: 'threeG',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                        {rowData.map((item, index) => (
                            <div className={"dual-line"} key={index}>
                                {
                                    item?.threeG? <img src={right} alt="Yes"/> : <img src={wrong} alt="Yes"/>
                                }
                            </div>
                        ))}
                    </div>
                );
            },
            width: '27%',
            align: 'center'
        },
        {
            title: 'LINKS',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div >
                      {rowData?.map((item, index) => (
                        <div className={"dual-line"} key={index}>
                            {
                                item?.inspectionData?.map(item2 => {
                                    return item.siteStage === "NORMAL" ? <div className={"dual-line"}>{item2?.inspectionLinkUpdated ?
                                        <img src={inspectionImg} alt={"#"} style={{cursor: 'pointer'}}
                                             data-testid={"edit-button"}
                                             onClick={() => {
                                                 editHandler(record, item);
                                             }
                                             }/> : <Tooltip
                                            title="Inspections generation is in progress.Refresh the page and try.">
                                            <img src={infoImg} alt={"#"}
                                                 data-testid={"info-button"}/>
                                        </Tooltip>}</div> :  <div className="min-height"/>
                                })
                            }

                        </div>
                      ))}
                    </div>
                );
            },
            width: '22%',
        },
        {
            title: 'Revert Status',
            render: (text, record) => {
                const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
                return (
                    <div>
                        {rowData?.map((item, index) => {
                            return item.revertStatus && item.revertStatus.length ? <div className={"dual-line"} key={index}>{
                                disableRevertStatusBtn(userRole) ? null : <EditOutlined style={{cursor: 'pointer'}}  onClick={() => {
                                    editStatusRevertHandler(record, item);
                                }
                                }/>
                            }
                            </div> : <div className="min-height"/>
                        })}
                    </div>
                );
            },
            width: '24%',
        },
        // {
        //     title: 'Site Pause',
        //     render: (text, record) => {
        //         const rowData = Array.isArray(record) ? record : [record]; // Make sure it's an array
        //         return (
        //             <div>
        //               {rowData?.map((item, index) => (
        //                 <div className={"dual-line"} key={index}>{
        //                     item.siteStage === "NORMAL" ?  <Switch defaultChecked={item?.paused} data-testid={"site-pause-btn"}
        //                                                            onClick={(e) => onSitePauseToggle(e, item)}/> :  <div className="min-height"/>
        //                 }
        //                 </div>
        //               ))}
        //             </div>
        //         );
        //     },
        //     width: '22%',
        // },
    ];

    let colArrHistory =  [
        {
            title: 'UPID',
            // dataIndex: 'upid',
            render: (record) => shouldRedirectToNetbox ?
                <a href={record.netboxUrl} target="_blank">{record.upid}</a> : `${record.upid}`,
            width: '8%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'SITE NAME',
            dataIndex: 'sitename',
            width: '8%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'ROUTER INSTALLATION VENDOR',
            dataIndex: 'routerInstallVendor',
            width: '5%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Site Logs',
            render: (record) => {
                return <ul className="site-logs">
                    {record?.siteLogs ? record?.siteLogs?.map((item, index) => {
                        return <li key={index}>{moment(item.date).format('DD-MM-YYYY')} -> {item.status}</li>
                    }) : null}
                </ul>
            },
            width: '18%',
        },
        {
            title: 'LINKS',
            //dataIndex: '',
            //sorter: true,
            render: (record) => record?.inspectionLinkUpdated ?
                <img src={inspectionImg} alt={"#"} style={{cursor: 'pointer'}}
                     data-testid={"edit-button"}
                     onClick={() => {
                         editHandler(record);
                     }
                     }/> : <Tooltip title="Inspections generation is in progress.Refresh the page and try.">
                    <img src={infoImg} alt={"#"}
                         data-testid={"info-button"}/>
                </Tooltip>,
            width: '4%',
        },
        ]

    return isHistoryTabSelected ? colArrHistory : colArr;
}
