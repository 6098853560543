import * as React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from './auth';
import {ROLES} from "../../constants";

export const CheckLogin = ({
                                children
                            }) => {
    const auth = useAuth();
    if (auth?.user) {
        let path = auth?.userRole === ROLES.FIELDTECH ? "/routerOperationalTest" : "/dashboard"
        return (
            <Navigate
                to={path}
                data-testid="navigate"
            />
        );
    } else{
        return children;
    }
};
