import { message } from 'antd';

const TOAST_TIME = 3;  // 3 seconds

message.config({
  maxCount: 1
})

export const success = (displayMessage = 'Success', toast_time=TOAST_TIME) => {
  message.success(displayMessage,toast_time);
};

export const error = (displayMessage = "Oh no, something went wrong !", toast_time=TOAST_TIME) => {
  message.error(displayMessage,toast_time);
};

export const warning = (
  displayMessage = "Oh no, something went wrong !", toast_time=TOAST_TIME
) => {
  message.warning(displayMessage,toast_time);
};
