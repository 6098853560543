import {ROLES} from "../constants";

export const getRequiredRoles = (page) => {
    let requiredRoles = [];

    switch (page){
        case "dashboard":
        case "siteSchedule":
        case "siteFaults":
        case "cableRemediation":
            requiredRoles = [ROLES.SUPER_ADMIN,ROLES.ADMIN,ROLES.OPERATIONS, ROLES.VENDOR_MAXTECH, ROLES.VENDOR_ALLIANCE, ROLES.VENDOR_TELSTRA];
            break;
        case "routerConfig":
            requiredRoles = [ROLES.SUPER_ADMIN,ROLES.ADMIN, ROLES.FIELDTECH, ROLES.CUTOVER_ENGINEER, ROLES.VENDOR_MAXTECH];
            break;
        case "routerOperationalTest":
            requiredRoles = [ROLES.SUPER_ADMIN,ROLES.ADMIN, ROLES.FIELDTECH, ROLES.OPERATIONS, ROLES.SDWAN_INSTALLATION_ENGINEER];
            break;
        case "change-password":
            requiredRoles = ["*"];
            break;
        case "networkite":
            requiredRoles = [ROLES.DELETE_SITE, ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.SCAN_INSPECTION, ROLES.DELETE_SITE, ROLES.EXPORT_REPORT];
            break;
        default:
            requiredRoles = [ROLES.SUPER_ADMIN,ROLES.ADMIN]

    }
    return requiredRoles;
}
