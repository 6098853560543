import * as React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import {ApolloClient, InMemoryCache, createHttpLink} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: "https://netbox/graphql/",
    //uri: "https://api.spacex.land/graphql/",
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            //authorization: `Bearer 4fzJg1sBX3PmrBsLkWHh34A6caLMRVN2`,
            "X-CSRFToken": "4fzJg1sBX3PmrBsLkWHh34A6caLMRVN2",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

const Index = () => (
  <Provider store={store}>
          <App />
  </Provider>
);

export { Index };
