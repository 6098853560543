
export const ValidateDigits = (_,UPID) => {
    let regex = /[0-9]{4}\-[0-9]{5}/

    if (!UPID || UPID.length<=0){
        return Promise.reject('Enter UPID')
    }
    else if (!regex.test(UPID)) {
        return Promise.reject('Format must be (XXXX-XXXXX)')
   }
   return Promise.resolve()
}

export const setMask = (e, form) => {
    let upid = e.target.value
    let regex = /[-]{1}/
    if (regex.test(upid)){
        if (upid.replace("-","").length == 4)
        {
            form.setFieldValue('UPID',upid.replace("-","") )
        }
        else{
            form.setFieldValue('UPID', upid)
        }
    }
    else{
        if (upid.length <= 4){
            form.setFieldValue('UPID', upid)
        }
        else{
            let value = upid.replace("-","")
            const maskedUPID = value.slice(0, 4)+"-"+value.slice(4)
            form.setFieldValue('UPID', maskedUPID)
        }
    }
}

export const ValidateIDs = (form) => {
    let WageringID = form.getFieldInstance('WageringID').input.value
    let GamingID = form.getFieldInstance('GamingID').input.value
    let KenoID = form.getFieldInstance('KenoID').input.value
    let LotteriesID = form.getFieldInstance('LotteriesID').input.value
    if (WageringID == "" && GamingID == "" && KenoID == "" && LotteriesID == "")
    {
        return Promise.reject('Atleast one must be filled')
    }
    return Promise.resolve()
}

export const AddressSuburbList = [
    "Abbotsbury, NSW, 2176",
    "Abbotsford, NSW, 2046",
    "Abbotsford, VIC, 3067",
    "Aberdare, NSW, 2325",
    "Aberdare, NSW, 2336",
    "Aberdeen, NSW, 2336",
    "Aberfeldie, VIC, 3040",
    "Aberfoyle Park, SA, 5159",
    "Abermain, NSW, 2326",
    "Acacia Ridge, QLD, 4110",
    "Adaminaby, NSW, 2628",
    "Adaminaby, NSW, 2629",
    "Adamstown, NSW, 2289",
    "Adelaide River, NT, 0846",
    "Adelaide, SA, 5000",
    "Adelong, NSW, 2729",
    "Advancetown, QLD, 4211",
    "Agnes Water, QLD, 4677",
    "Ainslie, ACT, 2602",
    "Aintree, VIC, 3336",
    "Airds, NSW, 2560",
    "Airlie Beach, QLD, 4802",
    "Airport West, VIC, 3042",
    "Aitkenvale, QLD, 4814",
    "Albany Creek, QLD, 4035",
    "Albert Park, VIC, 3206",
    "Alberton, QLD, 4207",
    "Alberton, SA, 5014",
    "Alberton, VIC, 3971",
    "Albion Park Rail, NSW, 2527",
    "Albion Park, NSW, 2527",
    "Albion, QLD, 4010",
    "Albury, NSW, 2640",
    "Alderley, QLD, 4051",
    "Aldgate, SA, 5154",
    "Aldinga Beach, SA, 5173",
    "Aldinga, SA, 5173",
    "Alexandra Headland, QLD, 4572",
    "Alexandra Headlands, QLD, 4572",
    "Alexandra Hills, QLD, 4161",
    "Alexandra, VIC, 3714",
    "Alexandria, NSW, 2015",
    "Algester, QLD, 4115",
    "Alice Springs, NT, 0870",
    "Allambie Heights, NSW, 2100",
    "Allansford, VIC, 3277",
    "Allawah, NSW, 2218",
    "Allenby Gardens, SA, 5009",
    "Allenstown, QLD, 4700",
    "Alligator Creek, QLD, 4816",
    "Allingham, QLD, 4850",
    "Allora, QLD, 4362",
    "Alstonville, NSW, 2477",
    "Altona East, VIC, 3025",
    "Altona Meadows, VIC, 3028",
    "Altona North, VIC, 3025",
    "Altona, VIC, 3018",
    "Alyangula, NT, 0885",
    "Ambarvale, NSW, 2560",
    "Amity Point, QLD, 4183",
    "Andamooka, SA, 5722",
    "Andergrove, QLD, 4740",
    "Andrews Farm, SA, 5114",
    "Angaston, SA, 5353",
    "Angle Park, SA, 5010",
    "Angle Vale, SA, 5117",
    "Anglesea, VIC, 3230",
    "Anna Bay, NSW, 2316",
    "Annandale, NSW, 2038",
    "Annandale, QLD, 4814",
    "Annangrove, NSW, 2156",
    "Annerley, QLD, 4103",
    "Apollo Bay, VIC, 3233",
    "Appin, NSW, 2560",
    "Apple Tree Creek, QLD, 4660",
    "Aramac, QLD, 4726",
    "Arana Hills, QLD, 4054",
    "Ararat, VIC, 3377",
    "Aratula, QLD, 4309",
    "Arcadia, QLD, 4819",
    "Archerfield, QLD, 4077",
    "Ardlethan, NSW, 2665",
    "Ardrossan, SA, 5571",
    "Argenton, NSW, 2284",
    "Ariah Park, NSW, 2665",
    "Armadale, NSW, 3143",
    "Armadale, VIC, 3143",
    "Armidale, NSW, 2350",
    "Armstrong Creek, VIC, 3217",
    "Arncliffe, NSW, 2205",
    "Arndell Park, NSW, 2148",
    "Arno Bay, SA, 5603",
    "Artarmon, NSW, 2064",
    "Arundel, QLD, 4214",
    "Ascot Vale, VIC, 3032",
    "Ascot, QLD, 4007",
    "Ascot, VIC, 3551",
    "Ashburton, VIC, 3147",
    "Ashfield, NSW, 2131",
    "Ashford, NSW, 2361",
    "Ashford, SA, 5035",
    "Ashgrove, QLD, 4060",
    "Ashmont, NSW, 2650",
    "Ashmore, QLD, 4214",
    "Ashwood, VIC, 3147",
    "Aspendale, VIC, 3195",
    "Aspley, QLD, 4034",
    "Asquith, NSW, 2077",
    "Athelstone, SA, 5076",
    "Atherton, QLD, 4883",
    "Athol Park, SA, 5012",
    "Auburn, NSW, 2142",
    "Auburn, NSW, 2144",
    "Auburn, SA, 5451",
    "Auburn, VIC, 3123",
    "Augathella, QLD, 4477",
    "Augustine Heights, QLD, 4300",
    "Austinmer, NSW, 2515",
    "Austral, NSW, 2179",
    "Avalon Beach, NSW, 2107",
    "Avalon, NSW, 2107",
    "Avoca Beach, NSW, 2251",
    "Avoca, QLD, 4670",
    "Avoca, VIC, 3467",
    "Avondale Heights, VIC, 3034",
    "Avondale, QLD, 4670",
    "Ayr, QLD, 4807",
    "Babinda, QLD, 4861",
    "Bacchus Marsh, VIC, 3340",
    "Baffle Creek, QLD, 4674",
    "Bagdad, TAS, 7030",
    "Bairnsdale, VIC, 3875",
    "Bakers Creek, QLD, 4740",
    "Balaclava, VIC, 3183",
    "Balaklava, SA, 5461",
    "Bald Hills, QLD, 4036",
    "Balgowlah Heights, NSW, 2093",
    "Balgowlah, NSW, 2093",
    "Balgownie, NSW, 2519",
    "Balhannah, SA, 5042",
    "Balhannah, SA, 5242",
    "Ballan, VIC, 3342",
    "Ballarat Central, VIC, 3350",
    "Ballarat East, VIC, 3350",
    "Ballarat North, VIC, 3350",
    "Ballarat West, VIC, 3350",
    "Ballarat, VIC, 3350",
    "Ballina, NSW, 2478",
    "Balmain, NSW, 2041",
    "Balmoral, QLD, 4171",
    "Balnarring, VIC, 3926",
    "Balranald, NSW, 2715",
    "Balwyn North, VIC, 3104",
    "Balwyn, VIC, 3103",
    "Bamaga, QLD, 4876",
    "Banana, QLD, 4702",
    "Bangalow, NSW, 2479",
    "Bangor, NSW, 2234",
    "Banksia Beach, QLD, 4507",
    "Banksia, NSW, 2216",
    "Bankstown, NSW, 2200",
    "Bannockburn, QLD, 4207",
    "Bannockburn, VIC, 3331",
    "Banora Point, NSW, 2486",
    "Banyo, QLD, 4014",
    "Baradine, NSW, 2396",
    "Baralaba, QLD, 4702",
    "Barangaroo, NSW, 2000",
    "Barcaldine, QLD, 4725",
    "Bardon, QLD, 4065",
    "Bardwell Park, NSW, 2207",
    "Bardwell Valley, NSW, 2207",
    "Barellan, NSW, 2665",
    "Bargara, QLD, 4670",
    "Bargo, NSW, 2574",
    "Barham, NSW, 2732",
    "Baringa, QLD, 4551",
    "Barmah, VIC, 3639",
    "Barmedman, NSW, 2668",
    "Barmera, SA, 5345",
    "Barnawartha, VIC, 3688",
    "Barooga, NSW, 3644",
    "Barraba, NSW, 2347",
    "Barrack Heights, NSW, 2528",
    "Barwon Heads, VIC, 3227",
    "Bass Hill, NSW, 2197",
    "Bass, VIC, 3991",
    "Batchelor, NT, 0845",
    "Bateau Bay, NSW, 2261",
    "Batehaven, NSW, 2536",
    "Batemans Bay, NSW, 2536",
    "Bathurst, NSW, 2795",
    "Batlow, NSW, 2730",
    "Battery Point, TAS, 7004",
    "Baulkham Hills, NSW, 2153",
    "Bawley Point, NSW, 2539",
    "Baxter, VIC, 3911",
    "Bayswater North, VIC, 3153",
    "Bayswater, VIC, 3153",
    "Beachmere, QLD, 4510",
    "Beachport, SA, 5280",
    "Beaconsfield Upper, VIC, 3808",
    "Beaconsfield, NSW, 2015",
    "Beaconsfield, TAS, 7270",
    "Beaconsfield, VIC, 3807",
    "Beaudesert, QLD, 4285",
    "Beaufort, VIC, 3373",
    "Beaumaris, VIC, 3193",
    "Beaumont Hills, NSW, 2155",
    "Beauty Point, TAS, 7270",
    "Bedord Park, SA, 5042",
    "Beechwood, NSW, 2446",
    "Beechworth, VIC, 3747",
    "Beecroft, NSW, 2119",
    "Beenleigh, QLD, 4207",
    "Beerwah, QLD, 4519",
    "Bega, NSW, 2550",
    "Belair, SA, 5052",
    "Belconnen, ACT, 2617",
    "Belfield, NSW, 2191",
    "Belgian Gardens, QLD, 4810",
    "Belgrave, VIC, 3160",
    "Bell Park, VIC, 3215",
    "Bella Vista, NSW, 2153",
    "Bellamack, NT, 0832",
    "Bellambi, NSW, 2518",
    "Bellara, QLD, 4507",
    "Bellata, NSW, 2397",
    "Bellbird, NSW, 2325",
    "Bellbowrie, QLD, 4070",
    "Bellbrook, NSW, 2440",
    "Bellerive, TAS, 7018",
    "Bellevue Hill, NSW, 2023",
    "Bellingen, NSW, 2454",
    "Bellmere, QLD, 4510",
    "Belmont North, NSW, 2280",
    "Belmont, NSW, 2280",
    "Belmont, QLD, 4153",
    "Belmont, VIC, 3216",
    "Belmore, NSW, 2192",
    "Belrose, NSW, 2085",
    "Benalla, VIC, 3671",
    "Benalla, VIC, 3672",
    "Bendemeer, NSW, 2355",
    "Bendigo, VIC, 3550",
    "Benowa, QLD, 4217",
    "Bentleigh East, VIC, 3165",
    "Bentleigh, VIC, 3204",
    "Bentley Park, QLD, 4869",
    "Berala, NSW, 2141",
    "Beresfield, NSW, 2322",
    "Berkeley Vale, NSW, 2261",
    "Berkeley, NSW, 2506",
    "Berkshire Park, NSW, 2765",
    "Bermagui, NSW, 2546",
    "Berowra Heights, NSW, 2082",
    "Berowra, NSW, 2081",
    "Berri, SA, 5343",
    "Berridale, NSW, 2628",
    "Berriedale, TAS, 7011",
    "Berrigan, NSW, 2712",
    "Berrima, NSW, 2577",
    "Berrimah, NT, 0828",
    "Berry Springs, NT, 0838",
    "Berry, NSW, 2535",
    "Berserker, QLD, 4701",
    "Berwick, VIC, 3806",
    "Bethanga, VIC, 3691",
    "Beverley Park, NSW, 2217",
    "Beverley, SA, 5009",
    "Beverly Hills, NSW, 2209",
    "Beverly Hills, NSW, 2210",
    "Bexley North, NSW, 2207",
    "Bexley, NSW, 2207",
    "Bicheno, TAS, 7215",
    "Bidwill, NSW, 2770",
    "Biggenden, QLD, 4621",
    "Biggera Waters, QLD, 4216",
    "Billinudgel, NSW, 2483",
    "Biloela, QLD, 4715",
    "Binalong, NSW, 2584",
    "Bingara, NSW, 2404",
    "Binnaway, NSW, 2395",
    "Birchip, VIC, 3483",
    "Birdwood, SA, 5234",
    "Birkdale, QLD, 4159",
    "Birkenhead, SA, 5015",
    "Birmingham Gardens, NSW, 2287",
    "Birrong, NSW, 2143",
    "Birtinya, QLD, 4575",
    "Bittern, VIC, 3918",
    "Black Forest, SA, 5035",
    "Black Rock, VIC, 3193",
    "Blackall, QLD, 4472",
    "Blackalls Park, NSW, 2283",
    "Blackburn North, VIC, 3130",
    "Blackburn South, VIC, 3130",
    "Blackburn, VIC, 3130",
    "Blackbutt, QLD, 4306",
    "Blackett, NSW, 2770",
    "Blackheath, NSW, 2785",
    "Blackmans Bay, TAS, 7052",
    "Blacksmiths, NSW, 2281",
    "Blacktown, NSW, 2148",
    "Blackwater, QLD, 4717",
    "Blackwood, SA, 5051",
    "Blair Athol, SA, 5084",
    "Blakehurst, NSW, 2221",
    "Blakeview, SA, 5114",
    "Blanchetown, SA, 5357",
    "Blaxland East, NSW, 2774",
    "Blaxland, NSW, 2774",
    "Blayney, NSW, 2799",
    "Bli Bli, QLD, 4560",
    "Bligh Park, NSW, 2756",
    "Bluff, QLD, 4702",
    "Blyth, SA, 5462",
    "Boambee East, NSW, 2452",
    "Bodalla, NSW, 2545",
    "Bogan Gate, NSW, 2876",
    "Bogangar, NSW, 2488",
    "Boggabilla, NSW, 2409",
    "Boggabri, NSW, 2382",
    "Bohle, QLD, 4818",
    "Bokarina, QLD, 4575",
    "Bolivar, SA, 5110",
    "Bomaderry, NSW, 2540",
    "Bomaderry, NSW, 2541",
    "Bombala, NSW, 2632",
    "Bonalbo, NSW, 2469",
    "Bondi Beach, NSW, 2026",
    "Bondi Junction, NSW, 2022",
    "Bondi, NSW, 2026",
    "Bongaree, QLD, 4507",
    "Bonnells Bay, NSW, 2264",
    "Bonny Hills, NSW, 2445",
    "Bonnyrigg, NSW, 2177",
    "Boolaroo, NSW, 2284",
    "Booleroo Centre, SA, 5482",
    "Boomi, NSW, 2405",
    "Boonah, QLD, 4310",
    "Boondall, QLD, 4034",
    "Boonooroo, QLD, 4650",
    "Boorooma, NSW, 2650",
    "Boorowa, NSW, 2586",
    "Boort, VIC, 3537",
    "Booval, QLD, 4304",
    "Bordertown, SA, 5268",
    "Boronia Heights, QLD, 4124",
    "Boronia, VIC, 3155",
    "Bororen, QLD, 4678",
    "Bossley Park, NSW, 2176",
    "Botany, NSW, 2019",
    "Boulia, QLD, 4829",
    "Bourke, NSW, 2840",
    "Bowen Hills, QLD, 4006",
    "Bowen, QLD, 4805",
    "Bowning, NSW, 2582",
    "Bowral, NSW, 2576",
    "Bowraville, NSW, 2449",
    "Box Hill North, VIC, 3129",
    "Box Hill South, VIC, 3128",
    "Box Hill, NSW, 2765",
    "Box Hill, VIC, 3128",
    "Boyne Island, QLD, 4680",
    "Bracken Ridge, QLD, 4017",
    "Bradbury, NSW, 2560",
    "Braddon, ACT, 2612",
    "Braeside, VIC, 3195",
    "Brahma Lodge, SA, 5109",
    "Braidwood, NSW, 2622",
    "Brandon, QLD, 4808",
    "Branxton, NSW, 2335",
    "Brassall, QLD, 4305",
    "Bray Park, QLD, 4500",
    "Braybrook, VIC, 3019",
    "Breakwater, VIC, 3219",
    "Bredbo, NSW, 2626",
    "Brendale, QLD, 4500",
    "Brewarrina, NSW, 2839",
    "Briar Hill, VIC, 3088",
    "Bribbaree, NSW, 2594",
    "Bribie Island, QLD, 4507",
    "Bridgewater, SA, 5155",
    "Bridgewater, TAS, 7030",
    "Bridport, TAS, 7262",
    "Bright, VIC, 3741",
    "Brighton East, VIC, 3187",
    "Brighton North, VIC, 3186",
    "Brighton, QLD, 4017",
    "Brighton, SA, 5048",
    "Brighton, TAS, 7030",
    "Brighton, VIC, 3186",
    "Brighton-le-Sands, NSW, 2216",
    "Bringelly, NSW, 2556",
    "Brinsmead, QLD, 4870",
    "Brisbane Adelaide Street, QLD, 4000",
    "Brisbane City, QLD, 4000",
    "Brisbane, QLD, 4000",
    "Broadbeach Waters, QLD, 4218",
    "Broadbeach, QLD, 4128",
    "Broadbeach, QLD, 4218",
    "Broadford, VIC, 3658",
    "Broadmeadow, NSW, 2292",
    "Broadmeadows, VIC, 3047",
    "Broadview, SA, 5083",
    "Broadwater, NSW, 2472",
    "Broadway, NSW, 2007",
    "Broken Hill, NSW, 2880",
    "Brompton, SA, 5007",
    "Bronte, NSW, 2024",
    "Brooklyn Park, SA, 5032",
    "Brooklyn, NSW, 2083",
    "Brooklyn, VIC, 3012",
    "Brookvale, NSW, 2100",
    "Brooms Head, NSW, 2463",
    "Brown Hill, VIC, 3350",
    "Browns Plains, QLD, 4118",
    "Brownsville, NSW, 2530",
    "Bruce, ACT, 2617",
    "Brunswick East, VIC, 3057",
    "Brunswick Heads, NSW, 2483",
    "Brunswick West, VIC, 3055",
    "Brunswick, VIC, 3056",
    "Brushgrove, NSW, 2460",
    "Buddina, QLD, 4575",
    "Buderim, QLD, 4556",
    "Budgewoi, NSW, 2262",
    "Buff Point, NSW, 2262",
    "Bulahdelah, NSW, 2423",
    "Bulimba, QLD, 4171",
    "Bulla, VIC, 3428",
    "Bulleen, VIC, 3105",
    "Bulli, NSW, 2516",
    "Bundaberg Central, QLD, 4670",
    "Bundaberg, QLD, 4670",
    "Bundall, QLD, 4217",
    "Bundamba, QLD, 4304",
    "Bundanoon, NSW, 2578",
    "Bundarra, NSW, 2359",
    "Bundeena, NSW, 2230",
    "Bundoora, VIC, 3083",
    "Bungendore, NSW, 2621",
    "Buninyong, VIC, 3357",
    "Bunya, QLD, 4055",
    "Bunyip, VIC, 3815",
    "Buranda, QLD, 4102",
    "Burdell, QLD, 4818",
    "Burleigh Heads, QLD, 4220",
    "Burleigh Waters, QLD, 4220",
    "Burnett Heads, QLD, 4670",
    "Burnie, TAS, 7320",
    "Burnley, VIC, 3121",
    "Burnside, QLD, 4208",
    "Burnside, SA, 5066",
    "Burnside, VIC, 3023",
    "Buronga, NSW, 2739",
    "Burpengary East, QLD, 4505",
    "Burpengary Meadows, QLD, 4505",
    "Burpengary, QLD, 4505",
    "Burra, SA, 5417",
    "Burraga, NSW, 2795",
    "Burraneer, NSW, 2230",
    "Burrawang, NSW, 2577",
    "Burren Junction, NSW, 2386",
    "Burrill Lake, NSW, 2539",
    "Burringbah, NSW, 2483",
    "Burrum Heads, QLD, 4659",
    "Burrumbeet, VIC, 3352",
    "Burton, SA, 5110",
    "Burwood East, VIC, 3151",
    "Burwood, NSW, 2134",
    "Burwood, VIC, 3125",
    "Bushland Beach, QLD, 4818",
    "Bute, SA, 5560",
    "Byron Bay, NSW, 2481",
    "Cabarita, NSW, 2137",
    "Cabarlah, QLD, 4352",
    "Caboolture, QLD, 4510",
    "Cabramatta West, NSW, 2166",
    "Cabramatta, NSW, 2166",
    "Caddens, NSW, 2747",
    "Cadell, SA, 5321",
    "Cairnlea, VIC, 3023",
    "Cairns City, QLD, 4870",
    "Cairns North, QLD, 4870",
    "Cairns, QLD, 4870",
    "Calala, NSW, 2340",
    "Calamvale, QLD, 4116",
    "Calen, QLD, 4798",
    "Callala Bay, NSW, 2540",
    "Callala Beach, NSW, 2540",
    "Calliope, QLD, 4680",
    "Caloundra West, QLD, 4551",
    "Caloundra, QLD, 4551",
    "Calwell, ACT, 2905",
    "Camberwell, VIC, 3124",
    "Cambooya, QLD, 4358",
    "Cambridge Gardens, NSW, 2747",
    "Cambridge Park, NSW, 2747",
    "Camden Park, SA, 5038",
    "Camden, NSW, 2570",
    "Cameron Park, NSW, 2285",
    "Camira, QLD, 4300",
    "Cammeray, NSW, 2062",
    "Camooweal, QLD, 4828",
    "Camp Hill, QLD, 4152",
    "Campbell Town, TAS, 7210",
    "Campbellfield, VIC, 3061",
    "Campbells Creek, VIC, 3451",
    "Campbelltown, NSW, 2560",
    "Campbelltown, SA, 5074",
    "Camperdown, NSW, 2050",
    "Camperdown, VIC, 3260",
    "Campsie, NSW, 2194",
    "Canberra City, ACT, 2601",
    "Canberra, ACT, 2601",
    "Candelo, NSW, 2550",
    "Canley Heights, NSW, 2166",
    "Canley Vale, NSW, 2166",
    "Cannon Hill, QLD, 4170",
    "Cannonvale, QLD, 4802",
    "Canowindra, NSW, 2804",
    "Canterbury, NSW, 2193",
    "Canterbury, VIC, 3126",
    "Canungra, QLD, 4275",
    "Capalaba, QLD, 4157",
    "Cape Jervis, SA, 5204",
    "Cape Woolamai, VIC, 3925",
    "Capella, QLD, 4723",
    "Capertee, NSW, 2846",
    "Captains Flat, NSW, 2623",
    "Carcoar, NSW, 2791",
    "Cardiff, NSW, 2285",
    "Cardwell, QLD, 4849",
    "Carina, QLD, 4152",
    "Carindale, QLD, 4152",
    "Caringbah, NSW, 2229",
    "Carlingford, NSW, 2118",
    "Carlton North, VIC, 3054",
    "Carlton, NSW, 2218",
    "Carlton, VIC, 3053",
    "Carmila, QLD, 4739",
    "Carnegie, VIC, 3163",
    "Carnes Hill, NSW, 2171",
    "Caroline Springs, VIC, 3023",
    "Carpenter Rocks, SA, 5291",
    "Carramar, NSW, 2163",
    "Carrara, QLD, 4211",
    "Carrick, TAS, 7291",
    "Carrington, NSW, 2294",
    "Carrum Downs, VIC, 3201",
    "Carseldine, QLD, 4034",
    "Carss Park, NSW, 2221",
    "Casey, ACT, 2913",
    "Cashmere, QLD, 4500",
    "Casino, NSW, 2470",
    "Cassilis, NSW, 2329",
    "Casterton, VIC, 3311",
    "Castle Hill, NSW, 2154",
    "Castlemaine, VIC, 3450",
    "Casuarina, NT, 0810",
    "Casula, NSW, 2170",
    "Catherine Hill Bay, NSW, 2281",
    "Caulfield East, VIC, 3145",
    "Caulfield North, VIC, 3161",
    "Caulfield South, VIC, 3162",
    "Caulfield, VIC, 3162",
    "Cavan, SA, 5094",
    "Caves Beach, NSW, 2281",
    "Cawdor, NSW, 2570",
    "Cecil Hills, NSW, 2171",
    "Cecil Plains, QLD, 4407",
    "Ceduna, SA, 5690",
    "Cessnock, NSW, 2325",
    "Chadstone, VIC, 3148",
    "Chancellor Park, QLD, 4556",
    "Charleston, SA, 5244",
    "Charlestown, NSW, 2290",
    "Charleville, QLD, 4470",
    "Charlton, VIC, 3525",
    "Charmhaven, NSW, 2263",
    "Charnwood, ACT, 2615",
    "Charters Towers City, QLD, 4820",
    "Charters Towers, QLD, 4820",
    "Chatham, NSW, 2430",
    "Chatswood Hills, QLD, 4127",
    "Chatswood, NSW, 2067",
    "Chelmer, QLD, 4068",
    "Chelsea Heights, VIC, 3175",
    "Chelsea, VIC, 3196",
    "Cheltenham, VIC, 3192",
    "Chermside, QLD, 4032",
    "Cherrybrook, NSW, 2126",
    "Chester Hill, NSW, 2162",
    "Chevron Island, QLD, 4217",
    "Chigwell, TAS, 7011",
    "Childers, QLD, 4660",
    "Chillagoe, QLD, 4871",
    "Chiltern, VIC, 3683",
    "Chinchilla, QLD, 4413",
    "Chinderah, NSW, 2487",
    "Chippendale, NSW, 2008",
    "Chipping Norton, NSW, 2170",
    "Chirn Park, QLD, 4215",
    "Chirnside Park, VIC, 3116",
    "Chisholm, ACT, 2905",
    "Chisholm, NSW, 2322",
    "Chittaway Bay, NSW, 2261",
    "Christie Downs, SA, 5164",
    "Christies Beach, SA, 5165",
    "Chullora, NSW, 2190",
    "Churchill, VIC, 3842",
    "Civic Square, ACT, 2608",
    "Clare, SA, 5453",
    "Claremont Meadows, NSW, 2747",
    "Claremont, TAS, 7011",
    "Clarence Gardens, SA, 5039",
    "Clarence Park, SA, 5034",
    "Clarence Town, NSW, 2321",
    "Clarendon, NSW, 2756",
    "Clarendon, SA, 5157",
    "Clarinda, VIC, 3169",
    "Clayfield, QLD, 4011",
    "Claymore, NSW, 2559",
    "Clayton South, VIC, 3169",
    "Clayton, VIC, 3168",
    "Clear Island Waters, QLD, 4226",
    "Clearview, SA, 5085",
    "Clemton Park, NSW, 2206",
    "Clermont, QLD, 4721",
    "Cleve, SA, 5640",
    "Cleveland, QLD, 4163",
    "Clifton Beach, QLD, 4879",
    "Clifton Hill, VIC, 3068",
    "Clifton Springs, VIC, 3222",
    "Clifton, QLD, 4361",
    "Clinton, QLD, 4680",
    "Cloncurry, QLD, 4824",
    "Clontarf, QLD, 4019",
    "Clovelly Park, SA, 5042",
    "Clovelly, NSW, 2031",
    "Cluden, QLD, 4811",
    "Clunes, VIC, 3370",
    "Clyde North, VIC, 3978",
    "Clyde, VIC, 3978",
    "Cobar, NSW, 2835",
    "Cobargo, NSW, 2550",
    "Cobblebank, VIC, 3338",
    "Cobden, VIC, 3266",
    "Cobdogla, SA, 5346",
    "Cobram, VIC, 3643",
    "Cobram, VIC, 3644",
    "Coburg North, VIC, 3058",
    "Coburg, VIC, 3058",
    "Coffin Bay, SA, 5607",
    "Coffs Harbour, NSW, 2450",
    "Cohuna, VIC, 3568",
    "Colac, VIC, 3250",
    "Coldstream, VIC, 3770",
    "Coleambally, NSW, 2707",
    "Colebee, NSW, 2761",
    "Coledale, NSW, 2515",
    "Coleraine, VIC, 3315",
    "Collarenebri, NSW, 2833",
    "Collaroy Beach, NSW, 2097",
    "Collaroy Plateau, NSW, 2097",
    "Collaroy, NSW, 2097",
    "Collingwood Park, QLD, 4301",
    "Collingwood, VIC, 3066",
    "Collinsville, QLD, 4804",
    "Collinswood, SA, 5081",
    "Colonel Light Gardens, SA, 5041",
    "Colyton, NSW, 2760",
    "Comboyne, NSW, 2429",
    "Como, NSW, 2226",
    "Concord West, NSW, 2138",
    "Concord, NSW, 2137",
    "Condamine, QLD, 4416",
    "Condell Park, NSW, 2200",
    "Conder, ACT, 2906",
    "Condoblin, NSW, 2877",
    "Condobolin, NSW, 2877",
    "Condon, QLD, 4815",
    "Condong, NSW, 2484",
    "Coniston, NSW, 2500",
    "Coober Pedy, SA, 5723",
    "Coobowie, SA, 5583",
    "Coogee, NSW, 2034",
    "Cooks Hill, NSW, 2300",
    "Cooktown, QLD, 4895",
    "Coolah, NSW, 2843",
    "Coolalinga, NT, 0839",
    "Coolamon, NSW, 2701",
    "Coolangatta, QLD, 4225",
    "Coolaroo, VIC, 3048",
    "Coolongolook, NSW, 2423",
    "Cooloola Cove, QLD, 4580",
    "Coolum Beach, QLD, 4573",
    "Cooma, NSW, 2630",
    "Coombabah, QLD, 4216",
    "Coomera East, QLD, 4209",
    "Coomera Waters, QLD, 4209",
    "Coomera, QLD, 4209",
    "Coominya, QLD, 4311",
    "Coonabarabran, NSW, 2357",
    "Coonalpyn, SA, 5265",
    "Coonamble, NSW, 2829",
    "Coopernook, NSW, 2426",
    "Coopers Plains, QLD, 4108",
    "Cooranbong, NSW, 2265",
    "Cooroy, QLD, 4563",
    "Coorparoo, QLD, 4151",
    "Cootamundra, NSW, 2590",
    "Copacabana, NSW, 2251",
    "Copmanhurst, NSW, 2460",
    "Coppabella, QLD, 4741",
    "Coraki, NSW, 2471",
    "Coramba, NSW, 2450",
    "Corinda, QLD, 4075",
    "Corindi Beach, NSW, 2456",
    "Corio, VIC, 3214",
    "Cornubia, QLD, 4130",
    "Coromandel Valley, SA, 5051",
    "Corowa, NSW, 2646",
    "Corrimal, NSW, 2518",
    "Corryong, VIC, 3707",
    "Coutts Crossing, NSW, 2460",
    "Cowandilla, SA, 5033",
    "Cowell, SA, 5602",
    "Cowes, VIC, 3922",
    "Cowra, NSW, 2794",
    "Crace, ACT, 2911",
    "Craigieburn, VIC, 3064",
    "Craigmore, SA, 5114",
    "Craignish, QLD, 4655",
    "Cranbourne East, VIC, 3977",
    "Cranbourne North, VIC, 3977",
    "Cranbourne West, VIC, 3977",
    "Cranbourne, VIC, 3977",
    "Cranbrook, QLD, 4814",
    "Cranebrook, NSW, 2749",
    "Cremorne, NSW, 2090",
    "Crescent Head, NSW, 2440",
    "Crestmead, QLD, 4132",
    "Creswick, VIC, 3363",
    "Cringila, NSW, 2502",
    "Cromer, NSW, 2097",
    "Cronulla, NSW, 2230",
    "Crookwell, NSW, 2583",
    "Croppa Creek, NSW, 2411",
    "Crows Nest, NSW, 2065",
    "Crows Nest, QLD, 4355",
    "Croyden Park, NSW, 2133",
    "Croydon Hills, VIC, 3136",
    "Croydon North, VIC, 3136",
    "Croydon Park, NSW, 2133",
    "Croydon Park, SA, 5008",
    "Croydon South, VIC, 3136",
    "Croydon, NSW, 2132",
    "Croydon, VIC, 3136",
    "Crystal Brook, SA, 5523",
    "Cudal, NSW, 2864",
    "Cudlee Creek, SA, 5232",
    "Culburra Beach, NSW, 2540",
    "Culcairn, NSW, 2660",
    "Cumberland Park, SA, 5041",
    "Cummins, SA, 5631",
    "Cumnock, NSW, 2867",
    "Cundletown, NSW, 2430",
    "Cunnamulla, QLD, 4490",
    "Curlewis, NSW, 2381",
    "Curlewis, VIC, 3222",
    "Currajong, QLD, 4812",
    "Currarong, NSW, 2540",
    "Currie, TAS, 7256",
    "Currimundi, QLD, 4551",
    "Currumbin Valley, QLD, 4223",
    "Currumbin Waters, QLD, 4223",
    "Currumbin, QLD, 4223",
    "Curtin, ACT, 2605",
    "Cygnet, TAS, 7112",
    "D'Aguilar, QLD, 4514",
    "Daceyville, NSW, 2018",
    "Daisy Hill, QLD, 4127",
    "Dakabin, QLD, 4503",
    "Dalby, QLD, 4405",
    "Dalmeny, NSW, 2546",
    "Dandenong North, VIC, 3175",
    "Dandenong South, VIC, 3175",
    "Dandenong, VIC, 3175",
    "Dapto, NSW, 2530",
    "Dareton, NSW, 2717",
    "Darley, VIC, 3340",
    "Darling Harbour, NSW, 2000",
    "Darlinghurst, NSW, 2010",
    "Darlinghurst, NSW, 2021",
    "Darlington Point, NSW, 2706",
    "Darlington, NSW, 2008",
    "Darlington, SA, 5047",
    "Darra, QLD, 4076",
    "Dartmoor, VIC, 3304",
    "Darwin City, NT, 0800",
    "Darwin River, NT, 0841",
    "Darwin, NT, 0800",
    "Davistown, NSW, 2251",
    "Davoren Park, SA, 5113",
    "Daw Park, SA, 5041",
    "Dayboro, QLD, 4521",
    "Daylesford, VIC, 3460",
    "Deagon, QLD, 4017",
    "Deakin, ACT, 2600",
    "Deception Bay, QLD, 4508",
    "Dederang, VIC, 3691",
    "Dee Why, NSW, 2099",
    "Deer Park, VIC, 3023",
    "Deeragun, QLD, 4818",
    "Delacombe, VIC, 3356",
    "Delahey, VIC, 3037",
    "Delegate, NSW, 2633",
    "Deloraine, TAS, 7304",
    "Delungra, NSW, 2403",
    "Denham Court, NSW, 2565",
    "Deniliquin, NSW, 2710",
    "Denistone East, NSW, 2112",
    "Denistone, NSW, 2114",
    "Denman, NSW, 2328",
    "Dernancourt, SA, 5075",
    "Derrimut, VIC, 3026",
    "Derwent Park, TAS, 7009",
    "Devonport, TAS, 7310",
    "Diamond Creek, VIC, 3089",
    "Dickson, ACT, 2602",
    "Dicky Beach, QLD, 4551",
    "Diggers Rest, VIC, 3427",
    "Dimboola, VIC, 3414",
    "Dimbulah, QLD, 4872",
    "Dingley Village, VIC, 3172",
    "Dingo Beach, QLD, 4800",
    "Dingo, QLD, 4702",
    "Dirranbandi, QLD, 4486",
    "Docklands, VIC, 3008",
    "Dodges Ferry, TAS, 7173",
    "Dolls Point, NSW, 2219",
    "Donald, VIC, 3480",
    "Doncaster East, VIC, 3109",
    "Doncaster, VIC, 3108",
    "Donnybrook, QLD, 4510",
    "Doonside, NSW, 2767",
    "Dora Creek, NSW, 2264",
    "Doreen, VIC, 3754",
    "Dorrigo, NSW, 2453",
    "Double Bay, NSW, 2028",
    "Douglas, QLD, 4814",
    "Dover, TAS, 7117",
    "Doveton, VIC, 3177",
    "Doyalson, NSW, 2262",
    "Drake, NSW, 2469",
    "Drayton, QLD, 4350",
    "Dromana, VIC, 3936",
    "Drouin, VIC, 3818",
    "Drummoyne, NSW, 2047",
    "Dry Creek, SA, 5094",
    "Drysdale, VIC, 3222",
    "Duaringa, QLD, 4712",
    "Dubbo, NSW, 2830",
    "Dublin, SA, 5501",
    "Dudley, NSW, 2290",
    "Dulacca, QLD, 4425",
    "Dulwich Hill, NSW, 2203",
    "Dulwich, SA, 5065",
    "Dunalley, TAS, 7177",
    "Dundas Valley, NSW, 2117",
    "Dundas, NSW, 2117",
    "Dunedoo, NSW, 2844",
    "Dungog, NSW, 2420",
    "Dungowan, NSW, 2340",
    "Dunkeld, VIC, 3294",
    "Dunolly, VIC, 3472",
    "Dunoon, NSW, 2480",
    "Dunwich, QLD, 4183",
    "Durack, QLD, 4077",
    "Dural, NSW, 2158",
    "Dysart, QLD, 4745",
    "Eagle Farm, QLD, 4009",
    "Eagle Junction, QLD, 4011",
    "Eagle Vale, NSW, 2558",
    "Eagleby, QLD, 4207",
    "Eaglehawk, VIC, 3556",
    "Earlville, QLD, 4870",
    "Earlwood, NSW, 2206",
    "East Ballina, NSW, 2478",
    "East Brisbane, QLD, 4169",
    "East Corrimal, NSW, 2518",
    "East Devonport, TAS, 7310",
    "East Gosford, NSW, 2250",
    "East Gresford, NSW, 2311",
    "East Hills, NSW, 2213",
    "East Ipswich, QLD, 4305",
    "East Lismore, NSW, 2480",
    "East Maitland, NSW, 2323",
    "East Melbourne, VIC, 3002",
    "East Sydney, NSW, 2010",
    "East Tamworth, NSW, 2340",
    "East Toowoomba, QLD, 4350",
    "Eastern Creek, NSW, 2766",
    "Eastlakes, NSW, 2018",
    "Eastwood, NSW, 2122",
    "Eatons Hill, QLD, 4037",
    "Echuca, VIC, 3564",
    "Eden Valley, SA, 5235",
    "Eden, NSW, 2551",
    "Edenhope, VIC, 3318",
    "Edens Landing, QLD, 4207",
    "Edensor Park, NSW, 2176",
    "Edge Hill, QLD, 4870",
    "Edgecliff, NSW, 2027",
    "Edgeworth, NSW, 2285",
    "Edithburgh, SA, 5583",
    "Edithvale, VIC, 3196",
    "Edmondson Park, NSW, 2174",
    "Edmonton, QLD, 4869",
    "Edwardstown, SA, 5039",
    "Eidsvold, QLD, 4627",
    "Eight Mile Plains, QLD, 4113",
    "Elanora Heights, NSW, 2101",
    "Elanora, QLD, 4221",
    "Elermore Vale, NSW, 2287",
    "Eli Waters, QLD, 4655",
    "Elimbah, QLD, 4516",
    "Elizabeth Beach, NSW, 2428",
    "Elizabeth Downs, SA, 5113",
    "Elizabeth East, SA, 5112",
    "Elizabeth North, SA, 5113",
    "Elizabeth Park, SA, 5113",
    "Elizabeth South, SA, 5112",
    "Elizabeth Vale, SA, 5112",
    "Elizabeth, SA, 5112",
    "Ellalong, NSW, 2325",
    "Ellen Grove, QLD, 4300",
    "Elliott Heads, QLD, 4670",
    "Elliston, SA, 5670",
    "Elmore, VIC, 3558",
    "Elsternwick, VIC, 3185",
    "Eltham, VIC, 3095",
    "Elwood, VIC, 3184",
    "Emerald, QLD, 4720",
    "Emerald, VIC, 3782",
    "Emerton, NSW, 2770",
    "Emmaville, NSW, 2371",
    "Empire Bay, NSW, 2257",
    "Emu Heights, NSW, 2750",
    "Emu Park, QLD, 4710",
    "Emu Plains, NSW, 2750",
    "Endeavour Hills, VIC, 3802",
    "Enfield, NSW, 2136",
    "Engadine, NSW, 2233",
    "Enmore, NSW, 2042",
    "Enngonia, NSW, 2840",
    "Enoggera, QLD, 4051",
    "Epping, NSW, 2121",
    "Epping, VIC, 3076",
    "Epsom, VIC, 3551",
    "Erina Heights, NSW, 2260",
    "Erina, NSW, 2250",
    "Ermington, NSW, 2115",
    "Erskine Park, NSW, 2759",
    "Erskineville, NSW, 2043",
    "Esk, QLD, 4312",
    "Essendon Fields, VIC, 3041",
    "Essendon, VIC, 3040",
    "Ethelton, SA, 5015",
    "Eton, QLD, 4741",
    "Ettalong Beach, NSW, 2257",
    "Eudunda, SA, 5374",
    "Eugowra, NSW, 2806",
    "Eumundi, QLD, 4562",
    "Euramo, QLD, 4854",
    "Euroa, VIC, 3666",
    "Euston, NSW, 2737",
    "Evandale, SA, 5069",
    "Evans Head, NSW, 2473",
    "Evanston Gardens, SA, 5116",
    "Evanston, SA, 5116",
    "Evatt, ACT, 2617",
    "Everton Hills, QLD, 4053",
    "Everton Park, QLD, 4053",
    "Exeter, SA, 5019",
    "Exeter, TAS, 7275",
    "Fairfield East, NSW, 2165",
    "Fairfield Heights, NSW, 2165",
    "Fairfield West, NSW, 2165",
    "Fairfield, NSW, 2165",
    "Fairfield, QLD, 4103",
    "Fairfield, VIC, 3078",
    "Fairlight, NSW, 2094",
    "Fairview Park, SA, 5126",
    "Fairy Meadow, NSW, 2519",
    "Fannie Bay, NT, 0820",
    "Farleigh, QLD, 4741",
    "Fawkner, VIC, 3060",
    "Felixstow, SA, 5070",
    "Ferntree Gully, VIC, 3156",
    "Fernvale, QLD, 4306",
    "Ferny Grove, QLD, 4055",
    "Ferny Hills, QLD, 4055",
    "Ferryden Park, SA, 5010",
    "Figtree, NSW, 2525",
    "Findon, SA, 5023",
    "Fingal Bay, NSW, 2315",
    "Finley, NSW, 2713",
    "Firle, SA, 5070",
    "Fishery Falls, QLD, 4871",
    "Fitzgibbon, QLD, 4018",
    "Fitzroy North, VIC, 3068",
    "Fitzroy, VIC, 3065",
    "Five Dock, NSW, 2046",
    "Flagstaff Hill, SA, 5159",
    "Flagstone, QLD, 4280",
    "Flemington, VIC, 3031",
    "Fletcher, NSW, 2287",
    "Flinders Park, SA, 5025",
    "Flinders View, QLD, 4305",
    "Flinders, NSW, 2529",
    "Flinders, VIC, 3929",
    "Footscray, VIC, 3011",
    "Forbes, NSW, 2871",
    "Forest Glen, QLD, 4556",
    "Forest Hill, NSW, 2651",
    "Forest Hill, QLD, 4342",
    "Forest Hill, VIC, 3131",
    "Forest Lake, QLD, 4078",
    "Forest Lodge, NSW, 2037",
    "Forestville, NSW, 2087",
    "Forrest Beach, QLD, 4850",
    "Forster, NSW, 2428",
    "Fortitude Valley, QLD, 4006",
    "Foster, VIC, 3960",
    "Frances, SA, 5262",
    "Frankston North, VIC, 3200",
    "Frankston South, VIC, 3199",
    "Frankston, VIC, 3199",
    "Fraser Rise, VIC, 3336",
    "Fraser, ACT, 2615",
    "Frederickton, NSW, 2440",
    "Freeling, SA, 5372",
    "Frenchs Forest, NSW, 2086",
    "Freshwater, NSW, 2096",
    "Fulham Gardens, SA, 5024",
    "Fulham, SA, 5024",
    "Fullarton, SA, 5063",
    "Fyansford, VIC, 3218",
    "Fyshwick, ACT, 2609",
    "Gailes, QLD, 4300",
    "Galston, NSW, 2159",
    "Ganmain, NSW, 2702",
    "Garbutt, QLD, 4814",
    "Gardenvale, VIC, 3185",
    "Garfield, VIC, 3814",
    "Garran, ACT, 2605",
    "Gateshead, NSW, 2290",
    "Gatton, QLD, 4343",
    "Gaven, QLD, 4211",
    "Gawler East, SA, 5118",
    "Gawler West, SA, 5118",
    "Gawler, SA, 5118",
    "Gayndah, QLD, 4625",
    "Gaythorne, QLD, 4051",
    "Geebung, QLD, 4034",
    "Geelong East, VIC, 3219",
    "Geelong North, VIC, 3215",
    "Geelong West, VIC, 3218",
    "Geelong, VIC, 3215",
    "Geelong, VIC, 3220",
    "Geeveston, TAS, 7116",
    "Gembrook, VIC, 3783",
    "George Town, TAS, 7253",
    "Georges Hall, NSW, 2198",
    "Gepps Cross, SA, 5094",
    "Gerringong, NSW, 2534",
    "Gerroa, NSW, 2534",
    "Geurie, NSW, 2818",
    "Gilberton, SA, 5081",
    "Gilgandra, NSW, 2827",
    "Gillen, NT, 0870",
    "Gilles Plains, SA, 5086",
    "Gillieston Heights, NSW, 2321",
    "Gin Gin, QLD, 4671",
    "Girilambone, NSW, 2831",
    "Giru, QLD, 4809",
    "Gisborne, VIC, 3437",
    "Gladesville, NSW, 2111",
    "Gladstone Park, VIC, 3043",
    "Gladstone, NSW, 2440",
    "Gladstone, QLD, 4680",
    "Gladstone, SA, 5473",
    "Gladstone, TAS, 7264",
    "Glamorgan Vale, QLD, 4306",
    "Glandore, SA, 5037",
    "Glanville, SA, 5015",
    "Glass House Mountains, QLD, 4518",
    "Glebe, NSW, 2037",
    "Glebe, TAS, 7000",
    "Gledswood Hills, NSW, 2557",
    "Glen Alpine, NSW, 2560",
    "Glen Innes, NSW, 2370",
    "Glen Iris, VIC, 3146",
    "Glen Osmond, SA, 5064",
    "Glen Waverley, VIC, 3150",
    "Glenbrook, NSW, 2773",
    "Glenburnie, SA, 5291",
    "Glendale, NSW, 2285",
    "Glendambo, SA, 5710",
    "Glenden, QLD, 4743",
    "Glenelg East, SA, 5045",
    "Glenelg North, SA, 5045",
    "Glenelg South, SA, 5045",
    "Glenelg, SA, 5045",
    "Glenella, QLD, 4740",
    "Glenfield, NSW, 2167",
    "Glengala, VIC, 3020",
    "Glengowrie, SA, 5044",
    "Glenhuntly, VIC, 3163",
    "Glenmore Park, NSW, 2745",
    "Glenorchy, TAS, 7010",
    "Glenorie, NSW, 2157",
    "Glenquarie, NSW, 2564",
    "Glenreagh, NSW, 2450",
    "Glenroy, VIC, 3046",
    "Glenside, SA, 5065",
    "Glenunga, SA, 5064",
    "Glenwood, NSW, 2768",
    "Gloucester, NSW, 2422",
    "Glynde, SA, 5070",
    "Gol Gol, NSW, 2738",
    "Golden Beach, QLD, 4551",
    "Golden Grove, SA, 5125",
    "Golden Square, VIC, 3555",
    "Goodna, QLD, 4300",
    "Goodwood, SA, 5034",
    "Goolgowi, NSW, 2652",
    "Goolma, NSW, 2852",
    "Gooloogong, NSW, 2805",
    "Goolwa, SA, 5214",
    "Goombungee, QLD, 4354",
    "Goomeri, QLD, 4601",
    "Goondiwindi, QLD, 4390",
    "Goonellabah, NSW, 2480",
    "Gordon, ACT, 2906",
    "Gordon, NSW, 2072",
    "Gordonvale, QLD, 4865",
    "Gorokan, NSW, 2263",
    "Gosford, NSW, 2250",
    "Goulburn, NSW, 2580",
    "Gove, NT, 0880",
    "Gracemere, QLD, 4702",
    "Graceville, QLD, 4075",
    "Grafton, NSW, 2460",
    "Grandchester, QLD, 4340",
    "Grange, QLD, 4051",
    "Grange, SA, 5022",
    "Granton, TAS, 7030",
    "Grantville, VIC, 3984",
    "Granville, NSW, 2142",
    "Granville, QLD, 4650",
    "Great Western, VIC, 3374",
    "Green Point, NSW, 2251",
    "Green Valley, NSW, 2168",
    "Greenacre, NSW, 2190",
    "Greenacres, SA, 5086",
    "Greenbank, QLD, 4124",
    "Greenfield Park, NSW, 2176",
    "Greenock, SA, 5360",
    "Greensborough, VIC, 3088",
    "Greenslopes, QLD, 4120",
    "Greenvale, QLD, 4816",
    "Greenvale, VIC, 3059",
    "Greenway, ACT, 2900",
    "Greenwell Point, NSW, 2540",
    "Greenwith, SA, 5125",
    "Gregory Hills, NSW, 2557",
    "Grenfell, NSW, 2810",
    "Gresford, NSW, 2311",
    "Greta, NSW, 2334",
    "Greystanes, NSW, 2145",
    "Griffin, QLD, 4503",
    "Griffith, NSW, 2680",
    "Grovedale, VIC, 3216",
    "Guildford West, NSW, 2161",
    "Guildford, NSW, 2161",
    "Gulargambone, NSW, 2828",
    "Gulgong, NSW, 2852",
    "Gulliver, QLD, 4812",
    "Gumdale, QLD, 4154",
    "Gunalda, QLD, 4570",
    "Gunbower, VIC, 3566",
    "Gundagai, NSW, 2722",
    "Gungahlin, ACT, 2912",
    "Gunnedah, NSW, 2380",
    "Gunning, NSW, 2581",
    "Guyra, NSW, 2365",
    "Gwandalan, NSW, 2259",
    "Gwynneville, NSW, 2500",
    "Gwynneville, NSW, 2550",
    "Gymea, NSW, 2227",
    "Gympie, QLD, 4570",
    "Haberfield, NSW, 2045",
    "Hackham West, SA, 5163",
    "Hackham, SA, 5163",
    "Hackney, SA, 5069",
    "Hadfield, VIC, 3046",
    "Hahndorf, SA, 5245",
    "Haigslea, QLD, 4306",
    "Half Tide Beach, QLD, 4740",
    "Halidon, SA, 5309",
    "Halifax, QLD, 4850",
    "Hallam, VIC, 3803",
    "Hallett Cove, SA, 5158",
    "Halliday Bay, QLD, 4740",
    "Hallidays Point, NSW, 2430",
    "Hamilton Island, QLD, 4803",
    "Hamilton, NSW, 2303",
    "Hamilton, QLD, 4007",
    "Hamilton, VIC, 3300",
    "Hamley Bridge, SA, 5401",
    "Hamlyn Heights, VIC, 3215",
    "Hammondville, NSW, 2170",
    "Hampstead Gardens, SA, 5086",
    "Hampton East, VIC, 3188",
    "Hampton Park, VIC, 3976",
    "Hampton, NSW, 2790",
    "Hampton, QLD, 4352",
    "Hampton, VIC, 3188",
    "Hanwood, NSW, 2680",
    "Happy Valley, SA, 5159",
    "Harbord, NSW, 2096",
    "Harden, NSW, 2587",
    "Hardys Bay, NSW, 2257",
    "Harrington Park, NSW, 2567",
    "Harrington, NSW, 2427",
    "Harris Park, NSW, 2150",
    "Harrisville, QLD, 4307",
    "Harwood, NSW, 2465",
    "Hassall Grove, NSW, 2761",
    "Hastings, VIC, 3915",
    "Hat Head, NSW, 2440",
    "Hatton Vale, QLD, 4341",
    "Hattonvale, QLD, 4341",
    "Hawker, ACT, 2614",
    "Hawker, SA, 5434",
    "Hawks Nest, NSW, 2324",
    "Hawthorn East, VIC, 3122",
    "Hawthorn East, VIC, 3123",
    "Hawthorn, VIC, 3122",
    "Hawthorne, QLD, 4171",
    "Hay Point, QLD, 4740",
    "Hay, NSW, 2711",
    "Hayborough, SA, 5211",
    "Haymarket, NSW, 2000",
    "Hazelbrook, NSW, 2779",
    "Hazelwood Park, SA, 5166",
    "Healesville, VIC, 3777",
    "Heathcote, NSW, 2233",
    "Heathcote, VIC, 3523",
    "Heathmont, VIC, 3135",
    "Heathwood, QLD, 4110",
    "Heatley, QLD, 4814",
    "Heddon Greta, NSW, 2321",
    "Heidelberg Heights, VIC, 3081",
    "Heidelberg West, VIC, 3081",
    "Heidelberg, VIC, 3084",
    "Helensburgh, NSW, 2508",
    "Helensvale, QLD, 4212",
    "Helidon, QLD, 4344",
    "Hemmant, QLD, 4174",
    "Hendon, SA, 5014",
    "Hendra, QLD, 4011",
    "Henley Beach South, SA, 5022",
    "Henley Beach, SA, 5022",
    "Henty, NSW, 2658",
    "Herberton, QLD, 4887",
    "Heritage Park, QLD, 4118",
    "Hermidale, NSW, 2831",
    "Hermit Park, QLD, 4812",
    "Herne Hill, VIC, 3218",
    "Herston, QLD, 4006",
    "Hervey Bay, QLD, 4655",
    "Hewett, SA, 5118",
    "Hexham, NSW, 2322",
    "Heyfield, VIC, 3858",
    "Heywood, VIC, 3304",
    "Highbury, SA, 5089",
    "Highett, VIC, 3190",
    "Highfields, QLD, 4352",
    "Highgate Hill, QLD, 4101",
    "Highland Park, QLD, 4211",
    "Highlands, VIC, 3660",
    "Highton, VIC, 3216",
    "Hill End, NSW, 2850",
    "Hill Top, NSW, 2575",
    "Hillbank, SA, 5112",
    "Hillcrest, QLD, 4118",
    "Hillcrest, SA, 5086",
    "Hillsborough, NSW, 2290",
    "Hillsdale, NSW, 2036",
    "Hillside, VIC, 3037",
    "Hillston, NSW, 2675",
    "Hillvue, NSW, 2340",
    "Hilton, SA, 5033",
    "Hindmarsh Island, SA, 5214",
    "Hindmarsh Valley, SA, 5211",
    "Hindmarsh, SA, 5007",
    "Hinton, NSW, 2321",
    "Hobart, TAS, 7000",
    "Holbrook, NSW, 2644",
    "Holden Hill, SA, 5088",
    "Holland Park West, QLD, 4121",
    "Holland Park, QLD, 4121",
    "Holloways Beach, QLD, 4878",
    "Holmesville, NSW, 2286",
    "Holmview, QLD, 4207",
    "Holt, ACT, 2615",
    "Holtze, NT, 0829",
    "Home Hill, QLD, 4806",
    "Homebush Bay, NSW, 2127",
    "Homebush West, NSW, 2140",
    "Homebush, NSW, 2140",
    "Hope Island, QLD, 4212",
    "Hope Valley, SA, 5090",
    "Hopetoun, VIC, 3396",
    "Hoppers Crossing, VIC, 3029",
    "Horn Island, QLD, 4875",
    "Hornsby Heights, NSW, 2077",
    "Hornsby, NSW, 2077",
    "Horsham, VIC, 3400",
    "Horsley Park, NSW, 2175",
    "Hove, SA, 5048",
    "Howard Springs, NT, 0835",
    "Howard, QLD, 4659",
    "Howlong, NSW, 2643",
    "Howrah, TAS, 7018",
    "Hoxton Park, NSW, 2171",
    "Hughenden, QLD, 4821",
    "Hughesdale, VIC, 3166",
    "Hume, ACT, 2620",
    "Humpty Doo, NT, 0836",
    "Hunters Hill, NSW, 2110",
    "Huntingdale, VIC, 3166",
    "Huntly, VIC, 3551",
    "Huonville, TAS, 7109",
    "Hurlstone Park, NSW, 2193",
    "Hurstbridge, VIC, 3099",
    "Hurstville, NSW, 2220",
    "Huskisson, NSW, 2540",
    "Hyde Park, QLD, 4812",
    "Hyde Park, SA, 5061",
    "Idalia, QLD, 4811",
    "Illfracombe, QLD, 4727",
    "Iluka, NSW, 2466",
    "Imbil, QLD, 4570",
    "Inala, QLD, 4077",
    "Indooroopilly, QLD, 4068",
    "Ingham, QLD, 4850",
    "Ingle Farm, SA, 5098",
    "Ingleburn, NSW, 2565",
    "Inglewood, QLD, 4387",
    "Inglewood, VIC, 3517",
    "Injune, QLD, 4454",
    "Innes Park, QLD, 4670",
    "Innisfail, QLD, 4860",
    "Inverell, NSW, 2360",
    "Inverloch, VIC, 3996",
    "Invermay Park, VIC, 3350",
    "Invermay, TAS, 7248",
    "Ipswich, QLD, 4305",
    "Irymple, VIC, 3498",
    "Islington, NSW, 2296",
    "Ivanhoe East, VIC, 3079",
    "Ivanhoe, NSW, 2878",
    "Ivanhoe, VIC, 3079",
    "Jabiru, NT, 0886",
    "Jacobs Well, QLD, 4208",
    "Jamberoo, NSW, 2533",
    "Jamestown, SA, 5491",
    "Jan Juc, VIC, 3228",
    "Jandowae, QLD, 4410",
    "Jannali, NSW, 2226",
    "Jerilderie, NSW, 2716",
    "Jerrabomberra, NSW, 2619",
    "Jesmond, NSW, 2299",
    "Jewells, NSW, 2280",
    "Jimboomba, QLD, 4280",
    "Jindabyne, NSW, 2627",
    "Jindalee, QLD, 4074",
    "Jindera, NSW, 2642",
    "Jingili, NT, 0810",
    "Jondaryan, QLD, 4403",
    "Jones Hill, QLD, 4570",
    "Jordan Springs, NSW, 2747",
    "Julia Creek, QLD, 4823",
    "Junction Hill, NSW, 2460",
    "Junee, NSW, 2663",
    "Junortoun, VIC, 3551",
    "Kabra, QLD, 4702",
    "Kadina, SA, 5554",
    "Kahibah, NSW, 2290",
    "Kairi, QLD, 4872",
    "Kalaru, NSW, 2550",
    "Kalbar, QLD, 4309",
    "Kaleen, ACT, 2617",
    "Kallangur, QLD, 4503",
    "Kambah, ACT, 2902",
    "Kandanga, QLD, 4570",
    "Kandos, NSW, 2848",
    "Kangaroo Flat, VIC, 3555",
    "Kangaroo Point, QLD, 4169",
    "Kanimbla, QLD, 4870",
    "Kaniva, VIC, 3419",
    "Kanwal, NSW, 2259",
    "Kapunda, SA, 5373",
    "Karabar, NSW, 2620",
    "Karalee, QLD, 4306",
    "Karama, NT, 0812",
    "Kareela, NSW, 2232",
    "Kariong, NSW, 2250",
    "Karoonda, SA, 5307",
    "Karuah, NSW, 2324",
    "Karumba, QLD, 4891",
    "Katherine, NT, 0850",
    "Katoomba, NSW, 2780",
    "Kawungan, QLD, 4655",
    "Kearlsey, NSW, 2326",
    "Kearns, NSW, 2558",
    "Kearsley, NSW, 2325",
    "Kedron, QLD, 4031",
    "Keilor Downs, VIC, 3038",
    "Keilor East, VIC, 3033",
    "Keilor, VIC, 3036",
    "Keiraville, NSW, 2500",
    "Keith, SA, 5267",
    "Kellyville Ridge, NSW, 2155",
    "Kellyville, NSW, 2155",
    "Kelso, NSW, 2795",
    "Kelso, QLD, 4815",
    "Kelvin Grove, QLD, 4059",
    "Kembla Grange, NSW, 2526",
    "Kemblawarra, NSW, 2505",
    "Kemps Creek, NSW, 2178",
    "Kempsey, NSW, 2440",
    "Kempton, TAS, 7030",
    "Kendall, NSW, 2439",
    "Kenilworth, QLD, 4574",
    "Kenmore, QLD, 4069",
    "Kennington, VIC, 3550",
    "Kensington Gardens, SA, 5068",
    "Kensington Park, SA, 5068",
    "Kensington, NSW, 2033",
    "Kensington, QLD, 4670",
    "Kensington, SA, 5068",
    "Kensington, VIC, 3031",
    "Kent Town, SA, 5067",
    "Kenthurst, NSW, 2156",
    "Keon Park, VIC, 3073",
    "Keperra, QLD, 4054",
    "Keppel Sands, QLD, 4702",
    "Kerang, VIC, 3579",
    "Kersbrook, SA, 5231",
    "Kew East, VIC, 3102",
    "Kew, NSW, 2439",
    "Kew, VIC, 3101",
    "Kewarra Beach, QLD, 4879",
    "Keysborough, VIC, 3173",
    "Khancoban, NSW, 2642",
    "Kialla, VIC, 3631",
    "Kiama Downs, NSW, 2533",
    "Kiama, NSW, 2533",
    "Kilburn, SA, 5084",
    "Kilcoy, QLD, 4515",
    "Kilkenny, SA, 5009",
    "Kilkivan, QLD, 4600",
    "Killara, NSW, 2071",
    "Killarney Vale, NSW, 2261",
    "Killarney, QLD, 4373",
    "Kilmore, VIC, 3764",
    "Kilsyth, VIC, 3137",
    "Kimba, SA, 5641",
    "Kin Kora, QLD, 4680",
    "Kincumber, NSW, 2251",
    "Kingaroy, QLD, 4610",
    "Kinglake, VIC, 3763",
    "Kings Beach, QLD, 4551",
    "Kings Cross, NSW, 2011",
    "Kings Langley, NSW, 2147",
    "Kings Meadows, TAS, 7249",
    "Kings Park, NSW, 2148",
    "Kings Park, VIC, 3021",
    "Kingsbury, VIC, 3083",
    "Kingscliff, NSW, 2487",
    "Kingscote Ki, SA, 5223",
    "Kingscote, SA, 5223",
    "Kingsford, NSW, 2032",
    "Kingsgrove, NSW, 2206",
    "Kingsgrove, NSW, 2207",
    "Kingsgrove, NSW, 2208",
    "Kingsthorpe, QLD, 4400",
    "Kingston Beach, TAS, 7050",
    "Kingston Se, SA, 5275",
    "Kingston, ACT, 2604",
    "Kingston, QLD, 4114",
    "Kingston, SA, 5275",
    "Kingston, TAS, 7050",
    "Kingsville, VIC, 3012",
    "Kingswood, NSW, 2340",
    "Kingswood, NSW, 2747",
    "Kingswood, SA, 5062",
    "Kippa-Ring, QLD, 4020",
    "Kippa-Ring, QLD, 4021",
    "Kirra, QLD, 4225",
    "Kirrawee, NSW, 2232",
    "Kirribilli, NSW, 2061",
    "Kirwan, QLD, 4817",
    "Kirwin, QLD, 4817",
    "Kitchener, NSW, 2325",
    "Klemzig, SA, 5087",
    "Knoxfield, VIC, 3180",
    "Kogarah, NSW, 2217",
    "Koo Wee Rup, VIC, 3981",
    "Kooralbyn, QLD, 4285",
    "Koorawatha, NSW, 2807",
    "Kooringal, NSW, 2650",
    "Kootingal, NSW, 2352",
    "Koroit, VIC, 3282",
    "Korumburra, VIC, 3950",
    "Kotara, NSW, 2289",
    "Krambach, NSW, 2429",
    "Kuraby, QLD, 4112",
    "Kuranda, QLD, 4872",
    "Kuranda, QLD, 4881",
    "Kurmond, NSW, 2757",
    "Kurnell, NSW, 2231",
    "Kurrajong Heights, NSW, 2758",
    "Kurralta Park, SA, 5037",
    "Kurri Kurri, NSW, 2327",
    "Kurrimine Beach, QLD, 4871",
    "Kuttabul, QLD, 4741",
    "Kyabram, VIC, 3619",
    "Kyabram, VIC, 3620",
    "Kyneton, VIC, 3444",
    "Kyogle, NSW, 2474",
    "La Perouse, NSW, 2036",
    "Labrador, QLD, 4215",
    "Laidley, QLD, 4341",
    "Lake Cargelligo, NSW, 2672",
    "Lake Cathie, NSW, 2445",
    "Lake Conjola, NSW, 2539",
    "Lake Haven, NSW, 2263",
    "Lake Munmorah, NSW, 2259",
    "Lakemba, NSW, 2195",
    "Lakes Creek, QLD, 4701",
    "Lakes Entrance, VIC, 3909",
    "Lalor Park, NSW, 2147",
    "Lalor, VIC, 3075",
    "Lambton, NSW, 2299",
    "Lameroo, SA, 5302",
    "Lancefield, VIC, 3435",
    "Landsborough, QLD, 4550",
    "Lane Cove, NSW, 2066",
    "Lang Lang, VIC, 3984",
    "Langhorne Creek, SA, 5255",
    "Langwarrin South, VIC, 3911",
    "Langwarrin, VIC, 3910",
    "Lansdowne, NSW, 2430",
    "Lansvale, NSW, 2166",
    "Lara, VIC, 3212",
    "Largs Bay, SA, 5016",
    "Largs North, SA, 5016",
    "Largs, NSW, 2320",
    "Latrobe, TAS, 7307",
    "Lauderdale, TAS, 7021",
    "Launceston, TAS, 7250",
    "Laura, SA, 5480",
    "Laurieton, NSW, 2443",
    "Laverton, VIC, 3028",
    "Lavington, NSW, 2641",
    "Lawnton, QLD, 4501",
    "Lawrence, NSW, 2460",
    "Lawson, NSW, 2783",
    "Leabrook, SA, 5068",
    "Leanyer, NT, 0812",
    "Leeton, NSW, 2705",
    "Legana, TAS, 7277",
    "Leichhardt, NSW, 2040",
    "Leichhardt, QLD, 4305",
    "Leigh Creek, SA, 5731",
    "Lemon Tree Passage, NSW, 2319",
    "Len Waters Estate, NSW, 2171",
    "Lenah Valley, TAS, 7008",
    "Lennox Head, NSW, 2478",
    "Leongatha, VIC, 3953",
    "Leopold, VIC, 3224",
    "Leppington, NSW, 2179",
    "Leumeah, NSW, 2560",
    "Leura, NSW, 2780",
    "Lewisham, NSW, 2049",
    "Lewisham, TAS, 7173",
    "Leyburn, QLD, 4365",
    "Lidcombe, NSW, 2141",
    "Lightning Ridge, NSW, 2834",
    "Lilydale, VIC, 3140",
    "Lilyfield, NSW, 2040",
    "Linden Park, SA, 5065",
    "Lindfield, NSW, 2070",
    "Lindisfarne, TAS, 7015",
    "Lisarow, NSW, 2250",
    "Lismore South, NSW, 2480",
    "Lismore, NSW, 2480",
    "Lissner, QLD, 4820",
    "Lithgow, NSW, 2790",
    "Little Bay, NSW, 2036",
    "Little Mountain, QLD, 4551",
    "Little Mulgrave, QLD, 4865",
    "Little River, VIC, 3211",
    "Littlehampton, SA, 5250",
    "Liverpool, NSW, 2170",
    "Lobethal, SA, 5241",
    "Lochinvar, NSW, 2321",
    "Lock, SA, 5633",
    "Lockhart, NSW, 2656",
    "Loftus, NSW, 2232",
    "Logan Central, QLD, 4114",
    "Logan Reserve, QLD, 4133",
    "Logan Village, QLD, 4207",
    "Loganholme, QLD, 4129",
    "Loganlea, QLD, 4131",
    "Londonderry, NSW, 2753",
    "Long Flat, NSW, 2446",
    "Long Gully, VIC, 3550",
    "Long Jetty, NSW, 2261",
    "Longford, TAS, 7301",
    "Longreach, QLD, 4730",
    "Longueville, NSW, 2066",
    "Longwarry, VIC, 3816",
    "Lonsdale, SA, 5160",
    "Lorn, NSW, 2320",
    "Lorne, VIC, 3232",
    "Lota, QLD, 4179",
    "Louth, NSW, 2840",
    "Lower Plenty, VIC, 3093",
    "Lowood, QLD, 4311",
    "Loxton, SA, 5333",
    "Lucas, VIC, 3350",
    "Lucinda, QLD, 4850",
    "Lucindale, SA, 5272",
    "Luddenham, NSW, 2745",
    "Lugarno, NSW, 2210",
    "Lurnea, NSW, 2170",
    "Lutana, TAS, 7009",
    "Lutwyche, QLD, 4030",
    "Lynbrook, VIC, 3975",
    "Lyndhurst, NSW, 2797",
    "Lyndhurst, VIC, 3975",
    "Lyndoch, SA, 5351",
    "Lyneham, ACT, 2602",
    "Lyrup, SA, 5343",
    "Macclesfield, SA, 5153",
    "Mackay Harbour, QLD, 4740",
    "Mackay North, QLD, 4740",
    "Mackay South, QLD, 4740",
    "Mackay West, QLD, 4740",
    "Mackay, QLD, 4740",
    "Macksville, NSW, 2447",
    "Maclean, NSW, 2463",
    "Macleay Island, QLD, 4184",
    "Macleod, VIC, 3085",
    "Macquarie Fields, NSW, 2564",
    "Macquarie Park, NSW, 2113",
    "Macquarie, ACT, 2614",
    "Maddingley, VIC, 3340",
    "Maffra, VIC, 3860",
    "Magill, SA, 5072",
    "Magnetic Island, QLD, 4819",
    "Maidenwell, QLD, 4615",
    "Maidstone, VIC, 3012",
    "Main Beach, QLD, 4217",
    "Maitland, NSW, 2320",
    "Maitland, SA, 5573",
    "Malabar, NSW, 2036",
    "Malanda, QLD, 4885",
    "Maldon, VIC, 3463",
    "Maleny, QLD, 4552",
    "Mallacoota, VIC, 3892",
    "Mallala, SA, 5502",
    "Malua Bay, NSW, 2536",
    "Malvern East, VIC, 3145",
    "Malvern, SA, 5062",
    "Malvern, VIC, 3144",
    "Malwala, NSW, 2647",
    "Manangatang, VIC, 3546",
    "Mandurama, NSW, 2792",
    "Mango Hill, QLD, 4509",
    "Mangrove Mountain, NSW, 2250",
    "Manildra, NSW, 2865",
    "Manilla, NSW, 2346",
    "Manly Vale, NSW, 2093",
    "Manly West, QLD, 4179",
    "Manly, NSW, 2095",
    "Manly, QLD, 4179",
    "Manning Point, NSW, 2430",
    "Mannum, SA, 5238",
    "Manoora, QLD, 4870",
    "Mansfield Park, SA, 5012",
    "Mansfield, QLD, 4122",
    "Mansfield, VIC, 3722",
    "Manuka, ACT, 2603",
    "Manunda, QLD, 4870",
    "Marayong, NSW, 2148",
    "Marburg, QLD, 4346",
    "Marcoola Beach, QLD, 4564",
    "Marcoola, QLD, 4564",
    "Marden, SA, 5070",
    "Mareeba, QLD, 4880",
    "Margate, QLD, 4019",
    "Margate, TAS, 7054",
    "Marian, QLD, 4753",
    "Maribyrnong, VIC, 3032",
    "Marion, SA, 5043",
    "Marks Point, NSW, 2280",
    "Marla, SA, 5724",
    "Marlborough, QLD, 4705",
    "Marleston, SA, 5033",
    "Marlo, VIC, 3888",
    "Marlow Lagoon, NT, 0830",
    "Maroochydore, QLD, 4558",
    "Maroochydore, QLD, 4560",
    "Maroubra Junction, NSW, 2035",
    "Maroubra South, NSW, 2035",
    "Maroubra, NSW, 2035",
    "Marrakai, NT, 0822",
    "Marrar, NSW, 2652",
    "Marrara, NT, 0812",
    "Marree, SA, 5733",
    "Marrickvale, NSW, 2204",
    "Marrickville, NSW, 2204",
    "Marsden Park, NSW, 2765",
    "Marsden, QLD, 4132",
    "Marulan, NSW, 2579",
    "Maryborough, QLD, 4650",
    "Maryborough, VIC, 3465",
    "Maryland, NSW, 2287",
    "Maryville, NSW, 2293",
    "Mascot, NSW, 2020",
    "Mataranka, NT, 0852",
    "Mathoura, NSW, 2710",
    "Matraville, NSW, 2036",
    "Maudsland, QLD, 4210",
    "Mawson Lakes, SA, 5095",
    "Mawson, ACT, 2607",
    "Mayfield East, NSW, 2304",
    "Mayfield West, NSW, 2304",
    "Mayfield, NSW, 2304",
    "Maylands, SA, 5069",
    "McCrae, VIC, 3938",
    "McDowall, QLD, 4053",
    "McGraths Hill, NSW, 2756",
    "McKinnon, VIC, 3204",
    "McLaren Vale, SA, 5171",
    "McMahons Point, NSW, 2060",
    "Mckellar, ACT, 2617",
    "Meadow Heights, VIC, 3048",
    "Meadowbank, NSW, 2114",
    "Meadowbrook, QLD, 4131",
    "Meadows, SA, 5201",
    "Meandarra, QLD, 4422",
    "Medowie, NSW, 2318",
    "Melbourne, VIC, 3000",
    "Melbourne, VIC, 3004",
    "Melrose, SA, 5483",
    "Melton South, VIC, 3338",
    "Melton West, VIC, 3337",
    "Melton, VIC, 3337",
    "Menai, NSW, 2234",
    "Menangle Park, NSW, 2563",
    "Menangle, NSW, 2568",
    "Mendooran, NSW, 2842",
    "Menindee, NSW, 2879",
    "Meningie, SA, 5264",
    "Mentone, VIC, 3194",
    "Merbein, VIC, 3002",
    "Merbein, VIC, 3505",
    "Meredith, VIC, 3333",
    "Merewether, NSW, 2291",
    "Meridan Plains, QLD, 4551",
    "Merimbula, NSW, 2548",
    "Merinda, QLD, 4805",
    "Meringandan, QLD, 4352",
    "Merlynston, VIC, 3058",
    "Mermaid Beach, QLD, 4218",
    "Mermaid Waters, QLD, 4218",
    "Mernda, VIC, 3754",
    "Merrimac, QLD, 4226",
    "Merriwa, NSW, 2329",
    "Merrylands West, NSW, 2160",
    "Merrylands, NSW, 2160",
    "Merton, VIC, 3715",
    "Metford, NSW, 2323",
    "Metung, VIC, 3904",
    "Miami, QLD, 4220",
    "Mickleham, VIC, 3064",
    "Middle Camberwell, VIC, 3124",
    "Middle Park, QLD, 4074",
    "Middle Park, VIC, 3206",
    "Middlemount, QLD, 4746",
    "Middleton, SA, 5213",
    "Midge Point, QLD, 4799",
    "Midway Point, TAS, 7171",
    "Milang, SA, 5256",
    "Mildura, VIC, 3500",
    "Mile End, SA, 5031",
    "Miles, QLD, 4415",
    "Mill Park, VIC, 3082",
    "Mill Park, VIC, 3752",
    "Miller, NSW, 2168",
    "Millers Point, NSW, 2000",
    "Millicent, SA, 5280",
    "Millmerran, QLD, 4357",
    "Millthorpe, NSW, 2798",
    "Milperra, NSW, 2214",
    "Milton, NSW, 2538",
    "Milton, QLD, 4064",
    "Minchinbury, NSW, 2770",
    "Miners Rest, VIC, 3352",
    "Minlaton, SA, 5575",
    "Minmi, NSW, 2287",
    "Minnipa, SA, 5654",
    "Minto, NSW, 2566",
    "Minyama, QLD, 4575",
    "Miranda, NSW, 2228",
    "Mirani, QLD, 4754",
    "Mirboo North, VIC, 3871",
    "Miriam Vale, QLD, 4677",
    "Mission Beach, QLD, 4852",
    "Mitcham, SA, 5062",
    "Mitcham, VIC, 3132",
    "Mitchell Park, SA, 5043",
    "Mitchell, QLD, 4465",
    "Mitchelton, QLD, 4053",
    "Mittagong, NSW, 2575",
    "Moama, NSW, 2731",
    "Moana, SA, 5169",
    "Modbury Heights, SA, 5092",
    "Modbury North, SA, 5092",
    "Modbury, SA, 5092",
    "Moe, VIC, 3825",
    "Moffat Beach, QLD, 4551",
    "Moggill, QLD, 4070",
    "Molendinar, QLD, 4214",
    "Mollymook, NSW, 2539",
    "Molong, NSW, 2866",
    "Mona Vale, NSW, 2103",
    "Monash, SA, 5342",
    "Monbulk, VIC, 3793",
    "Mont Albert, VIC, 3127",
    "Montmorency, VIC, 3094",
    "Monto, QLD, 4630",
    "Montrose, VIC, 3765",
    "Montville, QLD, 4560",
    "Mooball, NSW, 2483",
    "Mooloolaba, QLD, 4557",
    "Mooloolah, QLD, 4553",
    "Moonah, TAS, 7009",
    "Moonee Beach, NSW, 2450",
    "Moonee Ponds, VIC, 3039",
    "Mooney Mooney, NSW, 2083",
    "Moonta, SA, 5558",
    "Moorabbin Airport, VIC, 3194",
    "Moorabbin, VIC, 3189",
    "Moore Park, QLD, 4670",
    "Moorebank, NSW, 2170",
    "Mooroobool, QLD, 4870",
    "Moorooka, QLD, 4105",
    "Mooroolbark, VIC, 3138",
    "Mooroopna, VIC, 3629",
    "Moranbah, QLD, 4744",
    "Morayfield, QLD, 4506",
    "Mordialloc, VIC, 3195",
    "Moree, NSW, 2400",
    "Morgan, SA, 5320",
    "Morisset, NSW, 2264",
    "Morningside, QLD, 4170",
    "Mornington, TAS, 7018",
    "Mornington, VIC, 3931",
    "Morpeth, NSW, 2321",
    "Morphett Vale, SA, 5162",
    "Morphettville, SA, 5043",
    "Mortdale, NSW, 2223",
    "Mortlake, NSW, 2137",
    "Mortlake, VIC, 3272",
    "Moruya, NSW, 2537",
    "Morven, QLD, 4468",
    "Morwell, VIC, 3840",
    "Mosman, NSW, 2088",
    "Moss Vale, NSW, 2577",
    "Mossman, QLD, 4873",
    "Moulamein, NSW, 2733",
    "Mount Alford, QLD, 4310",
    "Mount Annan, NSW, 2567",
    "Mount Barker, SA, 5251",
    "Mount Clear, VIC, 3350",
    "Mount Colah, NSW, 2079",
    "Mount Compass, SA, 5210",
    "Mount Coolum, QLD, 4573",
    "Mount Crosby, QLD, 4306",
    "Mount Direction, TAS, 7252",
    "Mount Druitt, NSW, 2770",
    "Mount Eliza, VIC, 3930",
    "Mount Evelyn, VIC, 3796",
    "Mount Gambier, SA, 5290",
    "Mount Gambier, SA, 5291",
    "Mount Garnet, QLD, 4872",
    "Mount Gravatt, QLD, 4122",
    "Mount Hutton, NSW, 2290",
    "Mount Isa, QLD, 4825",
    "Mount Kembla, NSW, 2526",
    "Mount Larcom, QLD, 4695",
    "Mount Lofty, QLD, 4350",
    "Mount Louisa, QLD, 4814",
    "Mount Martha, VIC, 3934",
    "Mount Molloy, QLD, 4871",
    "Mount Morgan, QLD, 4714",
    "Mount Ommaney, QLD, 4074",
    "Mount Perry, QLD, 4671",
    "Mount Pleasant, SA, 5235",
    "Mount Pritchard, NSW, 2170",
    "Mount Schank, SA, 5291",
    "Mount Sheridan, QLD, 4868",
    "Mount Tamborine, QLD, 4272",
    "Mount Torrens, SA, 5244",
    "Mount Victoria, NSW, 2786",
    "Mount Warning, NSW, 2484",
    "Mount Warren Park, QLD, 4207",
    "Mount Waverley, VIC, 3149",
    "Mountain Creek, QLD, 4557",
    "Moura, QLD, 4718",
    "Mourilyan, QLD, 4858",
    "Mowbray, TAS, 7248",
    "Mt Larcom, QLD, 4695",
    "Mudgee, NSW, 2850",
    "Mudgeeraba, QLD, 4213",
    "Mudjimba, QLD, 4564",
    "Muirhead, NT, 0810",
    "Mulgoa, NSW, 2745",
    "Mulgowie, QLD, 4341",
    "Mulgrave, VIC, 3170",
    "Mullumbimby, NSW, 2482",
    "Mulwala, NSW, 2647",
    "Mundingburra, QLD, 4812",
    "Mundubbera, QLD, 4626",
    "Mungindi, NSW, 2406",
    "Munno Para West, SA, 5115",
    "Munno Para, SA, 5115",
    "Munno Parra West, SA, 5115",
    "Murarrie, QLD, 4172",
    "Murchison East, VIC, 3610",
    "Murgon, QLD, 4605",
    "Murphys Creek, QLD, 4352",
    "Murray Bridge East, SA, 5253",
    "Murray Bridge, SA, 5253",
    "Murray Bridge, SA, 5254",
    "Murray Downs, NSW, 2734",
    "Murrumba Downs, QLD, 4503",
    "Murrumbateman, NSW, 2582",
    "Murrumbeena, VIC, 3163",
    "Murrumburrah, NSW, 2587",
    "Murrurundi, NSW, 2338",
    "Murtoa, VIC, 3390",
    "Murwillumbah, NSW, 2484",
    "Muswellbrook, NSW, 2333",
    "Mylestrom, NSW, 2454",
    "Myponga, SA, 5202",
    "Myrtle Bank, SA, 5064",
    "Myrtleford, VIC, 3737",
    "Myrtleford, VIC, 3958",
    "Nabiac, NSW, 2312",
    "Nagambie, VIC, 3608",
    "Nairne, SA, 5252",
    "Nambour Heights, QLD, 4560",
    "Nambour, QLD, 4560",
    "Nambucca Heads, NSW, 2448",
    "Nanango, QLD, 4615",
    "Nanum, QLD, 4874",
    "Nar Nar Goon, VIC, 3812",
    "Naracoorte, SA, 5271",
    "Narangba, QLD, 4504",
    "Narellan, NSW, 2567",
    "Narooma, NSW, 2546",
    "Narrabeen, NSW, 2101",
    "Narrabri West, NSW, 2390",
    "Narrabri, NSW, 2390",
    "Narrabundah, ACT, 2604",
    "Narrandera, NSW, 2700",
    "Narraweena, NSW, 2099",
    "Narre Warren North, VIC, 3805",
    "Narre Warren South, VIC, 3805",
    "Narre Warren, VIC, 3805",
    "Narromine, NSW, 2821",
    "Narwee, NSW, 2209",
    "Nathalia, VIC, 3638",
    "Nathan, QLD, 4111",
    "Nebo, QLD, 4742",
    "Neerim South, VIC, 3831",
    "Nelson Bay, NSW, 2315",
    "Nemingah, NSW, 2340",
    "Nerang, QLD, 4211",
    "Neutral Bay, NSW, 2089",
    "New Auckland, QLD, 4680",
    "New Berrima, NSW, 2577",
    "New Brighton, NSW, 2483",
    "New Farm, QLD, 4005",
    "New Gisborne, VIC, 3438",
    "New Lambton Heights, NSW, 2305",
    "New Lambton, NSW, 2305",
    "New Norfolk, TAS, 7140",
    "New Park, NSW, 2474",
    "New Town, TAS, 7008",
    "Newborough, VIC, 3825",
    "Newcastle West, NSW, 2302",
    "Newcastle, NSW, 2300",
    "Newcomb, VIC, 3219",
    "Newington, NSW, 2127",
    "Newmarket, QLD, 4051",
    "Newnham, TAS, 7248",
    "Newport Beach, NSW, 2106",
    "Newport, NSW, 2106",
    "Newport, QLD, 4020",
    "Newport, VIC, 3015",
    "Newstead, QLD, 4006",
    "Newstead, TAS, 7250",
    "Newton, SA, 5074",
    "Newtown, NSW, 2042",
    "Newtown, QLD, 4350",
    "Newtown, SA, 5074",
    "Newtown, TAS, 7008",
    "Newtown, VIC, 3220",
    "Nhill, VIC, 3418",
    "Nhulunbuy, NT, 0880",
    "Niagara Park, NSW, 2250",
    "Nicholls, ACT, 2913",
    "Nichols Point, VIC, 3501",
    "Niddrie, VIC, 3042",
    "Nightcliff, NT, 0810",
    "Nilligen, NSW, 2536",
    "Nimbin, NSW, 2480",
    "Nimmitabel, NSW, 2631",
    "Ningi, QLD, 4511",
    "Noarlung Centre, SA, 5168",
    "Noarlunga Centre, SA, 5168",
    "Noarlunga Downs, SA, 5168",
    "Nobby'S Beach, QLD, 4218",
    "Nobby, QLD, 4360",
    "Noble Park, VIC, 3174",
    "Noble Park, VIC, 3175",
    "Noonamah, NT, 0837",
    "Noosa Heads, QLD, 4567",
    "Noosaville, QLD, 4566",
    "Norah Head, NSW, 2263",
    "Norlane, VIC, 3214",
    "Norman Gardens, QLD, 4701",
    "Norman Park, QLD, 4170",
    "Normanhurst, NSW, 2076",
    "Normanton, QLD, 4890",
    "Normanville, SA, 5204",
    "North Adelaide, SA, 5006",
    "North Balgowlah, NSW, 2093",
    "North Bondi, NSW, 2026",
    "North Booval, QLD, 4304",
    "North Bundaberg, QLD, 4670",
    "North Cairns, QLD, 4870",
    "North Curl Curl, NSW, 2099",
    "North Epping, NSW, 2121",
    "North Haven, NSW, 2443",
    "North Haven, SA, 5018",
    "North Hobart, TAS, 7000",
    "North Ipswich, QLD, 4305",
    "North Kirra, QLD, 4225",
    "North Lakes, QLD, 4509",
    "North Lambton, NSW, 2299",
    "North Lismore, NSW, 2480",
    "North Mackay, QLD, 4740",
    "North Manly, NSW, 2100",
    "North Melbourne, VIC, 3051",
    "North Nowra, NSW, 2541",
    "North Richmond, NSW, 2754",
    "North Rockhampton, QLD, 4701",
    "North Rocks, NSW, 2151",
    "North Rothbury, NSW, 2335",
    "North Ryde, NSW, 2113",
    "North Shields, SA, 5607",
    "North Shore, QLD, 4818",
    "North St Marys, NSW, 2760",
    "North Star, NSW, 2408",
    "North Strathfield, NSW, 2137",
    "North Sydney, NSW, 2055",
    "North Sydney, NSW, 2060",
    "North Tamborine, QLD, 4272",
    "North Ward, QLD, 4810",
    "North Wollongong, NSW, 2500",
    "Northbridge, NSW, 2063",
    "Northcote, VIC, 3070",
    "Northfield, SA, 5085",
    "Northgate, QLD, 4013",
    "Northgate, SA, 5085",
    "Northmead, NSW, 2152",
    "Norwood, SA, 5067",
    "Norwood, TAS, 7250",
    "Notting Hill, VIC, 3168",
    "Novar Gardens, SA, 5040",
    "Nowra, NSW, 2541",
    "Nubeena, TAS, 7184",
    "Nudgee, QLD, 4014",
    "Nulkaba, NSW, 2325",
    "Numurkah, VIC, 3636",
    "Nunawading, VIC, 3131",
    "Nundah, QLD, 4012",
    "Nundle, NSW, 2340",
    "Nuriootpa, SA, 5355",
    "Nyah West, VIC, 3595",
    "Nyngan, NSW, 2825",
    "O'Connor, ACT, 2602",
    "O'Halloran Hill, SA, 5158",
    "O'Sullivan Beach, SA, 5166",
    "Oak Flats, NSW, 2529",
    "Oak Park, VIC, 3046",
    "Oakbank, SA, 5243",
    "Oakdale, NSW, 2570",
    "Oakden, SA, 5086",
    "Oakey, QLD, 4401",
    "Oaklands Park, SA, 5046",
    "Oaklands, NSW, 2646",
    "Oakleigh East, VIC, 3166",
    "Oakleigh South, VIC, 3167",
    "Oakleigh, VIC, 3166",
    "Oatlands, NSW, 2117",
    "Oatley, NSW, 2223",
    "Oberon, NSW, 2787",
    "Ocean Grove, VIC, 3226",
    "Ocean Shores, NSW, 2483",
    "Officer, VIC, 3809",
    "Old Bar, NSW, 2430",
    "Old Noarlunga, SA, 5168",
    "Olinda, VIC, 3788",
    "Olympic Dam, SA, 5725",
    "Olympic Park, NSW, 2127",
    "One Tree Hill, SA, 5114",
    "Oonoonba, QLD, 4811",
    "Ooralea, QLD, 4740",
    "Oran Park, NSW, 2570",
    "Orange, NSW, 2800",
    "Orbost, VIC, 3888",
    "Orford, TAS, 7190",
    "Ormeau, QLD, 4208",
    "Ormiston, QLD, 4160",
    "Ormond, VIC, 3204",
    "Orroroo, SA, 5431",
    "Osborne Park, WA, 6017",
    "Osborne, SA, 5017",
    "Ourimbah, NSW, 2258",
    "Ouyen, VIC, 3490",
    "Ovingham, SA, 5082",
    "Owen, SA, 5460",
    "Oxenford, QLD, 4210",
    "Oxley, QLD, 4075",
    "Oyster Bay, NSW, 2225",
    "Pacific Palms, NSW, 2428",
    "Pacific Paradise, QLD, 4564",
    "Pacific Pines, QLD, 4211",
    "Paddington, NSW, 2021",
    "Paddington, QLD, 4064",
    "Padstow, NSW, 2211",
    "Page, ACT, 2614",
    "Pagewood, NSW, 2035",
    "Pakenham, VIC, 3810",
    "Pallamallawa, NSW, 2399",
    "Palm Beach, NSW, 2108",
    "Palm Beach, QLD, 4221",
    "Palm Cove, QLD, 4879",
    "Palmerston, NT, 0830",
    "Palmview, QLD, 4553",
    "Palmwoods, QLD, 4555",
    "Pambula, NSW, 2549",
    "Panania, NSW, 2213",
    "Panton Hill, VIC, 3759",
    "Para Hills West, SA, 5096",
    "Para Hills, SA, 5096",
    "Para Vista, SA, 5093",
    "Paracombe, SA, 5132",
    "Paradise Point, QLD, 4216",
    "Paradise, SA, 5075",
    "Parafield Airport, SA, 5106",
    "Parafield Gardens, SA, 5107",
    "Paralowie, SA, 5108",
    "Parap, NT, 0820",
    "Paringa, SA, 5340",
    "Park Avenue, QLD, 4701",
    "Park Holme, SA, 5043",
    "Park Ridge, QLD, 4125",
    "Parkdale, VIC, 3195",
    "Parkes, NSW, 2870",
    "Parkhurst, QLD, 4702",
    "Parkinson, QLD, 4115",
    "Parkside, SA, 5063",
    "Parkville, VIC, 3052",
    "Parkwood, QLD, 4214",
    "Parramatta, NSW, 2150",
    "Parrearra, QLD, 4575",
    "Pasadena, SA, 5042",
    "Pascoe Vale South, VIC, 3044",
    "Pascoe Vale, VIC, 3044",
    "Paterson, NSW, 2421",
    "Patterson Lakes, VIC, 3197",
    "Paxton, NSW, 2325",
    "Paynesville, VIC, 3880",
    "Peak Crossing, QLD, 4306",
    "Peak Hill, NSW, 2869",
    "Peakhurst, NSW, 2210",
    "Pearcedale, VIC, 3912",
    "Pelican Flat, NSW, 2281",
    "Pelican Waters, QLD, 4551",
    "Pemulwuy, NSW, 2145",
    "Pendle Hill, NSW, 2145",
    "Penfield, SA, 5121",
    "Penguin, TAS, 7316",
    "Pennant Hills, NSW, 2120",
    "Penneshaw, SA, 5222",
    "Pennington, SA, 5013",
    "Penola, SA, 5277",
    "Penong, SA, 5690",
    "Penrith South, NSW, 2750",
    "Penrith, NSW, 2750",
    "Penshurst West, NSW, 2222",
    "Penshurst, NSW, 2222",
    "Penshurst, VIC, 3289",
    "Peregian Beach, QLD, 4573",
    "Peregian Springs, QLD, 4573",
    "Perisher Valley, NSW, 2624",
    "Perth, TAS, 7300",
    "Perthville, NSW, 2795",
    "Peterborough, SA, 5422",
    "Peterhead, SA, 5016",
    "Petersham, NSW, 2049",
    "Petrie Terrace, QLD, 4000",
    "Petrie, QLD, 4502",
    "Phillip Bay, NSW, 2036",
    "Phillip, ACT, 2606",
    "Pialba, QLD, 4655",
    "Picton, NSW, 2571",
    "Pilliga, NSW, 2388",
    "Pimba, SA, 5720",
    "Pimlico, QLD, 4812",
    "Pimpama, QLD, 4209",
    "Pine Creek, NT, 0847",
    "Pinkenba, QLD, 4008",
    "Pinnaroo, SA, 5304",
    "Pitt Town, NSW, 2756",
    "Pittsworth, QLD, 4356",
    "Plainland, QLD, 4341",
    "Planlands, QLD, 4740",
    "Plumpton, NSW, 2761",
    "Plympton Park, SA, 5038",
    "Plympton, SA, 5038",
    "Point Cook, VIC, 3030",
    "Point Lookout, QLD, 4183",
    "Point Vernon, QLD, 4655",
    "Pokolbin, NSW, 2320",
    "Pomona, QLD, 4568",
    "Pooncarie, NSW, 2648",
    "Pooraka, SA, 5095",
    "Port Adelaide, SA, 5015",
    "Port Augusta West, SA, 5700",
    "Port Augusta, SA, 5700",
    "Port Broughton, SA, 5522",
    "Port Campbell, VIC, 3269",
    "Port Clinton, SA, 5570",
    "Port Douglas, QLD, 4877",
    "Port Elliot, SA, 5212",
    "Port Fairy, VIC, 3284",
    "Port Germein, SA, 5495",
    "Port Hughes, SA, 5558",
    "Port Kembla, NSW, 2505",
    "Port Kenny, SA, 5671",
    "Port Lincoln, SA, 5606",
    "Port Macdonnell, SA, 5291",
    "Port Macquarie, NSW, 2444",
    "Port Melbourne, VIC, 3207",
    "Port Noarlunga, SA, 5167",
    "Port Pirie West, SA, 5540",
    "Port Pirie, SA, 5540",
    "Port Stephens, NSW, 2315",
    "Port Victoria, SA, 5573",
    "Port Vincent, SA, 5581",
    "Port Wakefield, SA, 5550",
    "Portarlington, VIC, 3223",
    "Portland, NSW, 2847",
    "Portland, VIC, 3305",
    "Portsea, VIC, 3944",
    "Potts Point, NSW, 2011",
    "Pottsville Beach, NSW, 2489",
    "Pottsville, NSW, 2489",
    "Prahran, VIC, 3181",
    "Prairiewood, NSW, 2176",
    "Preston West, VIC, 3072",
    "Preston, VIC, 3072",
    "Prestons, NSW, 2170",
    "Price, SA, 5570",
    "Primbee, NSW, 2502",
    "Proserpine, QLD, 4800",
    "Prospect, NSW, 2148",
    "Prospect, SA, 5082",
    "Prospect, TAS, 7250",
    "Pullenvale, QLD, 4069",
    "Punchbowl, NSW, 2196",
    "Putney, NSW, 2112",
    "Pymble, NSW, 2073",
    "Pyrmont, NSW, 2009",
    "Quakers Hill, NSW, 2763",
    "Quambone, NSW, 2831",
    "Quandialla, NSW, 2721",
    "Quarry Hill, VIC, 3550",
    "Queanbeyan East, NSW, 2620",
    "Queanbeyan West, NSW, 2620",
    "Queanbeyan, NSW, 2620",
    "Queenscliff, VIC, 3225",
    "Queenstown, SA, 5014",
    "Queenstown, TAS, 7467",
    "Quilpie, QLD, 4480",
    "Quinalow, QLD, 4403",
    "Quirindi, NSW, 2343",
    "Quorn, SA, 5433",
    "Raby, NSW, 2566",
    "Raceview, QLD, 4305",
    "Raglan, QLD, 4697",
    "Rainbow Beach, QLD, 4581",
    "Ramsgate, NSW, 2217",
    "Rand, NSW, 2642",
    "Randwick, NSW, 2031",
    "Rapid Creek, NT, 0810",
    "Rasmussen, QLD, 4815",
    "Rathdowney, QLD, 4287",
    "Rathmines, NSW, 2283",
    "Ravenshoe, QLD, 4888",
    "Ravenswood, QLD, 4816",
    "Ravenswood, TAS, 7250",
    "Raymond Terrace, NSW, 2324",
    "Red Cliffs, VIC, 3496",
    "Red Hill, QLD, 4059",
    "Red Rock, NSW, 2456",
    "Redan, VIC, 3350",
    "Redbank Plains, QLD, 4301",
    "Redbank, QLD, 4301",
    "Redcliffe, QLD, 4020",
    "Redfern, NSW, 2016",
    "Redhead, NSW, 2290",
    "Redland Bay, QLD, 4165",
    "Redlynch, QLD, 4870",
    "Redwood Park, SA, 5097",
    "Reedy Creek, QLD, 4227",
    "Regency Downs, QLD, 4341",
    "Regency Park, SA, 5010",
    "Regents Park, NSW, 2143",
    "Regents Park, QLD, 4118",
    "Regentville, NSW, 2745",
    "Region",
    "Renmark, SA, 5341",
    "Research, VIC, 3095",
    "Reservoir, VIC, 3073",
    "Revesby Heights, NSW, 2212",
    "Revesby, NSW, 2212",
    "Reynella, SA, 5161",
    "Rhodes, NSW, 2138",
    "Richlands, QLD, 4077",
    "Richmond, NSW, 2753",
    "Richmond, QLD, 4822",
    "Richmond, SA, 5033",
    "Richmond, TAS, 7025",
    "Richmond, VIC, 3121",
    "Riddells Creek, VIC, 3431",
    "Ridleyton, SA, 5008",
    "Ringwood East, VIC, 3135",
    "Ringwood North, VIC, 3134",
    "Ringwood, VIC, 3134",
    "Risdon Vale, TAS, 7016",
    "Riverside, TAS, 7250",
    "Riverstone, NSW, 2765",
    "Riverton, SA, 5412",
    "Riverwood, NSW, 2210",
    "Rivett, ACT, 2611",
    "Roadvale, QLD, 4310",
    "Robe, SA, 5276",
    "Robertson, NSW, 2577",
    "Robina, QLD, 4226",
    "Robina, QLD, 4230",
    "Robinvale, VIC, 3549",
    "Rochedale South, QLD, 4123",
    "Rochedale, QLD, 4123",
    "Rochester, VIC, 3561",
    "Rockdale, NSW, 2216",
    "Rockhampton, QLD, 4700",
    "Rocklea, QLD, 4106",
    "Rocky Cape, TAS, 7321",
    "Rokeby, TAS, 7019",
    "Rollingstone, QLD, 4816",
    "Roma, QLD, 4455",
    "Romsey, VIC, 3434",
    "Rooty Hill, NSW, 2766",
    "Ropes Crossing, NSW, 2760",
    "Rosanna, VIC, 3084",
    "Rose Bay North, NSW, 2030",
    "Rose Bay, NSW, 2029",
    "Rosebery, NSW, 2018",
    "Rosebery, TAS, 7470",
    "Rosebud West, VIC, 3940",
    "Rosebud, VIC, 3939",
    "Rosedale, VIC, 3847",
    "Rosehill, NSW, 2142",
    "Roselands, NSW, 2196",
    "Rosemeadow, NSW, 2560",
    "Roseville, NSW, 2069",
    "Rosewater, SA, 5013",
    "Rosewood, NSW, 2652",
    "Rosewood, QLD, 4340",
    "Roseworthy, SA, 5371",
    "Rosny Park, TAS, 7018",
    "Rosslea, QLD, 4812",
    "Rossmore, NSW, 2557",
    "Rostrevor, SA, 5073",
    "Rothwell, QLD, 4022",
    "Rouse Hill, NSW, 2155",
    "Rowville, VIC, 3178",
    "Roxburgh Park, VIC, 3064",
    "Roxby Downs, SA, 5725",
    "Royal Park, SA, 5014",
    "Royston Park, SA, 5070",
    "Rozelle, NSW, 2039",
    "Rubyvale, QLD, 4702",
    "Runaway Bay, QLD, 4216",
    "Runcorn, QLD, 4113",
    "Rural View, QLD, 4740",
    "Ruse, NSW, 2560",
    "Rushworth, VIC, 3612",
    "Russell Island, QLD, 4184",
    "Rutherford, NSW, 2320",
    "Rutherglen, VIC, 3685",
    "Rydalmere, NSW, 2116",
    "Ryde, NSW, 2112",
    "Rye, VIC, 3941",
    "Rylstone, NSW, 2849",
    "Safety Beach, NSW, 2456",
    "Saint Helena, VIC, 3088",
    "Salamander Bay, NSW, 2317",
    "Sale, VIC, 3850",
    "Salisbury Downs, SA, 5108",
    "Salisbury East, SA, 5109",
    "Salisbury Heights, SA, 5109",
    "Salisbury North, SA, 5108",
    "Salisbury Plain, SA, 5109",
    "Salisbury, QLD, 4107",
    "Salisbury, SA, 5108",
    "Samford Village, QLD, 4520",
    "Samford, QLD, 4520",
    "San Remo, NSW, 2262",
    "San Remo, VIC, 3925",
    "Sanctuary Cove, QLD, 4212",
    "Sanctuary Point, NSW, 2540",
    "Sandgate, QLD, 4017",
    "Sandringham, VIC, 3191",
    "Sandstone Point, QLD, 4511",
    "Sandy Bay, TAS, 7005",
    "Sandy Hollow, NSW, 2333",
    "Sans Souci, NSW, 2219",
    "Saratoga, NSW, 2251",
    "Sarina Beach, QLD, 4737",
    "Sarina, QLD, 4737",
    "Sawtell, NSW, 2452",
    "Scarborough, NSW, 2515",
    "Scarborough, QLD, 4020",
    "Scarness, QLD, 4655",
    "Schofields, NSW, 2762",
    "Scone, NSW, 2337",
    "Scoresby, VIC, 3179",
    "Scotts Head, NSW, 2447",
    "Scottsdale, TAS, 7260",
    "Sea Lake, VIC, 3533",
    "Seacliff Park, SA, 5049",
    "Seacliff, SA, 5049",
    "Seaford, SA, 5169",
    "Seaford, VIC, 3198",
    "Seaforth, NSW, 2092",
    "Seaforth, QLD, 4741",
    "Seaton, SA, 5023",
    "Seaview Downs, SA, 5049",
    "Sebastopol, VIC, 3356",
    "Seddon, VIC, 3011",
    "Sefton Park, SA, 5083",
    "Sefton, NSW, 2162",
    "Sellicks Hill, SA, 5174",
    "Semaphore, SA, 5019",
    "Seven Hills, NSW, 2147",
    "Sevenhill, SA, 5453",
    "Seymour, VIC, 3660",
    "Shalvey, NSW, 2770",
    "Shearwater, TAS, 7307",
    "Sheffield, TAS, 7306",
    "Sheidow Park, SA, 5158",
    "Shell Cove, NSW, 2529",
    "Shellharbour, NSW, 2529",
    "Shelly Beach, NSW, 2261",
    "Shepparton, VIC, 3630",
    "Sherwood, QLD, 4075",
    "Shoal Bay, NSW, 2315",
    "Shoalhaven Heads, NSW, 2535",
    "Shortland, NSW, 2307",
    "Silkstone, QLD, 4304",
    "Silverdale, NSW, 2752",
    "Silverwater, NSW, 2128",
    "Simpson, VIC, 3266",
    "Singleton Heights, NSW, 2330",
    "Singleton, NSW, 2330",
    "Sinnamon Park, QLD, 4073",
    "Sippy Downs, QLD, 4556",
    "Slacks Creek, QLD, 4127",
    "Slade Point, QLD, 4740",
    "Smiggin Holes, NSW, 2624",
    "Smithfield Plains, SA, 5114",
    "Smithfield, NSW, 2147",
    "Smithfield, NSW, 2164",
    "Smithfield, QLD, 4878",
    "Smithfield, SA, 5114",
    "Smiths Lake, NSW, 2428",
    "Smithton, TAS, 7330",
    "Smithtown, NSW, 2440",
    "Snowtown, SA, 5520",
    "Snug, TAS, 7054",
    "Soldiers Point, NSW, 2317",
    "Solomontown, SA, 5540",
    "Somerset, TAS, 7322",
    "Somerville, VIC, 3912",
    "Sorell, TAS, 7172",
    "Sorrento, VIC, 3943",
    "South Brisbane, QLD, 4101",
    "South Coogee, NSW, 2034",
    "South Gladstone, QLD, 4680",
    "South Grafton, NSW, 2460",
    "South Hobart, TAS, 7004",
    "South Hurstville, NSW, 2221",
    "South Kincumber, NSW, 2251",
    "South Kolan, QLD, 4670",
    "South Lismore, NSW, 2480",
    "South Mackay, QLD, 4740",
    "South Maitland, NSW, 2320",
    "South Melbourne, VIC, 3205",
    "South Morang, VIC, 3752",
    "South Murwillumbah, NSW, 2484",
    "South Plympton, SA, 5038",
    "South Tamworth, NSW, 2340",
    "South Toowoomba, QLD, 4350",
    "South Townsville, QLD, 4810",
    "South West Rocks, NSW, 2431",
    "South Windsor, NSW, 2756",
    "South Yarra, VIC, 3141",
    "Southbank, QLD, 4101",
    "Southbank, VIC, 3006",
    "Southport, QLD, 4215",
    "Southside, QLD, 4570",
    "Speers Point, NSW, 2284",
    "Spotswood, VIC, 3015",
    "Spreyton, TAS, 7310",
    "Spring Farm, NSW, 2570",
    "Spring Gully, VIC, 3550",
    "Spring Hill, QLD, 4000",
    "Spring Hill, QLD, 4004",
    "Springdale Heights, NSW, 2641",
    "Springfield Lakes, QLD, 4300",
    "Springfield, QLD, 4300",
    "Springsure, QLD, 4722",
    "Springvale South, VIC, 3172",
    "Springvale, VIC, 3171",
    "Springwood, NSW, 2777",
    "Springwood, QLD, 4127",
    "St Agnes, SA, 5097",
    "St Albans, VIC, 3021",
    "St Andrews, NSW, 2566",
    "St Arnaud, VIC, 3478",
    "St Clair, NSW, 2759",
    "St Clair, SA, 5011",
    "St George, QLD, 4487",
    "St Georges Basin, NSW, 2540",
    "St Germains, VIC, 3620",
    "St Helena, VIC, 3088",
    "St Helens, TAS, 7216",
    "St Ives, NSW, 2075",
    "St Johns Park, NSW, 2176",
    "St Kilda East, VIC, 3183",
    "St Kilda, SA, 5110",
    "St Kilda, VIC, 3182",
    "St Lawrence, QLD, 4707",
    "St Leonards, NSW, 2065",
    "St Leonards, TAS, 7250",
    "St Leonards, VIC, 3223",
    "St Lucia, QLD, 4067",
    "St Marys, NSW, 2760",
    "St Marys, SA, 5042",
    "St Marys, TAS, 7215",
    "St Morris, SA, 5068",
    "St Peters, NSW, 2044",
    "Stafford Heights, QLD, 4053",
    "Stafford, QLD, 4053",
    "Stanhope Gardens, NSW, 2768",
    "Stanmore, NSW, 2048",
    "Stansbury, SA, 5582",
    "Stanthorpe, QLD, 4380",
    "Stawell, VIC, 3380",
    "Stepney, SA, 5069",
    "Stirling North, SA, 5710",
    "Stirling, ACT, 2611",
    "Stirling, SA, 5152",
    "Stockinbingal, NSW, 2725",
    "Stockton, NSW, 2295",
    "Stockwell, SA, 5355",
    "Stones Corner, QLD, 4120",
    "Stony Creek, VIC, 3957",
    "Strahan, TAS, 7468",
    "Stratford, QLD, 4870",
    "Strathalbyn, SA, 5255",
    "Strathdale, VIC, 3550",
    "Strathfield South, NSW, 2136",
    "Strathfield, NSW, 2135",
    "Strathfieldsaye, VIC, 3551",
    "Strathmore, VIC, 3041",
    "Strathpine Centre, QLD, 4500",
    "Strathpine, QLD, 4500",
    "Streaky Bay, SA, 5680",
    "Stroud, NSW, 2425",
    "Stuart Park, NT, 0820",
    "Stuarts Point, NSW, 2441",
    "Suffolk Park, NSW, 2481",
    "Summer Hill, NSW, 2130",
    "Summerland Point, NSW, 2259",
    "Sumner, QLD, 4074",
    "Sunbury, VIC, 3429",
    "Sunnybank Hills, QLD, 4109",
    "Sunnybank, QLD, 4109",
    "Sunshine Beach, QLD, 4567",
    "Sunshine North, VIC, 3020",
    "Sunshine West, VIC, 3020",
    "Sunshine, VIC, 3020",
    "Surat, QLD, 4417",
    "Surfers Paradise, QLD, 4217",
    "Surrey Downs, SA, 5126",
    "Surrey Hills, VIC, 3127",
    "Surry Hills, NSW, 2010",
    "Sussex Inlet, NSW, 2540",
    "Sutherland, NSW, 2232",
    "Sutton, NSW, 2620",
    "Swan Hill, VIC, 3585",
    "Swan Reach, SA, 5354",
    "Swan Reach, VIC, 3903",
    "Swansea, NSW, 2281",
    "Swansea, TAS, 7190",
    "Sydenham, NSW, 2044",
    "Sydney Markets, NSW, 2129",
    "Sydney Olympic Park, NSW, 2127",
    "Sydney, NSW, 2000",
    "Sydney, NSW, 2022",
    "Sydney, NSW, 2508",
    "Sylvania Waters, NSW, 2224",
    "Sylvania, NSW, 2224",
    "Tahmoor, NSW, 2573",
    "Taigum, QLD, 4018",
    "Tailem Bend, SA, 5260",
    "Talbingo, NSW, 2720",
    "Tallai, QLD, 4213",
    "Tallangatta, VIC, 3700",
    "Tallebudgera, QLD, 4228",
    "Tambo, QLD, 4478",
    "Tamborine Mountain, QLD, 4272",
    "Taminda, NSW, 2340",
    "Tamworth, NSW, 2340",
    "Tanilba Bay, NSW, 2319",
    "Tannum Sands, QLD, 4680",
    "Tantanoola, SA, 5280",
    "Tanunda, SA, 5352",
    "Taperoo, SA, 5017",
    "Tara, QLD, 4421",
    "Tarago, NSW, 2580",
    "Taralga, NSW, 2580",
    "Tarampa, QLD, 4311",
    "Taranganba, QLD, 4703",
    "Tarcutta, NSW, 2652",
    "Taree, NSW, 2430",
    "Taren Point, NSW, 2229",
    "Taringa, QLD, 4068",
    "Tarneit, VIC, 3029",
    "Taroom, QLD, 4420",
    "Tarpeena, SA, 5277",
    "Tarragindi, QLD, 4121",
    "Tarrington, VIC, 3301",
    "Tathra, NSW, 2550",
    "Tatura, VIC, 3616",
    "Tawonga South, VIC, 3698",
    "Taylors Hill, VIC, 3037",
    "Taylors Lakes, VIC, 3038",
    "Tea Gardens, NSW, 2324",
    "Tea Tree Gully, SA, 5091",
    "Telarah, NSW, 2320",
    "Telegraph Point, NSW, 2441",
    "Telopea, NSW, 2117",
    "Temora, NSW, 2666",
    "Templestowe Lower, VIC, 3107",
    "Templestowe, VIC, 3106",
    "Tenambit, NSW, 2323",
    "Teneriffe, QLD, 4005",
    "Tennant Creek, NT, 0860",
    "Tenterfield, NSW, 2372",
    "Teralba, NSW, 2284",
    "Terang, VIC, 3264",
    "Terranora, NSW, 2486",
    "Terrey Hills, NSW, 2084",
    "Terrigal, NSW, 2260",
    "Tewantin, QLD, 4565",
    "Texas, QLD, 4385",
    "Thargomindah, QLD, 4492",
    "The Channon, NSW, 2480",
    "The Entrance, NSW, 2261",
    "The Gap, QLD, 4061",
    "The Junction, NSW, 2291",
    "The Oaks, NSW, 2570",
    "The Ponds, NSW, 2769",
    "The Rock, NSW, 2655",
    "The Rocks, NSW, 2000",
    "The Summit, QLD, 4377",
    "Thebarton, SA, 5031",
    "Theodore, ACT, 2905",
    "Theodore, QLD, 4719",
    "Thevenard, SA, 5690",
    "Thirlmere, NSW, 2572",
    "Thirroul, NSW, 2515",
    "Thomastown, VIC, 3074",
    "Thornbury, VIC, 3071",
    "Thorngate, SA, 5082",
    "Thornlands, QLD, 4164",
    "Thornleigh, NSW, 2120",
    "Thornton, NSW, 2322",
    "Thornton, VIC, 3712",
    "Thredbo, NSW, 2625",
    "Thurgoona, NSW, 2640",
    "Thuringowa Central, QLD, 4817",
    "Thursday Island, QLD, 4875",
    "Tiaro, QLD, 4650",
    "Tibooburra, NSW, 2880",
    "Tieri, QLD, 4709",
    "Tighes Hill, NSW, 2297",
    "Tilligerry, NSW, 2319",
    "Timboon, VIC, 3268",
    "Tin Can Bay, QLD, 4580",
    "Tinana, QLD, 4650",
    "Tingalpa, QLD, 4173",
    "Tingha, NSW, 2369",
    "Tintinara, SA, 5266",
    "Tirroan, QLD, 4671",
    "Tocumwal, NSW, 2714",
    "Tolga, QLD, 4882",
    "Tolland, NSW, 2650",
    "Tomago, NSW, 2322",
    "Tomakin, NSW, 2537",
    "Tomingley, NSW, 2869",
    "Tongala, VIC, 3621",
    "Toobeah, QLD, 4498",
    "Toogoolawah, QLD, 4313",
    "Tooleybuc, NSW, 2736",
    "Toombul, QLD, 4012",
    "Toongabbie, NSW, 2146",
    "Toongabbie, NSW, 2147",
    "Toora, VIC, 3962",
    "Tooradin, VIC, 3980",
    "Toorak, VIC, 3142",
    "Toorbul, QLD, 4510",
    "Toormina, NSW, 2452",
    "Toowomba, QLD, 4350",
    "Toowong, QLD, 4066",
    "Toowoomba, QLD, 4350",
    "Toowoon Bay, NSW, 2261",
    "Torbanlea, QLD, 4662",
    "Toronto, NSW, 2283",
    "Torquay, QLD, 4655",
    "Torquay, VIC, 3228",
    "Torrens Park, SA, 5062",
    "Torrens, ACT, 2607",
    "Torrensville, SA, 5031",
    "Torrumbarry, VIC, 3562",
    "Tottenham, NSW, 2873",
    "Toukley, NSW, 2263",
    "Townsville, QLD, 4810",
    "Townsville, QLD, 4811",
    "Townsville, QLD, 4812",
    "Towong, VIC, 3707",
    "Towradgi, NSW, 2518",
    "Trafalgar, VIC, 3824",
    "Trangie, NSW, 2823",
    "Tranmere, SA, 5073",
    "Traralgon, VIC, 3844",
    "Tregear, NSW, 2770",
    "Trentham, VIC, 3458",
    "Trevallyn, TAS, 7250",
    "Triabunna, TAS, 7190",
    "Trinity Beach, QLD, 4879",
    "Trinity Gardens, SA, 5068",
    "Trinity Park, QLD, 4879",
    "Trott Park, SA, 5158",
    "Truganina, VIC, 3029",
    "Trunding, QLD, 4874",
    "Trundle, NSW, 2875",
    "Truro, SA, 5356",
    "Tuggerah, NSW, 2259",
    "Tuggeranong, ACT, 2900",
    "Tugun, QLD, 4224",
    "Tullamarine, VIC, 3043",
    "Tullamore, NSW, 2874",
    "Tully Heads, QLD, 4854",
    "Tully, QLD, 4854",
    "Tumbarumba, NSW, 2653",
    "Tumbi Umbi, NSW, 2261",
    "Tumbulgum, NSW, 2490",
    "Tumby Bay, SA, 5605",
    "Tumut, NSW, 2720",
    "Tuncurry, NSW, 2428",
    "Tunkalilla, SA, 5203",
    "Tura Beach, NSW, 2548",
    "Turner, ACT, 2602",
    "Tuross Head, NSW, 2537",
    "Turramurra, NSW, 2074",
    "Tweed Heads South, NSW, 2486",
    "Tweed Heads, NSW, 2485",
    "Two Wells, SA, 5501",
    "Tyabb, VIC, 3913",
    "Tyalgum, NSW, 2484",
    "Tynong, VIC, 3813",
    "Ulan, NSW, 2850",
    "Ulladulla, NSW, 2539",
    "Ulmarra, NSW, 2462",
    "Ulong, NSW, 2450",
    "Ultimo, NSW, 2007",
    "Ultimo, NSW, 2037",
    "Ulverstone, TAS, 7315",
    "Umina Beach, NSW, 2257",
    "Unanderra, NSW, 2526",
    "Underdale, SA, 5032",
    "Underwood, QLD, 4119",
    "Ungarie, NSW, 2669",
    "Unley, SA, 5061",
    "Upper Burnie, TAS, 7320",
    "Upper Coomera, QLD, 4209",
    "Upper Ferntree Gully, VIC, 3156",
    "Upper Horton, NSW, 2347",
    "Upper Mount Gravatt, QLD, 4122",
    "Upwey, VIC, 3158",
    "Uralla, NSW, 2358",
    "Urana, NSW, 2645",
    "Urangan, QLD, 4655",
    "Uranquinty, NSW, 2652",
    "Urbenville, NSW, 2475",
    "Urraween, QLD, 4655",
    "Urunga, NSW, 2455",
    "Valentine, NSW, 2280",
    "Valla Beach, NSW, 2448",
    "Valley View, SA, 5093",
    "Varsity Lakes, QLD, 4227",
    "Vaucluse, NSW, 2030",
    "Veresdale, QLD, 4285",
    "Vermont South, VIC, 3133",
    "Vermont, VIC, 3133",
    "Victor Harbor, SA, 5211",
    "Victoria Point, QLD, 4165",
    "Villawood, NSW, 2163",
    "Vincent, QLD, 4814",
    "Vincentia, NSW, 2540",
    "Vineyard, NSW, 2765",
    "Virginia, NT, 0822",
    "Virginia, QLD, 4014",
    "Virginia, SA, 5120",
    "Wacol, QLD, 4076",
    "Wadalba, NSW, 2259",
    "Wagga Wagga, NSW, 2650",
    "Wahgunyah, VIC, 3687",
    "Wahroonga, NSW, 2076",
    "Waikerie, SA, 5330",
    "Waitara, NSW, 2077",
    "Wakeley, NSW, 2176",
    "Wakool, NSW, 2710",
    "Walcha, NSW, 2354",
    "Walgett, NSW, 2832",
    "Walkerston, QLD, 4751",
    "Walkerville, SA, 5081",
    "Walkley Heights, SA, 5098",
    "Walla Walla, NSW, 2659",
    "Wallabadah, NSW, 2343",
    "Wallace, VIC, 3352",
    "Wallacia, NSW, 2745",
    "Wallan, VIC, 3756",
    "Wallangarra, QLD, 4383",
    "Wallaroo, SA, 5556",
    "Wallaville, QLD, 4671",
    "Wallerawang, NSW, 2845",
    "Walloon, QLD, 4306",
    "Wallsend, NSW, 2287",
    "Wallumbilla, QLD, 4428",
    "Wamberal, NSW, 2260",
    "Wamuran, QLD, 4512",
    "Wandal, QLD, 4700",
    "Wandin North, VIC, 3139",
    "Wandoan, QLD, 4419",
    "Wandong, VIC, 3758",
    "Wangan, QLD, 4871",
    "Wangaratta West, VIC, 3677",
    "Wangaratta, VIC, 3676",
    "Wangaratta, VIC, 3677",
    "Wangi Wangi, NSW, 2267",
    "Wanguri, NT, 0810",
    "Wanniassa, ACT, 2903",
    "Wantirna South, VIC, 3152",
    "Wantirna, VIC, 3152",
    "Warabrook, NSW, 2304",
    "Waramanga, ACT, 2611",
    "Warana, QLD, 4575",
    "Waratah, NSW, 2298",
    "Warburton, VIC, 3799",
    "Wardell, NSW, 2477",
    "Wareemba, NSW, 2046",
    "Warialda, NSW, 2402",
    "Warilla, NSW, 2528",
    "Warner, QLD, 4500",
    "Warners Bay, NSW, 2282",
    "Warooka, SA, 5577",
    "Warracknabeal, VIC, 3393",
    "Warradale, SA, 5046",
    "Warragamba, NSW, 2752",
    "Warragul, VIC, 3820",
    "Warrandyte, VIC, 3113",
    "Warrawong, NSW, 2502",
    "Warren, NSW, 2824",
    "Warriewood, NSW, 2102",
    "Warrnambool, VIC, 3280",
    "Warwick Farm, NSW, 2170",
    "Warwick, QLD, 4370",
    "Wasleys, SA, 5400",
    "Watchem, VIC, 3482",
    "Waterford West, QLD, 4133",
    "Waterford, QLD, 4133",
    "Waterloo, NSW, 2017",
    "Watsonia, VIC, 3087",
    "Watsons Bay, NSW, 2030",
    "Wattle Grove, NSW, 2173",
    "Wattle Park, SA, 5066",
    "Wauchope, NSW, 2446",
    "Waurn Ponds, VIC, 3216",
    "Wavell Heights, QLD, 4012",
    "Waverley Gardens, VIC, 3170",
    "Waverley, NSW, 2024",
    "Wayville, SA, 5034",
    "Wean, NSW, 2382",
    "Wedderburn, VIC, 3518",
    "Wee Waa, NSW, 2388",
    "Weethalle, NSW, 2669",
    "Weipa, QLD, 4874",
    "Welland, SA, 5007",
    "Wellington Point, QLD, 4160",
    "Wellington, NSW, 2820",
    "Wellington, SA, 5259",
    "Wendouree, VIC, 3355",
    "Wentworth Falls, NSW, 2782",
    "Wentworth Point, NSW, 2127",
    "Wentworth, NSW, 2648",
    "Wentworthville, NSW, 2145",
    "Werri Beach, NSW, 2534",
    "Werribee, VIC, 3030",
    "Werrington, NSW, 2747",
    "Werris Creek, NSW, 2341",
    "Wesburn, VIC, 3799",
    "West Albury, NSW, 2640",
    "West Ballina, NSW, 2478",
    "West Beach, SA, 5024",
    "West Croydon, SA, 5008",
    "West End, QLD, 4101",
    "West Gladstone, QLD, 4680",
    "West Gosford, NSW, 2250",
    "West Hoxton, NSW, 2171",
    "West Ipswich, QLD, 4305",
    "West Ipswich, QLD, 4502",
    "West Kempsey, NSW, 2440",
    "West Lakes Shore, SA, 5020",
    "West Lakes, SA, 5021",
    "West Mackay, QLD, 4740",
    "West Pennant Hills, NSW, 2125",
    "West Pymble, NSW, 2073",
    "West Richmond, SA, 5033",
    "West Ryde, NSW, 2114",
    "West Tamworth, NSW, 2340",
    "West Wallsend, NSW, 2286",
    "West Wodonga, VIC, 3690",
    "West Wollongong, NSW, 2500",
    "West Wyalong, NSW, 2671",
    "Westbrook, QLD, 4350",
    "Westbury, TAS, 7303",
    "Westcourt, QLD, 4870",
    "Westgarth, VIC, 3070",
    "Westleigh, NSW, 2120",
    "Westmead, NSW, 2145",
    "Westmeadows, VIC, 3049",
    "Weston, ACT, 2611",
    "Weston, NSW, 2326",
    "Wetherill Park, NSW, 2164",
    "Whalan, NSW, 2770",
    "Wheelers Hill, VIC, 3150",
    "Whitebridge, NSW, 2290",
    "Whitemark, TAS, 7255",
    "Whitsundays, QLD, 4802",
    "Whittlesea, VIC, 3757",
    "Whitton, NSW, 2705",
    "Whyalla Norrie, SA, 5608",
    "Whyalla Playford, SA, 5600",
    "Whyalla Stuart, SA, 5608",
    "Whyalla, SA, 5600",
    "Wickham, NSW, 2293",
    "Wilberforce, NSW, 2756",
    "Wilcannia, NSW, 2836",
    "Wiley Park, NSW, 2195",
    "Willaston, SA, 5118",
    "Willawarrin, NSW, 2440",
    "Williams Landing, VIC, 3027",
    "Williamstown, SA, 5351",
    "Williamstown, VIC, 3016",
    "Willoughby, NSW, 2068",
    "Willow Tree, NSW, 2339",
    "Willunga, SA, 5172",
    "Wilmington, SA, 5485",
    "Wilsonton Heights, QLD, 4350",
    "Wilsonton, QLD, 4350",
    "Wilston, QLD, 4051",
    "Winchelsea, VIC, 3241",
    "Windale, NSW, 2306",
    "Windang, NSW, 2528",
    "Windaroo, QLD, 4207",
    "Windorah, QLD, 4481",
    "Windsor Gardens, SA, 5087",
    "Windsor, NSW, 2756",
    "Windsor, QLD, 4030",
    "Windsor, VIC, 3181",
    "Wingen, NSW, 2337",
    "Wingfield, SA, 5013",
    "Wingham, NSW, 2429",
    "Winmalee, NSW, 2777",
    "Winnellie, NT, 0820",
    "Winston Hills, NSW, 2153",
    "Winton, QLD, 4735",
    "Wirrabara, SA, 5481",
    "Wirrina Cove, SA, 5204",
    "Wisemans Ferry, NSW, 2775",
    "Wishart, QLD, 4122",
    "Withcott, QLD, 4352",
    "Wivenhoe, TAS, 7320",
    "Woden, ACT, 2606",
    "Wodonga, VIC, 3689",
    "Wodonga, VIC, 3690",
    "Wollert, VIC, 3750",
    "Wolli Creek, NSW, 2205",
    "Wollongbar, NSW, 2477",
    "Wollongong, NSW, 2500",
    "Wolumla, NSW, 2550",
    "Wombat, NSW, 2587",
    "Wondai, QLD, 4606",
    "Wongarbon, NSW, 2831",
    "Wonthaggi, VIC, 3995",
    "Woodberry, NSW, 2322",
    "Woodburn, NSW, 2472",
    "Woodcroft, NSW, 2767",
    "Woodcroft, SA, 5162",
    "Woodenbong, NSW, 2476",
    "Woodend, VIC, 3442",
    "Woodford, QLD, 4514",
    "Woodgate Beach, QLD, 4660",
    "Woodgate, QLD, 4660",
    "Woodridge, QLD, 4114",
    "Woodside, SA, 5244",
    "Woodstock, NSW, 2793",
    "Woodville North, SA, 5012",
    "Woodville Park, SA, 5011",
    "Woodville South, SA, 5011",
    "Woodville West, SA, 5011",
    "Woodville, SA, 5011",
    "Woody Point, QLD, 4019",
    "Woolamai, VIC, 3995",
    "Woolgoolga, NSW, 2456",
    "Wooli, NSW, 2462",
    "Woollahra, NSW, 2025",
    "Woolloomooloo, NSW, 2011",
    "Woolloongabba, QLD, 4102",
    "Wooloongabba, QLD, 4102",
    "Woolooware, NSW, 2230",
    "Wooloowin, QLD, 4030",
    "Woolwich, NSW, 2110",
    "Woombye, QLD, 4559",
    "Woonona East, NSW, 2517",
    "Woonona, NSW, 2517",
    "Woori Yallock, VIC, 3139",
    "Woorim, QLD, 4507",
    "Wooroolin, QLD, 4608",
    "Woree, QLD, 4868",
    "Worongary, QLD, 4213",
    "Woronora, NSW, 2517",
    "Worrigee, NSW, 2540",
    "Woy Woy, NSW, 2256",
    "Wright, ACT, 2611",
    "Wudinna, SA, 5652",
    "Wulguru, QLD, 4811",
    "Wurtulla, QLD, 4575",
    "Wy Yung, VIC, 3875",
    "Wyalong, NSW, 2671",
    "Wyangala Dam, NSW, 2808",
    "Wycheproof, VIC, 3527",
    "Wyee, NSW, 2259",
    "Wyndham Vale, VIC, 3024",
    "Wynn Vale, SA, 5127",
    "Wynnum Central, QLD, 4178",
    "Wynnum North, QLD, 4178",
    "Wynnum West, QLD, 4178",
    "Wynnum, QLD, 4178",
    "Wynyard, TAS, 7325",
    "Wyoming, NSW, 2250",
    "Wyong, NSW, 2259",
    "Wyongah, NSW, 2259",
    "Yagoona, NSW, 2199",
    "Yallourn, VIC, 3825",
    "Yamanto, QLD, 4305",
    "Yamba, NSW, 2464",
    "Yanco, NSW, 2703",
    "Yandina, QLD, 4561",
    "Yankalilla, SA, 5203",
    "Yarck, VIC, 3719",
    "Yarra Glen, VIC, 3775",
    "Yarra Junction, VIC, 3797",
    "Yarrabilba, QLD, 4207",
    "Yarram, VIC, 3971",
    "Yarraman, QLD, 4614",
    "Yarraville, VIC, 3013",
    "Yarrawonga, NSW, 2502",
    "Yarrawonga, NT, 0830",
    "Yarrawonga, VIC, 3730",
    "Yass, NSW, 2582",
    "Yatala, QLD, 4207",
    "Yea, VIC, 3717",
    "Yelarbon, QLD, 4388",
    "Yenda, NSW, 2681",
    "Yeoval, NSW, 2868",
    "Yeppoon, QLD, 4703",
    "Yerong Creek, NSW, 2642",
    "Yeronga, QLD, 4104",
    "Yetman, NSW, 2410",
    "Yinnar, VIC, 3869",
    "Yoogali, NSW, 2680",
    "Yorketown, SA, 5576",
    "Yorkeys Knob, QLD, 4878",
    "Young, NSW, 2594",
    "Youngtown, TAS, 7249",
    "Yulara, NT, 0872",
    "Yuleba, QLD, 4427",
    "Yungaburra, QLD, 4884",
    "Zeehan, TAS, 7469",
    "Zetland, NSW, 2017",
    "Zillmere, QLD, 4034",
    "Zuccoli, NT, 0832",
    "Zug, NSW, 2770",
    "Zumbra, NSW, 2770",
    "Zul obed, NSW, 2770",
]
