import React from 'react';
import '../style.scss';
import {Button, Form, Input} from 'antd';
import {getRouterDeviceDetailsAction, getTenantAction} from "../../../service-contracts/dashboard/dashboard-actions";
import {error as errorToast} from "../../../utils/toast-utils";

const RouterGetInfoForm = (props) => {
    let {setRouterData, shouldDisplayFields, setLoader, setEntries, setTenantList,setShowEntries,setErr} = props;
    const [form] = Form.useForm();

    const onGetInfoClick = async (values) => {
        await setLoader(true);
        setEntries([]);
        setShowEntries(false);
        setErr(false);
        try {
            await getRouterDeviceDetailsAction(values).then(async (resp) => {
                if (resp && resp.data && resp.data.status) {
                    setRouterData(resp.data.data)
                    if(resp.data.data.tenant === null || resp.data.data.tenant === ''){
                        await getTenantAction().then(resp => {
                            setTenantList(resp.data.data);
                        })
                    }
                }
                else{
                    setRouterData({})
                    setErr(true)
                    errorToast(resp?.data?.errors[0]?.message || "Error Occurred");
                }
            });
        } catch (error) {
            errorToast("Error Occurred");
        } finally {
            setLoader(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="router-form-wrapper" style={{width: '49%'}} data-testid={"router-get-info-form"}>
            <Form
                form={form}
                className={"router-form"}
                //layout={"inline"}
                layout="vertical"
                onFinish={onGetInfoClick}
                onFinishFailed={onFinishFailed}
            >
                <div className={"router-form-right-panel"}>
                    {/*   {
                        shouldDisplayFields?.upid ? <Form.Item label="UPID"
                                                               name="upid"
                                                               className="height-40"
                                                               rules={[
                                                                   {
                                                                       required: true,
                                                                       message: 'Enter UPID!',
                                                                   },
                                                               ]}>
                            <Input placeholder="Enter UPID"/>
                        </Form.Item> : null
                    }*/}
                    {
                        shouldDisplayFields?.routerSerialNumber ? <Form.Item label="Router Serial Number"
                                                                             name="routerSerialNumber"
                                                                             className="height-40"
                                                                             rules={[
                                                                                 {
                                                                                     required: true,
                                                                                     message: 'Enter Router Serial Number!',
                                                                                 },
                                                                             ]}>
                            <Input placeholder="Enter Router Serial Number"/>
                        </Form.Item> : null
                    }
                </div>
                <div className="get-info-btn-box">
                    <Form.Item>
                        <Button type="primary" className="primary-tab-btn" htmlType="submit"
                                OnClick={() => {
                                    setLoader(true);
                                    onGetInfoClick()
                                }}>Get Info</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
export default RouterGetInfoForm;
