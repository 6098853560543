import React from "react";

export const inspectionTableColumns = [
    {
        title: 'Inspection Link',
        //dataIndex: 'inspectionLink',
        key: 'inspectionLink',
        render: (record) => <a href={record?.inspectionDeepLink} target={"_blank"}>{record?.inspectionDeepLink}</a>,
    },
    {
        title: 'Inspection Type',
        dataIndex: 'inspectionType',
        key: 'inspectionType',
        //render: (text) => <a>{text}</a>,
    },
];
