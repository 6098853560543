import { Table } from 'antd';
import {useEffect, useState} from "react";
import './style.scss';

const PaginatedTable = ({data,loading, tableParams,handleTableChange,columns,scrollX=100,dataTestId, width="100%", toggleFilter, rowSelection, components, className,rowKey, record}) => {
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    let hideTable = toggleFilter ? "hide-table" : ''

    //get row className for dual site in site schedule
    const _getRowClassName = (_record) => {
        console.log("Record : " , record, _record)
        let _class = (record && record.length && record[0]?.upid === _record[0]?.upid) || ((record && record.upid) === (_record && _record?.upid) && record?.upid !== undefined) ? "inspection-clicked" : '';
        return _record && _record?.length > 1 || (_record.siteType === "DUAL") ? `${_class} red-row` : _record.isDeleted ? "disable-it" : `${_class}`;
    };

    return (
        <div style={{width:width}} className={hideTable}>
            <Table
                columns={columns}
               // rowKey={(record) => record.login.uuid}
                dataSource={tableData}
                pagination={tableParams?.pagination}
                loading={loading}
                onChange={handleTableChange}
                className={`paginated-table-wrapper ${className}`}
                scroll={{ x: scrollX }}
                data-testid={dataTestId}
                rowSelection={rowSelection}
                rowClassName={(_record) => {
                    return `${_getRowClassName(_record)} row-class editable-row`
                }}
                components={components}
                rowKey={rowKey}
            />
        </div>
    );
};
export default PaginatedTable;
