import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import {Checkbox, Table} from "antd";
import {
    getMonthlyCutoverVsTargetAction,
    getSitesCountPerStatusAction,
    getSitesStatusSummaryAction,
    getWeeklySiteDataVsActualAction
} from "../../service-contracts/dashboard/dashboard-actions";
import ChartBox from "./ChartBox";
import Loader from "../Notifications/Loader";
import {
    colorArray,
    GRAPH_TITLE,
    modifySiteSummaryData,
    modifyWeeklyResponse, siteSummaryColumn, tbbSummaryColumn,
    toTitleCase
} from "./helper";
import {connect} from "react-redux";
import {FilterFilled} from "@ant-design/icons";
import moment from "moment";

const Dashboard = (props) => {
    const [sitesCountPerStatusData, setSitesCountPerStatusData] = useState([]);
    //const [monthlyCutoverVsTargetData, setMonthlyCutoverVsTargetData] = useState([]);
    const [weeklySiteVsTargetData, setWeeklySiteVsTargetData] = useState([]);
    const [sitesStatusOneData, setSitesStatusOneData] = useState([]);
    const [sitesStatusTwoData, setSitesStatusTwoData] = useState([]);
    const [sitesTBBStatus, setSitesTBBStatus] = useState([]);
    const [totalSites, setTotalSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const ref = useRef();
    const [toggleFilter, setToggleFilter] = useState(false);
    const [checkedFilter, setCheckedFilter] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const CheckboxGroup = Checkbox.Group;

    const filterOptions = [
        {
            label: 'GAMING',
            value: 'GAMING',
        },
        {
            label: 'KENO',
            value: 'KENO',
        },
        {
            label: 'LOTTERIES',
            value: 'LOTTERIES',
        },
        {
            label: 'WAGERING',
            value: 'WAGERING',
        },
    ];

    /**
     * Function to fetch the status of all the dashboard widgets
     * @param appendQuery
     */
    const fetchStatus = async (appendQuery = '') => {
        setLoading(true);

        const [response, weeklyResponse, sitesStatusResponse] = await Promise.all([
            getSitesCountPerStatusAction(appendQuery),
            getWeeklySiteDataVsActualAction(),
            getSitesStatusSummaryAction(appendQuery),
        ]);
        if (response?.data?.status) {
            let data = response?.data?.data;
            let _totalSites = 0;
            data && data.length && data.map((item, index) => {
                item.id = toTitleCase(item.name);
                item.label = toTitleCase(item.name);
                item.value = item.count;
                item.color = colorArray[index];
                // item.color = "hsl(202, 70%, 50%)"
                _totalSites = _totalSites + item.count;
            })
            setSitesCountPerStatusData(data);
            setTotalSites(_totalSites || 0);
        }
        /* if (monthlyResponse?.data?.status) {
             let data = monthlyResponse?.data?.data;
             let _data = modifyMonthlyResponse(data);
             setMonthlyCutoverVsTargetData(_data);
         }*/
        if (weeklyResponse?.data?.status) {
            let data = weeklyResponse?.data?.data;
            let _data = modifyWeeklyResponse(data);
            setWeeklySiteVsTargetData(_data);
        }
        if (sitesStatusResponse?.data?.status) {
            let data = sitesStatusResponse?.data?.data;
            let _data = modifySiteSummaryData(data);
            setSitesStatusOneData(_data && _data.tableOneArr);
            setSitesStatusTwoData(_data && _data.tableTwoArr);
            setSitesTBBStatus(_data && _data.tableTBB);
        }
        setFilterApplied(appendQuery !== '')
        setLoading(false);
    };

    React.useEffect(() => {
        fetchStatus();

    }, []);

    let display = toggleFilter ? "block" : "none";

    const onChange = (checkedValues) => {
        setCheckedFilter(checkedValues);
    };

    /**
     * Function to apply filters
     */
    const onFilterApply = () => {
        let appendQuery = '';
        checkedFilter && checkedFilter.length && checkedFilter.map((item, index) => {
            if (index === 0) {
                appendQuery += `?businessUnit=${item}`
            } else {
                appendQuery += `&businessUnit=${item}`
            }
        })
        fetchStatus(appendQuery);
        setToggleFilter(false);
    }

    const onOpenFilter = () => {
        setToggleFilter(!toggleFilter);
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (toggleFilter && ref.current && !ref.current.contains(e.target)) {
                setToggleFilter(false)
            }
            if ((e.target.className === "filter-btn" && toggleFilter) ||
                (toggleFilter && e.target.nodeName === "path" && e.target?.parentNode?.parentNode?.className.includes('filter-btns'))) {
                setToggleFilter(true)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [toggleFilter])

    return (
        <div className="pd-25">
            {
                loading ? <Loader/> : null
            }
            <div className="dashboard-setup-top">
                <div className="filter-btn-wrapper">
                    <div onClick={onOpenFilter} className="filter-btn" id={"filter-btn"}>
                        <FilterFilled className="filter-btns"/>
                    </div>
                </div>
            </div>
            <div className="dashboard-left-box">
                <div className={`dashboard-wrapper`}>
                    <div className="graph-wrapper" data-testid="pie-chart">
                        {
                            sitesCountPerStatusData && sitesCountPerStatusData.length ?
                                <ChartBox title={GRAPH_TITLE.SITE_STATUS_REPORT} data-testid="pie-chart"><PieChart
                                    data={sitesCountPerStatusData} totalSites={totalSites}
                                    screenWidth={props.screenWidth} toggleFilter={toggleFilter}/></ChartBox> : null
                        }
                        {/*{
                    monthlyCutoverVsTargetData && monthlyCutoverVsTargetData.length ?
                        <ChartBox title={GRAPH_TITLE.SITES_CUTOVER} lessOpacity={filterApplied}><LineChart
                            data={monthlyCutoverVsTargetData}/></ChartBox> : null
                }*/}
                        {
                            weeklySiteVsTargetData && weeklySiteVsTargetData.length ?
                                <ChartBox title={GRAPH_TITLE.WEEKLY_REPORT} lessOpacity={filterApplied}>
                                    <BarChart
                                        data={weeklySiteVsTargetData}/></ChartBox> : null
                        }
                    </div>
                    <div className="tables-wrapper">
                        <div className="table-top-details">
                            <h3 className="title">Site Status as on <span>{moment().format("DD-MM-YYYY")}</span></h3>
                        </div>
                        <div>
                            <Table columns={tbbSummaryColumn} dataSource={sitesTBBStatus}
                                   scroll={{x: 100}}
                                   pagination={false}
                                   className="schedule-table table-summary-tbb"/>
                        </div>
                        <div>
                            {sitesStatusOneData && sitesStatusOneData.length ?
                                <Table columns={siteSummaryColumn} dataSource={sitesStatusOneData}
                                       scroll={{x: 100}}
                                       pagination={false}
                                       className="schedule-table table-summary table-summary-one"/> : null}
                        </div>
                        {
                            sitesStatusTwoData && sitesStatusTwoData.length ? <div>
                                <Table columns={siteSummaryColumn} dataSource={sitesStatusTwoData}
                                       scroll={{x: 100}}
                                       pagination={false} className="schedule-table table-summary table-summary-two"/>
                            </div> : null
                        }
                    </div>
                </div>
                {
                    toggleFilter ? <div style={{display: display}} className="filter-wrapper-box" ref={ref}>
                        <div className="filter-checkboxes">
                            <div>
                                <div className="filter-heading">Product Type</div>
                                <CheckboxGroup options={filterOptions} value={checkedFilter}
                                               onChange={(e) => onChange(e)}/>
                            </div>
                            <button onClick={onFilterApply}>Apply</button>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isDesktopView: state.commonReducer.isDesktopView,
    screenWidth: state.commonReducer.screenWidth,
});

export default connect(
    mapStateToProps,
    null,
)(Dashboard);
