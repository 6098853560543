import inspectionImg from '../../../assets/images/link.png';
import React from "react";
import right from "../../../assets/images/right.png";
import wrong from "../../../assets/images/wrong.png";
import moment from "moment";
import {TABLE_PAGE_SIZE} from "../../constants";
import {approveInspectionAction, submitInspectionAction} from "../../service-contracts/dashboard/dashboard-actions";

const STATUS_COLOR = {
    "Quote Submitted": '#20709B',
    "Quote Required": '#ED1E1E',
    "Quote Approved": '#5EC472',
}

export const setColumnForCableRemediationList = (setIsModalVisible, editHandler, isHistoryTabSelected,screenWidth,shouldRedirectToNetbox, fetchData) => {
    let colArr = [
        {
            title: 'UPID',
            render: (record) => shouldRedirectToNetbox ? <a href={record.netboxUrl} target="_blank">{record.upid}</a> : `${record.upid}`,
            width: '8%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'SITE NAME',
            dataIndex: 'siteName',
            width: '12%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'VENDOR',
            dataIndex: 'vendor',
            width: '8%',
            fixed: screenWidth < 767 ? '' : 'left',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'STATUS',
            //dataIndex: 'status',
            render: (record) => <div className={"status-block"}><span className={"status-indicator"}
                                                                      style={{background: `${STATUS_COLOR[record.status]}`}}/>{record.status}
            </div>,
            width: '12%',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'LINKS',
            //dataIndex: '',
            //sorter: true,
            render: (record) => <img src={inspectionImg} alt={"#"} style={{cursor: 'pointer'}}
                                     data-testid={"edit-button"}
                                     onClick={() => {
                                         editHandler(record);
                                     }
                                     }/>,
            width: '5%',
        }
    ];

    if (!isHistoryTabSelected) {
        colArr.splice(4,0, {
                title: 'DAYS LAPSED',
                dataIndex: 'dayLapsed',
                width: '5%',
                align: 'center',
                sorter: true,
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend', 'descend', 'ascend']
            },
            /* {
                 title: 'QUOTE REQUIRED',
                 render: (record) => <div
                     className={`cable-status-box ${(record.status === "Quote Required" || record.status === "Quote Submitted" || record.status === "Quote Approved") ? "sow-required-active" : "sow-required"}`}>
                     SOW Required</div>,
                 width: '12%',
             },*/
            {
                title: 'QUOTE RECEIVED',
                // render: (record) => <div
                //     className={`cable-status-box ${(record.status === "Quote Submitted" || record.status === "Quote Approved") ? "sow-submitted-active" : "sow-submitted"}`}>
                //     Submitted</div>,
                render: (record) => (record.status === "Quote Submitted" || record.status === "Quote Approved") ?
                    <img src={right} alt="Yes"/> : <button className={"cable-btn"} onClick={ async () => {
                        await submitInspectionAction({auditId :record?.auditId || "" });
                        fetchData();
                    }}>Receive</button>,
                width: '10%',
                align: 'center'
            },
            {
                title: 'QUOTE APPROVED',
                // render: (record) => <div
                //     className={`cable-status-box ${record.status === "Quote Approved" ? 'sow-approved-active' : 'sow-approved'}`}>
                //     Approved</div>,
                render: (record) => (record.status === "Quote Approved") ? <img src={right} alt="Yes"/> : record.status === "Quote Submitted" ?
                    <button className={"cable-btn"} onClick={ async () => {
                        await approveInspectionAction({auditId :record?.auditId || "" } );
                        fetchData();
                    }}>Approve</button>:
                    <img src={wrong} alt="No"/>,
                width: '12%',
                align: 'center'
            })
    }
    else{
        colArr.splice(4, 0,  {
            title: 'APPROVAL DATE',
            render: (record) => moment(record?.approvedDate).format('MMM DD, YYYY'),
            width: '12%',
            align: 'center'
        })
    }
    return colArr;
};

export const handleTableChange = async (pagination, filters, sorter, setTableParams, tableParams, setReloadList, setListData,filterReq) => {
    let sorting = 'ASC';
    let sortingColumn = '';

    if (sorter.hasOwnProperty("column")) {
        sorting = sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : "ASC";
        sortingColumn= sorter.column?.title;
    }

    await setTableParams({
        ...tableParams,
        pagination,
        filters,
        ...sorter,
        sorting,
        sortingColumn,
        filterReq,
    });

    await setReloadList(true);
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setListData([]);
    }
};

export const handleSearch = async (val, setTableParams, tableParams, setReloadList) => {
    await setTableParams({
        ...tableParams,
        "textSearch": val,
        "filterReq": tableParams?.filterReq
    });

    setReloadList(true);
};

export const onSearchInputChange = (e, setSearchInput) => {
    setSearchInput(e.target.value)
};

export const handleCancel = (form, setIsModalVisible) => {
    form.resetFields();
    setIsModalVisible(false);
};

export const getTableParamInitialVal = (state) => {
    let initialVal = {
        pagination: {
            current: 1,
            pageSize: TABLE_PAGE_SIZE,
            pageSizeOptions: ['25', '50', '75', '100'],
            showSizeChanger: true,
        },
        textSearch: state && state.upid ? state.upid : '',
        sorting: 'ASC',
        sortingColumn: "status"
    }
    return initialVal;
};
