import * as React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from './auth';
import {ROLES} from "../../constants";
import {getRequiredRoles} from "../../Routing/helper";

export const RequireAuth = ({
                                children,
                                requiredRoles
                            }) => {
    const auth = useAuth();

    if (auth?.user == null || auth?.user === '') {
        return (
            <Navigate
                to="/"
            />
        );
    }
    if(requiredRoles && Array.isArray(requiredRoles) && (requiredRoles.some(item => auth?.userRole.includes(item)) || requiredRoles.includes("*"))){
        return children;
    } else{
        let path = '';
        if(auth?.userRole.includes(ROLES.FIELDTECH) || auth?.userRole.includes(ROLES.SDWAN_INSTALLATION_ENGINEER) ){
            path = 'routerOperationalTest';
        } else if(auth?.userRole.includes(ROLES.CUTOVER_ENGINEER)){
            path = 'routerConfig';
        } else{
            path = 'dashboard'
        }

        if(getRequiredRoles(path) && Array.isArray(getRequiredRoles(path)) && ((getRequiredRoles(path).some(item => auth?.userRole.includes(item)))
            || getRequiredRoles(path).includes("*"))){
            return (
                <Navigate
                    to={`/${path}`}
                />
            );
        }
        else{
            auth.logout();
            return <Navigate to="/" />;
        }
    }
};
