import React from 'react';
import './style.scss';

const ChartBox = ({children, title, lessOpacity}) => {
    return (
        <>
            <div className={`chart-box ${lessOpacity ? "low-opacity" : ''}`} data-testid="pie-chart">
                <div className="chart-box-header">
                    <div className={"title"}>{title}</div>
                </div>
                {children}
            </div>
        </>
    )
}
export default ChartBox;
