import {Form, Select} from "antd";
import {Option} from "antd/es/mentions";
import Input from "antd/es/input/Input";
import React from "react";
import {SITE_UPLOAD_CASE} from "../helper";

export const FIELDS_LIST =  ['upid', 'siteName','vendor','relatedUpid','nbnTechnology','circuitId','ntdSerialOrMac','portOrAdditionalComment','tbbInstallationComplete', 'siteType', 'siteUploadCase', "threeG"];

export const ValidateDigits = (_,UPID) => {
    let regex = /[0-9]{4}\-[0-9]{5}/

    if (!regex.test(UPID)) {
        return Promise.reject('Format must be (XXXX-XXXXX)')
    }

    return Promise.resolve()
}

export const renderSiteForm = (dataIndex, vendorRespData, siteTypeRespData, nbnRespData,caseRespData) => {

    let inputNode;
    let isRequired;
    let labelName;
    let validator;
    let yesNoSelect = <Select showArrow={false}>
        <Option value="YES">Yes</Option>
        <Option value="NO">No</Option>
    </Select>;

    switch (dataIndex) {
        case 'vendor':
            isRequired = false;
            labelName = 'Vendor';
            inputNode = <Select showArrow={false}>
                {
                    vendorRespData && vendorRespData.length && vendorRespData.map(item => {
                        return <Option value={item} key={item} data-testid={`${item}`}>{item}</Option>
                    })
                }
            </Select>
            break;
        case 'siteType':
            isRequired = false;
            labelName = 'Site Type';
            inputNode = <Select showArrow={false}>
                {
                    siteTypeRespData && siteTypeRespData.length && siteTypeRespData.map(item => {
                        return <Option value={item} key={item} data-testid={`${item}`}>{item}</Option>
                    })
                }
            </Select>
            break;
        case 'nbnTechnology':
            isRequired = false;
            labelName = 'NBN Tech';
            inputNode = <Select showArrow={false}>
                {
                    nbnRespData && nbnRespData.length && nbnRespData.map(item => {
                        return <Option value={item} key={item} data-testid={`${item}`}>{item}</Option>
                    })
                }
            </Select>
            break;
        case 'siteUploadCase':
            isRequired = false;
            labelName = 'Site Case';
            inputNode = <Select showArrow={false}>
                {
                    caseRespData && caseRespData.length && caseRespData.map(item => {
                        return <Option value={item} key={item} data-testid={`${item}`}>{SITE_UPLOAD_CASE[item]}</Option>
                    })
                }
            </Select>
            break;
        case 'tbbInstallationComplete':
            isRequired = false;
            labelName = 'TBB Complete';
            inputNode = yesNoSelect;
            break;
        case 'upid':
            isRequired = true;
            labelName = 'UPID';
            inputNode = <Input maxLength={10}/>;
            validator=ValidateDigits;
            break;
        case 'siteName':
            isRequired = false;
            labelName = 'Site Name';
            inputNode = <Input/>
            break;
        case 'circuitId':
            isRequired = false;
            labelName = 'Circuit Id';
            inputNode = <Input/>
            break;
        case 'relatedUpid':
            isRequired = false;
            labelName = 'Related UPID';
            inputNode = <Input/>;
            break;
        case 'ntdSerialOrMac':
            isRequired = false;
            labelName = 'NTD Serial or Mac';
            inputNode = <Input/>;
            break;
        case 'portOrAdditionalComment':
            isRequired = false;
            labelName = 'Port or Additional Comments';
            inputNode = <Input/>;
            break;
        case 'threeG':
            isRequired = false;
            labelName = '3G';
            inputNode = yesNoSelect;
            break;
        default:
            isRequired = false;
            inputNode = <Input/>
    }

    return (
                <Form.Item
                    data-testid={"editing-row"}
                    name={dataIndex}
                    label={labelName}
                    className="input-row height-40 site-input-block"
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: isRequired,
                            message: `Enter ${labelName}!`,
                        },
                        {
                            validator:validator
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
    );
}
