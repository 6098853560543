import React, {useState} from 'react';
import '../style.scss';
import {Button, Form, Input, Tabs} from 'antd';
import MaskedInput from "react-text-mask";
import {error as errorToast} from "../../../utils/toast-utils";
import {success as successToast} from "../../../utils/toast-utils";
import {
    ExecuteRouterOpertionalAction,
    getRouterOperationalDataAction,
    simCardActivationAction
} from '../../../service-contracts/dashboard/dashboard-actions';
import Loader from "../../Notifications/Loader";
import simCard from "../../../../assets/images/simCard.png";

const RouterOperationalTest = (props) => {
    let [loader, setLoader] = useState(false);
    const [SystemIp, setSystemIp] = useState(false);
    const [SerailNo, setSerailNo] = useState(false);
    const [entries, setEntries] = useState([]);
    const [routerData, setrouterData] = useState(null);
    const [simCardData, setSimCardData] = useState(null);
    const [form] = Form.useForm();
    const [simForm] = Form.useForm();
    const [ShowInnerLoader, setShowInnerLoader] = useState(true);
    const [execData, setExecData] = useState([]);
    const [expand, setExpand] = useState(false);
    const [selectedTab, setSelectedTab] = useState('simCardActivation');

    const IPprops = {
        guide: true,
        mask: value => {
            let result = [];
            const chunks = value.split(".");

            for (let i = 0; i < 4; ++i) {
                const chunk = (chunks[i] || "").replace(/_/gi, "");

                if (chunk === "") {
                    result.push(/\d/, /\d/, /\d/, ".");
                    continue;
                } else if (+chunk === 0) {
                    result.push(/\d/, ".");
                    continue;
                } else if (
                    chunks.length < 4 ||
                    (chunk.length < 3 && chunks[i].indexOf("_") !== -1)
                ) {
                    if (
                        (chunk.length < 2 && +`${chunk}00` > 255) ||
                        (chunk.length < 3 && +`${chunk}0` > 255)
                    ) {
                        result.push(/\d/, /\d/, ".");
                        continue;
                    } else {
                        result.push(/\d/, /\d/, /\d/, ".");
                        continue;
                    }
                } else {
                    result.push(...new Array(chunk.length).fill(/\d/), ".");
                    continue;
                }
            }

            result = result.slice(0, -1);
            return result;
        },
        pipe: value => {
          if (value === "." || value.endsWith("..")) return false;

          const parts = value.split(".");

          if (
            parts.length > 4 ||
            parts.some(part => part === "00" || part < 0 || part > 255)
          ) {
            return false;
          }

          return value;
        }
    };

    const validateIP = (_, ip) => {
        if (!ip) {
            return Promise.resolve()
        }
        const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
        let chunk = ip.split(".")
        for (let i = 0; i < 3; i++) {
            let regex = /\_+/
            if (!regex.test(chunk[i])) {
                continue
            }
        }
        ip = ip.replace(/\_+/g, "")
        if (!ipRegex.test(ip)) {
            return Promise.reject('Invalid IP format XXX.XXX.XXX.XXX')
        }
        return Promise.resolve()
    }

    const formatIP = (e) => {
        let ip = e.target.value
        let chunk = ip.split(".")
        for (let i = 0; i < 3; i++) {
            let regex = /\_+/
            if (regex.test(chunk[i])) {
                return e.target.value
            }
        }
        ip = ip.replace(/\_+/g, "")
    }
    const handleSerialNumber = (e) => {
        if (!e.target.value) {
            setSerailNo(false)
        } else {
            setSerailNo(true)
        }
    }

    const handleSysIP = (e) => {
        if (!e.target.value) {
            setSystemIp(false)
        } else {
            setSystemIp(true)
        }
    }

    const ValidateDigits = (_,UPID) => {
        let regex = /[0-9]{4}\-[0-9]{5}/

        if (!UPID || UPID.length<=0){
            return Promise.reject('Enter UPID')
        }
        else if (!regex.test(UPID)) {
            return Promise.reject('Format must be (XXXX-XXXXX)')
        }
        return Promise.resolve()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:::', errorInfo);
    };

    const onFinish = async (values) => {
        if (!values.SystemIP && !values.routerSerialNumber) {
            errorToast("Atleast one field is required");
            return
        }
        setLoader(true)
        if (values.SystemIP) {
            let ip = values.SystemIP
            values.SystemIP = ip.replace(/\_+/g, "")
            form.setFieldValue('SystemIP', values.SystemIP)
        }
        let result = await ExecuteRouterOpertionalAction(values);
        if (result) {
            setrouterData(null)
            setExpand(false)
            operationalDataCall(result?.data);
        } else {
            setLoader(false)
        }
        form.resetFields();
        setSystemIp(false)
        setSerailNo(false)
        setLoader(false)
    }

    const onSimCardActivationSubmit = async (values) => {
        let payload = {
            "iccid": values.iccid,
            "upId": values.upid
        }
        setLoader(true)
        let result = await simCardActivationAction(payload);
        if (result) {
            setSimCardData(result.data)
        } else {
            setLoader(false)
        }
        simForm.resetFields();
        setLoader(false);
    }

    const operationalDataCall = async (jobId) => {

        try {
            setShowInnerLoader(true);
            await getRouterOperationalDataAction(jobId).then(async resp => {
                if (resp.data.errors.length > 0) {
                    errorToast(resp.data.errors[0].message);
                    setShowInnerLoader(false);
                    return
                }
                if (resp.data.data.completed === false) {
                    setTimeout(() => {
                        operationalDataCall(jobId);
                    }, 3000); // call api after 3 second
                    if (resp.data.data.entries && resp.data.data.entries.length) {
                        setEntries(resp.data.data.entries);
                        setExecData(resp.data.data)
                    }
                } else {
                    if (resp.data.data.execState === "Failed") {
                        errorToast(resp.data.data.message);
                    }
                    if (resp.data.data.execState === "Success") {
                        successToast(resp.data.data.message);
                    }
                    setEntries(resp.data.data.entries);
                    setExecData(resp.data.data);
                    setrouterData(resp.data.data.routerInfo);
                    setShowInnerLoader(false)
                }
            });
        } catch (error) {
            errorToast('Something went wrong');
            setShowInnerLoader(false);
        }
    }

    const items = [
        {
            key: 'simCardActivation',
            label: `Sim Card Activation`,
            //children: <div>operational</div>,
        },
        {
            key: 'routerOpsCheck',
            label: `Router Operational Test`,
            //children: <div>operational</div>,
        },
    ];

    const onTabChange = async (key) => {
        await setSelectedTab(key)
    };

    return (
        <>
            {
                loader ? <Loader/> : null
            }
            <Tabs defaultActiveKey="simCardActivation" items={items} onChange={onTabChange}/>
            <h3 className="activation-note">* Please Power Cycle the router after SIM Card Activation</h3>
            {
                selectedTab === "simCardActivation" ?
                    <div className=" pd-25">
                        <div className="router-wrapper">
                            <div className="sim-image-block">
                                <img src={simCard} alt="Yes"className="table-img"/>
                            </div>
                            <div className="router-form-wrapper" style={{width: '58%'}}
                                 data-testid={"router-get-info-form"}>
                                <h2>Sim Card Activation</h2>
                                <Form
                                    form={simForm}
                                    className={"router-form"}
                                    style={{justifyContent: "start"}}
                                    layout="vertical"
                                    onFinish={onSimCardActivationSubmit}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <div className={"router-form-right-panel"}>
                                        <Form.Item
                                            label="Sim Card ICCID"
                                            name="iccid"
                                            data-testid="routerSerialNumber"
                                            className="height-40"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter Sim Card ICCID'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Enter Sim Card ICCID" maxLength={20}/>
                                        </Form.Item>
                                        {/*<h3 className="iccid-note">* Please Enter the 20 digit number. (Refer to the
                                            given image)</h3>*/}
                                    </div>

                                    <div className={"router-form-right-panel"}>
                                        <Form.Item
                                            label="UPID"
                                            name="upid"
                                            data-testid="SystemIP"
                                            className="height-40"
                                            rules={[
                                                {
                                                    validator:ValidateDigits
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Enter UPID" maxLength={10}/>
                                        </Form.Item>
                                    </div>

                                    <div className="get-info-btn-box">
                                        <Form.Item>
                                            <Button type="primary" data-testid="submit" className="primary-tab-btn"
                                                    htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {
                            (simCardData) ?
                                <div className={"sim-display-info"} data-testid={'router-display-info'}>
                                    <div className="sim-info-left sim-info-box">
                                    <h2>Sim Card Details</h2>
                                    <div className="router-info">
                                        <span>Sim Card ICCID:</span>{simCardData?.iccid}
                                    </div>
                                    <div className="router-info">
                                        <span>UPID:</span>{simCardData?.upId}
                                    </div>
                                    </div>
                                    <div className={`${simCardData.error ? "sim-error" : "sim-success"} sim-info-right sim-info-box`}>
                                        <h2>Status :<span> {simCardData?.error ? "FAILED" : "SUCCESS"}</span></h2>
                                    <div className="sim-info-status">
                                        <div
                                            dangerouslySetInnerHTML={{__html: simCardData?.status}}
                                        />
                                    </div>
                                    </div>
                                </div>
                                : <></>
                        }
                    </div> : null}

            {
                selectedTab === "routerOpsCheck" ? <div className="router-wrapper pd-25">
                    <div className="router-form-wrapper" style={{width: '50%'}} data-testid={"router-get-info-form"}>
                        <Form
                            form={form}
                            className={"router-form"}
                            style={{justifyContent: "start"}}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <div className={"router-form-right-panel"}>
                                <Form.Item
                                    label="Router Serial Number"
                                    name="routerSerialNumber"
                                    data-testid="routerSerialNumber"
                                    className="height-40"
                                    rules={[
                                        {
                                            required: false,
                                            message: ''
                                        }
                                    ]}
                                >
                                    <Input onChange={(e) => handleSysIP(e)} disabled={SerailNo === true ? true : false}
                                           placeholder="Enter Router Serial Number"/>
                                </Form.Item>
                            </div>
                            <div className={"router-form-right-panel"} style={{width: '100%', textAlign: 'center'}}>
                                <label> Or </label>
                            </div>
                            <div className={"router-form-right-panel"}>
                                <Form.Item
                                    label="System IP"
                                    name="SystemIP"
                                    data-testid="SystemIP"
                                    className="height-40"
                                    rules={[
                                        {
                                            required: false,
                                            message: ''
                                        },
                                        {
                                            validator: validateIP
                                        }
                                    ]}
                                >
                                    <MaskedInput
                                        className="input-mask"
                                        disabled={SystemIp === true ? true : false}
                                        onChange={(e) => handleSerialNumber(e)}
                                        onBlur={(e) => formatIP(e)}
                                        {...IPprops}
                                    />
                                </Form.Item>
                            </div>

                            <div className="get-info-btn-box">
                                <Form.Item>
                                    <Button type="primary" data-testid="submit" className="primary-tab-btn"
                                            htmlType="submit">Execute</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    {
                        (routerData) ?
                            <div className="router-info-box" data-testid={'router-display-info'}>
                                <div>
                                    <h2>Router Information</h2>
                                    <div className="router-info">
                                        <span>UPID:</span>{routerData?.upId ? routerData?.upId : "NA"}
                                    </div>
                                    <div className="router-info">
                                        <span>Venue Name:</span>{routerData?.venueName ? routerData?.venueName : "NA"}
                                    </div>
                                    {/* <div className="router-info">
                                <span>SIM Status:</span>{routerData?.simStatus ? routerData?.simStatus : "NA"}
                            </div>
                            <div className="router-info">
                                <span>ICCID:</span>{routerData?.iccId ? routerData?.iccId : "NA"}</div> */}
                                    {/* <div className="router-info">
                                <span>Interface Status:</span>{routerData?.interfaceStatus ? routerData?.interfaceStatus : "NA"}</div> */}
                                    <div className="router-info">
                                        <span>Router Online:</span>{routerData?.routerOnline === true ? "Online" : "Offline"}
                                    </div>
                                    <div className="router-info">
                                        <span>UPID Status:</span>{routerData?.isUPIDCorrect === true ? "Correct" : "Incorrect"}
                                    </div>
                                    <div className="router-info">
                                        <span>IP Status:</span>{routerData?.isIPsCorrect === true ? "Correct" : "Incorrect"}
                                    </div>
                                    <div className="router-info">
                                        <span>Template Name:</span>{routerData?.templateName ? routerData?.templateName : "NA"}
                                    </div>
                                    {/* <div className="router-info">
                                <span>Template Status:</span>{routerData?.templateCorrect === true ? "Correct" : "Incorrect"}
                            </div> */}
                                </div>

                            </div>
                            : <></>
                    }
                </div> : null
            }
            {
                selectedTab === "routerOpsCheck" && (entries && entries.length) ? <>
                        <div className={"entries-heading"}>Entries</div>
                        <div className={"entries-info-wrapper"}>
                            <div>Status: <span
                                className={`${execData && execData.execState} status-color`}>{execData && execData.execState || "Running"}</span>
                            </div>
                            {
                                routerData?.router4GSignal ? <div>Router 4G
                                    Signal: <span>{(routerData?.router4GSignal == "0" || routerData?.router4GSignal == 0) ? "Failed to get signal strength" : routerData?.router4GSignal}</span>
                                </div> : null
                            }
                            {
                                routerData?.simStatus ? <div>Jasper: <span>{routerData?.simStatus}</span></div> : null
                            }
                            {
                                routerData?.interfaceStatus && routerData?.interfaceStatus == "Primary" ?
                                    <div>Router Active On: <span>NBN</span></div> : null
                            }
                            {
                                routerData?.interfaceStatus && routerData?.interfaceStatus == "Cellular" ?
                                    <div>Router Active On: <span>Backup</span></div> : null
                            }
                            {
                                routerData?.iccid ? <div>Router Sim Registered: <span>{routerData.iccid}</span></div> : null
                            }
                        </div>
                        <div className="logs-display" onClick={() => setExpand(!expand)}><span>Logs</span>{(!expand) ?
                            <span>+</span> : <span>-</span>}</div>
                        {
                            expand ?
                                <div className={"entries-wrapper"}>
                                    {
                                        entries.map((item, index) => {
                                            return <div style={{display: 'flex'}} key={index + 1}><span
                                                data-testid="log-data">Log# {index + 1} ~</span> {item?.log} {(ShowInnerLoader && index == entries.length - 1) ?
                                                <span className="log-loader"></span> : <></>}</div>
                                        })
                                    }
                                </div>
                                : null
                        }
                    </>
                    : <></>
            }
        </>
    )
}
export default RouterOperationalTest;
