import React, {useState} from 'react';
import '../style.scss';
import {Button, DatePicker, Form, Checkbox, Modal, Space} from "antd";
import {error as errorToast, success as successToast} from "../../../utils/toast-utils";
import {
    exportSitesDataAction, publishManualReportForBusinessUnitAction,
    sendPublishManualVendorReportAction
} from "../../../service-contracts/networkite/networkite-actions";
import Loader from "../../Notifications/Loader";
import moment from "moment";

const GetNotificationPeriodModal = ({setIsModalVisible, isModalVisible, intervalModalType}) => {
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const CheckboxGroup = Checkbox.Group;
    const plainOptions = ['Gaming', 'Keno','Lotteries', 'Wagering'];
    const [isLoading, setIsLoading] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [startDate, setStartDate] = useState(null);

    const sendNotification = (intervalModalType) => {
        form.validateFields().then(async (value) => {
            let formData = form.getFieldValue();
            let startTime, endTime;
            if(formData && formData.range && formData.range.length){
                let start = formData.range[0];
                let end = formData.range[1];
                startTime = start.format();
                endTime =  end.format();
                //startTime = moment(formData.range[0]).format("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                //endTime = moment(formData.range[1]).format("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
            }
            try {
                setIsLoading(true);
                let response;
                if(intervalModalType === "BusinessReports"){
                    let payload = {
                        "startTime": startTime,
                        "endTime": endTime,
                        "businessUnits": checkedList
                    }
                     response = await publishManualReportForBusinessUnitAction(payload);
                } else{
                     response = await sendPublishManualVendorReportAction(startTime , endTime);
                }
                if(response?.data?.status){
                    successToast("Report Send Successfully");
                    setIsModalVisible(false);
                    form.resetFields();
                }
                else{
                    errorToast(response?.data?.message || "Error Occurred");
                }
            } catch (error) {
                console.log(`Invalid`);
            } finally {
                setIsLoading(false);
                setCheckedList([])
            }
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    };

    const exportData = () => {
        form.validateFields().then(async (value) => {
            let formData = form.getFieldValue();
            let startTime = formData?.startTime?.set('hour', 0)?.set('minutes', 0)?.set('seconds', 0)?.format();
            let endTime = formData?.endTime?.set('hour', 23)?.set('minutes', 59)?.set('seconds', 59)?.format();

            /*if(formData && formData.range && formData.range.length){
                let start = formData.range[0];
                let end = formData.range[1];
                startTime = start.format();
                endTime =  end.format();
            }*/
            try {
                setIsLoading(true);
                let response = await exportSitesDataAction(startTime, endTime);
                if(response?.data?.status && response?.data?.data){
                    let a = document.createElement('a');
                    a.href = response?.data?.data;
                    a.click();
                    //successToast("Data Exported Successfully");
                    setIsModalVisible(false);
                    form.resetFields();
                }
                else{
                    errorToast(response?.data?.message || "Error Occurred");
                }
            } catch (error) {
                console.log(`Invalid`);
            } finally {
                setIsLoading(false);
            }
        })
            .catch(errorInfo => {
                console.log('errorInfo', errorInfo);
            });
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    const onChange = (list) => {
        setCheckedList(list);
    };

    const onstartTimeChange = (a) => {
        setStartDate(a.format('YYYY-MM-DD'));
    }

    return (
        <>
            {
                isLoading ? <Loader/> : null
            }
            <Modal title={ intervalModalType=== "BusinessReports" ? 'Enter Details' : 'Enter Time Period'}
                   open={isModalVisible}
                   onOk={sendNotification}
                   onCancel={handleCancel}
                   footer={null}
                   maskClosable={true}
            >
                <div className="add-new-modal-wrapper">
                    <Form
                        form={form}
                        layout="vertical"
                        className="add-user-form"
                    >
                        {
                            intervalModalType !== "Export" ?  <Form.Item
                                name="range"
                                label="Time Period"
                                className="height-40"
                                rules={[{ required: true, message: 'Please select Time Period' }]}
                            >
                                <RangePicker format="YYYY-MM-DD HH:mm"
                                             showTime/>
                            </Form.Item> : null
                        }

                        {
                            intervalModalType === "Export" ?  <><Form.Item
                                    name="startTime"
                                    label="Start Time"
                                    className="height-40"
                                    //rules={[{ required: true, message: 'Please select Time Period' }]}
                                >
                                    <DatePicker  format="YYYY-MM-DD" onChange={onstartTimeChange}/>
                                </Form.Item>

                                <Form.Item
                                    name="endTime"
                                    label="End Time"
                                    className="height-40"
                                    //rules={[{ required: true, message: 'Please select Time Period' }]}
                                >
                                    <DatePicker format="YYYY-MM-DD"
                                                disabledDate={(current) => {
                                                    return current && current < moment(startDate, "YYYY-MM-DD");
                                                }} />
                                </Form.Item></> : null
                        }

                        {
                            intervalModalType === "BusinessReports" ?  <Form.Item
                                    name="businessUnit"
                                    label="Business Unit"
                                    className="height-40"
                                    rules={[{ required: true, message: 'Please select any one business unit' }]}
                                >
                                    <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                                </Form.Item>
                                : null
                        }

                        <div className="ant-modal-footer mt20">
                            <Space size="middle">
                                <Button className="btn primary-btn ant-btn-primary primary-tab-btn" onClick={() =>  intervalModalType === "Reports" || intervalModalType=== "BusinessReports" ?
                                    sendNotification(intervalModalType) : exportData()}>
                                    {
                                        intervalModalType === "Reports" || intervalModalType=== "BusinessReports" ? "Send Reports" : "Download Data"
                                    }
                                </Button>
                            </Space>
                        </div>
                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default GetNotificationPeriodModal;
