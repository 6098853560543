/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
//console.log(process.env, "process.env");
const env = process.env.APP_ENV || 'dev';

let config = {};
if (process.env.NODE_ENV === 'production') {
  config = window.APP_CONFIG || config;
} else {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  config = require(`../configs/${env}/config.json`);
}

module.exports = config;
