import * as React from 'react';

import {
  USER_DETAILS,
} from '../../constants/index';
import {loginUser} from '../../service-contracts/auth/auth-service';
import {useNavigate} from "react-router-dom";
import {error as errorToast} from "../../utils/toast-utils";

const AuthContext = React.createContext(null);

export const AuthProvider = ({
  children,
}) => {
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem(USER_DETAILS)));
  const [isAdmin, setIsAdmin] = React.useState(JSON.parse(localStorage.getItem(USER_DETAILS))?.role === "ADMIN");
  const [userRole, setUserRole] = React.useState(JSON.parse(localStorage.getItem(USER_DETAILS))?.role);
  const navigate = useNavigate();

  /**
   * Function to get logged in user details
   * @param {logged in payload} userObj
   */
  // eslint-disable-next-line no-shadow
  const login = async (userObj) => {
    try{
      const response = await loginUser(userObj);
      if(response?.data?.status){
        let {access_token, refresh_token, role,email, fname, lname} = response?.data?.data;
        let _user = {
          access_token, refresh_token, role, email, fname, lname
        }
        localStorage.setItem(USER_DETAILS, JSON.stringify(_user))
        sessionStorage.setItem(USER_DETAILS, JSON.stringify(_user));
        setUser(_user);
        setIsAdmin((role.includes("ADMIN")));
        setUserRole(role);
        if(role.includes("FIELD_TECH")){
          navigate('/routerOperationalTest')
        } else if(role.includes("SCAN_INSPECTION") || role.includes("DELETE_SITE") || role.includes("EXPORT_REPORT")){
          navigate('/networkite')
        }
        else{
          navigate('/dashboard');
        }
      }
      else{
        errorToast(response.data.message || "Incorrect Credentials");
      }
    }
    catch (error) {
      errorToast("Incorrect Credentials");
    }
  };

  const logout = () => {
    sessionStorage.removeItem(USER_DETAILS);
    localStorage.removeItem(USER_DETAILS);
    setUser('');
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user,
        isAdmin,
        userRole,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
