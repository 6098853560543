import {ACTION} from "../constants";

const initialState = {
    isDesktopView: true,
    screenWidth: 0
};

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.WINDOW_RESIZE:
            return { ...state, isDesktopView: action?.data?.isDesktopView, screenWidth: action?.data?.screenWidth };
        default:
            return state;
    }
}