import React, {useEffect, useState} from 'react';
import './style.scss';
import {Drawer, Menu} from "antd";
import {sideBarMenus} from "./helper";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Logo from "../../../assets/images/logoTab.png";
import {USER_DETAILS} from "../../constants";

const ResponsiveNavigation = ({onClose, open}) => {
    let location = useLocation();
    const [current, setCurrent] = useState(location.pathname);
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(USER_DETAILS))?.role;

    const onLogoClick = () => {
        navigate('/dashboard')
        onClose();
    }

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    const getMenus = () => {
        return sideBarMenus.map((menus, index) => {
                return (
                    menus?.roles && Array.isArray(menus?.roles) && (menus?.roles.some(item => userRole.includes(item))) ? <Menu.Item key={menus.url}>
                        <Link to={menus.url} onClick={onClose}>{menus.label}</Link>
                    </Menu.Item> : null
                )
        })
    }

    return (
        <>
            <Drawer
                title="Basic Drawer"
                placement={"left"}
                closable={false}
                onClose={onClose}
                open={open}
                key={"bottom"}
               // size={'large'}
                className={"responsive-navigation main-sider"}
                data-testid="responsive-navigation"
            >
                <div className="logo">
                    <img src={Logo} alt="logo" onClick={onLogoClick} className="logo-img"/>
                </div>
                <Menu
                    mode="inline"
                    theme="light"
                    selectedKeys={[current]}
                    style={{height: '100%', borderRight: 0}}
                >
                    {getMenus()}
                </Menu>
            </Drawer>
        </>
    )
}
export default ResponsiveNavigation;
