import {mS} from "../TargetValue/helper";
import moment from "moment";

export const STATUS_CONSTANT = {
    "ACTIVE_MPLS": "Active MPLS",
    "ACTIVE_SDWAN_INFO_NOT_AVAILABLE": "Active SDWAN-Info not Available",
    "FAILED_MPLS": "Failed MPLS",
    "DIGITAL": "Digital",
    "REPLACE_ROUTER": "Replace Router",
    "DUAL_SITE_ONE_ACTIVE_SDWAN": "Dual Site/One Active SDWAN",
    "NEW_SDWAN_VENUE": "New SDWAN Venue",
    "READY_FOR_TBB": "Ready for TBB",
    "TBB_INSTALLED": "TBB Installed",
    "SITE_INSTALLED": "Site Installed",
    "CABLE_QUOTE_REQUIRED": "Cable Quote Required",
    "CABLE_QUOTE_SUBMITTED": "Cable Quote Submitted",
    "CABLE_QUOTE_APPROVED": "Cable Quote Approved",
    "SITE_FAULT": "Site fault",
    "SITE_CUTOVER": "Site Cutover",
    "CUTOVER_FAULT": "Cutover Fault ",
    "CUTOVER_FAULT_FIXED": "Cutover Fault Fixed",
    "ACTIVE_SDWAN": "Active SDWAN ",
    "FAILED_SDWAN": "Failed SDWAN",
}

export const GRAPH_TITLE = {
    "SITES_CUTOVER": "Sites Cutover",
    "SITE_STATUS_REPORT": "Site Status Report",
    "WEEKLY_REPORT": "Weekly Report",
}

export const tbbSummaryColumn = [
    {
        title: '',
        dataIndex: 'key',
        rowScope: 'row',
        onCell: (_, index) => ({
            rowSpan: index === 0 ? 3 : index === 3 ? 3 : 0,
        }),
    },
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Telstra Sites',
        dataIndex: 'telstraCount',
        key: 'telstraCount',
    },
    {
        title: 'Alliance Sites',
        dataIndex: 'allianceCount',
        key: 'allianceCount',
    },
    {
        title: 'Total',
        dataIndex: 'totalCount',
        key: 'totalCount',
    },
];

export const siteSummaryColumn = [
    {
        title: '',
        dataIndex: 'key',
        rowScope: 'row',
        onCell: (_, index) => ({
            rowSpan: index === 0 ? 5 : index === 5 ? 5 : 0,
        }),
    },
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
        //render: (text) => <a>{text}</a>,
    },
    {
        title: 'Telstra Sites',
        dataIndex: 'telstraCount',
        key: 'telstraCount',
    },
    {
        title: 'Alliance Sites',
        dataIndex: 'allianceCount',
        key: 'allianceCount',
    },
    {
        title: 'Total',
        dataIndex: 'totalCount',
        key: 'totalCount',
    },
];

export const toTitleCase = (phrase) => {
    return phrase
        .replace(/_/g, " ")
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const modifyMonthlyResponse = (data) => {
    const {sitesCutoverCountPerMonths = [], monthStatusTarget = []} = data;

    sitesCutoverCountPerMonths && sitesCutoverCountPerMonths.length && sitesCutoverCountPerMonths.map(item => {
        item.x = mS[item?.month?.month];
        item.y = item?.count;
        return item;
    });

    sitesCutoverCountPerMonths.sort((a, b) => a.month.month - b.month.month);

    monthStatusTarget && monthStatusTarget.length && monthStatusTarget.map(item => {
        item.x = mS[item?.month?.month];
        item.y = item?.target;
        return item;
    });

    monthStatusTarget.sort((a, b) => a.month.month - b.month.month);

    let actualObj = {
        "id": "Actual",
        "color": "hsl(273, 70%, 50%)",
        "data": sitesCutoverCountPerMonths
    }

    let targetObj = {
        "id": "Target",
        "color": "hsl(165, 70%, 50%)",
        "data": monthStatusTarget
    }

    return [actualObj, targetObj]
};
/*
export const modifyWeeklyResponse = ({siteActualVsTargetDataByVendorWeeklyList:data}) => {
    let outputArr =[{}, {}, {}, {}];
    data && data.length && data.map( item => {
        let actualVsTargetCountList = item.actualVsTargetCountList;
        actualVsTargetCountList.length && actualVsTargetCountList.map((listItem, index) => {
            let obj = {week : `Week ${listItem?.yearWeek?.week}`};
            obj[item.vendor] = listItem.target;
            obj[item.vendor+"-actual"] = listItem.actual;
            obj[item.vendor+"-target"] = listItem.target;
            return outputArr[index] = {...outputArr[index], ...obj};
        })
    })
    outputArr.map( item => {
        let arr = ["ALLIANCE", "MAXTECH", "TELSTRA"];
        for (let i=0; i < arr.length; i++){
             item.detailedData = {
                ...item.detailedData,
                [arr[i]] : {
                    "actual": item[`${arr[i]}-actual`],
                    "target": item[`${arr[i]}-target`]
                }
            }
        }
        return item;
    })
    return outputArr;
}*/

export const modifyWeeklyResponse = (data) => {
    let _data = [];
    data && data.length && data.map(item => {
        let obj = {week: moment(item && item.weekEndDate).format("DD-MM-YYYY")};
        let vendorArr = item.vendorWiseCount;
        vendorArr && vendorArr.length && vendorArr.map(vendorItem => {
            obj[vendorItem?.vendor] = vendorItem?.totalCount
        });
        _data.push(obj)
    })
    return _data;
}

export const siteFaultColumn = [
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
    },
    {
        title: 'TBB Fault',
        dataIndex: 'tbbFault',
        key: 'tbbFault',
    },
    {
        title: 'Site Fault',
        dataIndex: 'siteFault',
        key: 'siteFault',
    },
    {
        title: 'Cutover Fault',
        dataIndex: 'cutoverFault',
        key: 'cutoverFault',
    },
];

export const cableRemediationColumn = [
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
    },
    {
        title: 'Quote Required',
        dataIndex: 'quoteRequired',
        key: 'quoteRequired',
    },
    {
        title: 'Quote Submitted',
        dataIndex: 'quoteSubmitted',
        key: 'quoteSubmitted',
    },
    {
        title: 'Quote Approved',
        dataIndex: 'quoteApproved',
        key: 'quoteApproved',
    },
];

const vendorList = {
    "MAXTECH": "Maxtech",
    "ALLIANCE": "Alliance",
    "TELSTRA": "Telstra",
};

// const statusList = {
//     "SITE_FAULT" : "siteFault",
//     "CUTOVER_FAULT" : "cutoverFault",
//     "TBB_FAULT" : "tbbFault",
// };

const getValue = (data, vendor, status) => {
    let vendorObj = data.filter((item) => vendorList[item.vendor] === vendor && item.status === status);
    return Array.isArray(vendorObj) && vendorObj[0]?.count || "-"
};

export const modifySiteFaultResponse = (data) => {
    let _data = ["Maxtech", "Alliance", "Telstra"].map(item => {
        return {
            vendor: item,
            tbbFault: getValue(data, item, "TBB_FAULT"),
            cutoverFault: getValue(data, item, "CUTOVER_FAULT"),
            siteFault: getValue(data, item, "SITE_FAULT")
        }
    })

    return _data;
}

const SUMMARY_TITLES = {
    "readyForSdwanInstall": "Ready for Scheduling for SDWAN Install",
    "sdwanInstallScheduled": "SDWAN Install Scheduled",
    "siteFault": "Site Fault",
    "cableQuoteRequired": "In Cable Remediation",
    "routerInstalled": "Router Installed & to be scheduled for MTS",
    "cutoverScheduled": "Cutover Scheduled",
    "cutoverFault": "Cutover Fault",
    "cutoverCompleted": "Cutover",
    "sum": "Sum Total",
    "readyForTBBSites": "Ready For TBB",
    "tbbFaultSites": "TBB Fault",
}

export const modifySiteSummaryData = (data) => {
    let tableOneArr = [], tableTwoArr = [], tableTBB =[];
    let keyForTableOne = ["readyForSdwanInstall", "sdwanInstallScheduled", "siteFault", "cableQuoteRequired"]
    for (const key in data) {
        if (key !== "readyForTBBSites" && key !== "tbbFaultSites") {
            let obj = {
                name: SUMMARY_TITLES[key],
                ...data[key],
            }
            if (keyForTableOne.includes(key)) {
                obj.key = "SDWAN Wiring and Router Installation"
                tableOneArr.push(obj);
            } else {
                obj.key = "SDWAN Cutover"
                tableTwoArr.push(obj)
            }
        } else if (key === "readyForTBBSites" || key === "tbbFaultSites") {
            let obj = {
                key: "TBB",
                name: SUMMARY_TITLES[key],
                totalCount: data[key],
                telstraCount: '',
                allianceCount: ''
            }
            tableTBB.push(obj);
        }
    }

    return {
        tableOneArr,
        tableTwoArr,
        tableTBB,
    }
}

export const modifyCableRemediationResponse = (data) => {


    let _data = ["Alliance", "Telstra"].map(item => {
        return {
            vendor: item,
            quoteRequired: getValue(data, item, "CABLE_QUOTE_REQUIRED"),
            quoteApproved: getValue(data, item, "CABLE_QUOTE_APPROVED"),
            quoteSubmitted: getValue(data, item, "CABLE_QUOTE_SUBMITTED")
        }
    })

    return _data;
}

export const colorArray = ['#e8c1a0', '#f47560', '#f1e15b', '#e8a838', '#61cdbb',
    '#97e3d5', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728',
    '#9467bd', '#8c564b', '#E6B3B3', '#e377c2', '#7f7f7f',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
